import { v4 as uuid } from 'uuid';

class UniqueKeysManager<T> {
  private keys = new Map<T, string>();

  public ensure(element: T): string {
    if (!this.keys.has(element)) {
      this.keys.set(element, uuid());
    }
    return this.keys.get(element)!;
  }

  public replace(previous: T, next: T): string {
    const key: string = this.keys.get(previous)!;
    this.keys.delete(previous);
    this.keys.set(next, key);
    return key;
  }

  public delete(element: T): void {
    this.keys.delete(element);
  }
}

export default UniqueKeysManager;
