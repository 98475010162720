import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { GppGood } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const TotalChecks = (props) => {
  const [overallChecks, setOverallChecks] = useState();
  useEffect(() => {
    fetch(`/api/v1/vulnerability-assessment/stats/getsecuritycheckcount`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response;
      })
      .then((response) => {
        return response.text();
      })
      .then((res) => {
        setOverallChecks(res);
      })
      .catch((reason) => console.log(reason));
  }, []);

  return (
    <Card sx={{ height: '100%' }} {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Total Security Checks in Knowledge Base
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {overallChecks}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 56,
                width: 56
              }}
            >
              <GppGood />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default TotalChecks;
