import {
  matchPath,
  NavLink as RouterLink,
  useLocation
} from 'react-router-dom';
import { Box, Button, ListItem } from '@mui/material';
import { Icon } from 'react-feather';

interface Props {
  href: string;
  icon: Icon;
  title: string;
}

const NavItem = ({ href, icon: MenuIcon, title, ...rest }: Props) => {
  const location = useLocation();

  const active = href
    ? matchPath(
        {
          path: href,
          end: false
        },
        location.pathname
      )
    : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        pt: href === '/app/settings' ? 5 : 0
      }}
      {...rest}
    >
      <Button
        disabled={href.split('sap-btp-monitoring').length > 1}
        component={RouterLink}
        sx={{
          color: href === '/app/settings' ? '#000' : 'text.secondary',
          fontWeight: 'medium',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          py: 1.25,
          textTransform: 'none',
          minWidth: 0,
          width: '100%',
          ...(active && {
            color: 'green'
          }),
          '& svg': {
            mr: title.length ? 1 : 0
          }
        }}
        to={href}
      >
        {/* when this button contains some text (title is not empty) */}
        {/* indentation between menu list elements increases */}
        {/* (because of some unknown yet reason) */}
        {/* comparing when there is no text inside. */}
        {/* size 25 makes this not visible */}
        <Box sx={{ minWidth: '25px' }}>
          <MenuIcon size="25" />
        </Box>
        <span>{title}</span>
      </Button>
    </ListItem>
  );
};

export default NavItem;
