import { useCallback, useEffect, useState } from 'react';
import { ServiceMetadata } from '../../api/service/serviceApi';
import { getServicesMetadata as loadServicesMetadata } from '../../api/service/serviceService';

const SERVICES_METADATA_KEY = 'servicesMetadata';

export const getServicesMetadata = (): ServiceMetadata[] => {
  const servicesMetadata = window.sessionStorage.getItem(SERVICES_METADATA_KEY);
  return JSON.parse(servicesMetadata === null ? '[]' : servicesMetadata);
};

const ServicesMetadataLoader = ({ children }: { children: any }) => {
  const [servicesMetadataLoaded, setServicesMetadataLoaded] = useState(
    getServicesMetadata().length > 0
  );

  const callback = useCallback(() => {
    if (getServicesMetadata().length === 0) {
      loadServicesMetadata()
        .then((servicesMetadata: ServiceMetadata[]) =>
          window.sessionStorage.setItem(
            SERVICES_METADATA_KEY,
            JSON.stringify(servicesMetadata)
          )
        )
        .then(() => setServicesMetadataLoaded(true));
    }
  }, []);
  useEffect(() => callback());

  return servicesMetadataLoaded && children;
};

export default ServicesMetadataLoader;
