import { MiscInterface } from './MiscInterface';
import { checkOk } from '../../../api/common';

export function saveMisc(configuration: MiscInterface) {
  return fetch(`/api/v1/settings/misc/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(configuration)
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function getMisc(): Promise<MiscInterface> {
  return fetch(`/api/v1/settings/misc/get`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}
