import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export interface ReportMetadata {
  id: number;
  createdAt: string;
  name: string;
}

interface Props {
  onChange: (event: any, reportMetadata: ReportMetadata | null) => void;
  selectedName?: string;
}

const ReportSelector: React.FC<Props> = ({ onChange, selectedName }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<ReportMetadata[]>([]);
  const [value, setValue] = React.useState<ReportMetadata | null>(null);
  const renamed = selectedName != null && value?.name !== selectedName;
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }
    (async () => {
      const response = await fetch(
        '/api/v1/component-versions/reports/metadata',
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        }
      );
      const data = await response.json();

      if (active) {
        setOptions([...data]);
        if (data.length === 0) {
          alert('No reports available');
          setOpen(false); // Close the Autocomplete if the response is empty
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (renamed) {
      const virtualValue = { ...value } as ReportMetadata;
      virtualValue.name = selectedName;
      setValue(virtualValue);
    }
  }, [renamed]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event, reportMetadata) => {
        setValue(reportMetadata);
        if (reportMetadata !== null) {
          onChange(event, reportMetadata);
        }
      }}
      isOptionEqualToValue={(option, inputValue) =>
        option.name === inputValue.name
      }
      getOptionLabel={(option: ReportMetadata) => option.name}
      options={options.sort((a, b) => b.createdAt.localeCompare(a.createdAt))}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Select Audit Report"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};

export default ReportSelector;
