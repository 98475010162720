import * as React from 'react';
import { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { v4 as uuidv4 } from 'uuid';
import CircleIcon from '@mui/icons-material/Circle';
import {
  getSeverityColor,
  Severity
} from '../../features/vulnerabilities/Severity';

function createData(title, status, criticality) {
  return { title, status, criticality };
}

function parseDataFromApi(latestVulnerabilities) {
  let i = 0;
  const lengthOfTotalVulnerabilities = latestVulnerabilities.length / 3;
  const funcArrayOfData = [];

  // Process available data
  while (i < lengthOfTotalVulnerabilities) {
    funcArrayOfData.push(
      createData(
        latestVulnerabilities[i * 3 + 2],
        latestVulnerabilities[i * 3 + 1],
        latestVulnerabilities[i * 3]
      )
    );
    i++;
  }

  // Fill with empty data if less than 7 items
  while (funcArrayOfData.length <= 7) {
    funcArrayOfData.push(createData('', '', ''));
  }

  return funcArrayOfData;
}

const LatestBugs = (props) => {
  const [latestVulnerabilities, setLatestVulnerabilities] = useState([]);

  useEffect(() => {
    fetch(`/api/v1/vulnerability-assessment/stats/getlastvulnerabilities`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response;
      })
      .then((response) => response.json())
      .then((res) => {
        setLatestVulnerabilities(res);
      })
      .catch((reason) => console.log(reason));
  }, []);

  const rows = parseDataFromApi(latestVulnerabilities);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Last Discovered Vulnerabilities</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Criticality</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={uuidv4()}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  pt: row.title.trim() === '' ? '26px' : undefined,
                  pb: row.title.trim() === '' ? '26px' : undefined
                }}
              >
                {row.criticality && (
                  <CircleIcon
                    sx={{
                      color: getSeverityColor(row.criticality),
                      fontSize: 'small',
                      mr: 2
                    }}
                  />
                )}
                {row.title}
              </TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">{row.criticality}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LatestBugs;
