import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import {
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import TextArea from 'antd/es/input/TextArea';
import {
  ActivatedPoliciesInterface,
  CHANGE_TYPE,
  REDRAYS_VALUES
} from '../../api/sapBtp/sapBtpApi';
import { getClassIdText } from '../../api/sapBtp/sapBtpService';

interface AlertEventsDialogProps {
  open: boolean;
  onClose: () => void;
  alertEvents: ActivatedPoliciesInterface;
  onSetupAlert: () => void;
  onParameterChange: (name: string, value: string) => void; // Callback function for change type selection
}

const AlertEventsDialog: React.FC<AlertEventsDialogProps> = ({
  open,
  onClose,
  alertEvents,
  onSetupAlert,
  onParameterChange
}) => {
  const [selectedType, setSelectedType] = useState<string>(
    CHANGE_TYPE.VALUES_CHANGED
  ); // Initialize with empty string

  useEffect(() => {
    if (alertEvents.changeType) {
      setSelectedType(alertEvents.changeType); // Update selectedType if alertEvents.changeType changes
    }
  }, [alertEvents.changeType]);

  const handleEmptyFunction = () => {
    // This function is empty
  };

  const [previousValue, setPreviousValue] = useState<string>('');

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Update the previous value when the checkbox is checked
    if (event.target.checked) {
      setPreviousValue(alertEvents.trackChange);
      onParameterChange('trackChange', REDRAYS_VALUES.REDRAYS_ANY_VALUE);
    } else {
      // Revert to the previous value when the checkbox is unchecked
      onParameterChange('trackChange', previousValue);
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: 'white',
          color: 'black',
          fontSize: '1.2rem',
          mb: 2
        }}
      >
        <Typography variant="h1">Setup Alerting</Typography>
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Tracking Object"
              value={getClassIdText(alertEvents.classId)}
              onChange={handleEmptyFunction}
              fullWidth
              margin="dense"
              sx={{ cursor: 'default' }}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              sx={{ mt: 1 }}
              onChange={(e) =>
                onParameterChange('changeType', e.target.value as string)
              } // Handle change event
              fullWidth
              variant="outlined"
              margin="dense"
            >
              {Object.entries(CHANGE_TYPE).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Tracking Path"
              value={alertEvents.changePath}
              onChange={handleEmptyFunction}
              fullWidth
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            {alertEvents.trackChange !== REDRAYS_VALUES.REDRAYS_ANY_OBJECT ? (
              <TextField
                label="Tracking Value"
                disabled={
                  alertEvents.trackChange ===
                    REDRAYS_VALUES.REDRAYS_ANY_OBJECT ||
                  alertEvents.trackChange === REDRAYS_VALUES.REDRAYS_ANY_VALUE
                }
                value={alertEvents.trackChange}
                onChange={(e) =>
                  onParameterChange('trackChange', e.target.value)
                }
                fullWidth
                margin="dense"
              />
            ) : (
              <>
                <TextField
                  label="Tracking Value"
                  value={`Track any parameter changes from ${alertEvents.changePath} path`}
                  InputProps={{
                    readOnly: true,
                    style: { color: '#ff0000' }
                  }}
                  fullWidth
                  margin="dense"
                />
              </>
            )}
          </Grid>
          {/* New checkbox for tracking any value */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    alertEvents.trackChange === REDRAYS_VALUES.REDRAYS_ANY_VALUE
                  }
                  onChange={handleCheckboxChange}
                  disabled={
                    alertEvents.trackChange ===
                    REDRAYS_VALUES.REDRAYS_ANY_OBJECT
                  }
                  color="primary"
                />
              }
              label="Tracking Any Value"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Global Account GUID"
              value={alertEvents.globalAccountGuid}
              onChange={handleEmptyFunction}
              fullWidth
              margin="dense"
            />
          </Grid>
          {alertEvents.subaccountGuid && (
            <Grid item xs={6}>
              <TextField
                label="Subaccount Guid"
                value={alertEvents.subaccountGuid}
                onChange={handleEmptyFunction}
                fullWidth
                margin="dense"
              />
            </Grid>
          )}
          {alertEvents.userEmail && (
            <Grid item xs={6}>
              <TextField
                label="User Email"
                value={alertEvents.userEmail}
                onChange={handleEmptyFunction}
                fullWidth
                margin="dense"
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextArea
            rows={3}
            value={alertEvents.comment}
            onChange={(e) => onParameterChange('comment', e.target.value)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onSetupAlert} color="success">
          Setup Alert
        </Button>
        <Button variant="outlined" onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertEventsDialog;
