import { checkOk } from '../../../api/common';

export interface OpenvpnConfig {
  configFileContent: string;
  username: string;
  password: string;
}

export function getOpenvpnConfiguration(): Promise<OpenvpnConfig> {
  return fetch('/api/v1/settings/openvpn/configuration/get', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export default getOpenvpnConfiguration;
