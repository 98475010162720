import * as React from 'react';
import {
  Autocomplete,
  AutocompleteProps,
  Chip,
  TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BasicGridRowProps } from '../reportgrid/ReportGrid';
import theme from '../../theme';
import { Status } from '../../features/profileparameters/Status';

const updateVulnerability = (
  vulnerabilityId: number,
  property: string,
  aNewValue: string,
  onSuccess: () => void,
  onFail: () => void = () => {
    // do nothing
  }
) => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: `{ "${property}": "${aNewValue}" }`
  };
  fetch(
    `/api/v1/vulnerability-assessment/vulnerabilities/${vulnerabilityId}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response;
    })
    .then((response) => response.json())
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onFail();
    });
};
const ColoredEnumSelector = <
  EnumType extends unknown,
  T extends BasicGridRowProps
>({
  property,
  label,
  getColor,
  EnumValues,
  entity,
  reloadReport,
  variant = 'outlined',
  readonly = false,
  inputWidth,
  ...autocompleteProps
}: {
  property: keyof T;
  label?: string;
  getColor: (e: EnumType) => string;
  EnumValues: { [s: string]: EnumType };
  entity: T;
  reloadReport: () => void;
  variant?: 'outlined' | 'standard';
  readonly?: boolean;
  inputWidth?: number;
} & Partial<AutocompleteProps<any, any, any, any>>): JSX.Element => {
  const value = [entity[property] as EnumType];

  const readonlyProps: Partial<AutocompleteProps<any, any, any, any>> = readonly
    ? {
        open: false,
        popupIcon: '',
        inputValue: ''
      }
    : {};

  const useStyles = makeStyles(() => ({
    root: {
      '& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root': {
        flexWrap: 'nowrap'
      }
    }
  }));

  const classes = useStyles();
  const inputStyle = {
    caretColor: 'transparent', // Hide the input cursor
    width: inputWidth
  };

  function getStatusText(localStatus: string) {
    let enumValue: string;

    switch (localStatus) {
      case Status.NOT_VULNERABLE:
        enumValue = 'Not Vulnerable';
        break;
      case Status.NOT_FOUND:
        enumValue = 'Not Found';
        break;
      case Status.VULNERABLE:
        enumValue = 'Vulnerable';
        break;
      default:
        enumValue = localStatus;
        break;
    }
    return enumValue;
  }

  return (
    <div className={classes.root}>
      <Autocomplete
        {...readonlyProps}
        options={Object.values(EnumValues)}
        multiple
        sx={{
          backgroundColor: getColor(value[0])
        }}
        disableClearable
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              {...getTagProps({ index })}
              label={getStatusText(option as string)}
              sx={{
                backgroundColor: getColor(value[0]),
                borderRadius: 1,
                width: '100%',
                fontWeight: 800,
                color: theme.palette.primary.contrastText
              }}
              onDelete={undefined}
            />
          ));
        }}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            label={label}
            InputProps={{
              ...params.InputProps,
              sx: inputStyle
            }}
          />
        )}
        onChange={(event: React.SyntheticEvent, newValue: EnumType[]) => {
          if (newValue !== null) {
            const lastSelectedValues = newValue.filter(
              (x: EnumType) => !value.includes(x)
            );
            if (lastSelectedValues.length > 0) {
              updateVulnerability(
                entity.id,
                property,
                lastSelectedValues[0] as string,
                reloadReport
              );
            }
          }
        }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Chip
                label={option as string}
                sx={{
                  borderRadius: 1,
                  backgroundColor: getColor(option),
                  fontWeight: 800,
                  color: theme.palette.primary.contrastText
                }}
              />
            </li>
          );
        }}
        fullWidth
        {...autocompleteProps}
      />
    </div>
  );
};

export default ColoredEnumSelector;
