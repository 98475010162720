// todo how to configure eslint for enum properly?
// eslint-disable-next-line import/prefer-default-export
import { green, grey, red } from '@mui/material/colors';

export enum Status {
  VULNERABLE = 'VULNERABLE',
  NOT_VULNERABLE = 'NOT_VULNERABLE',
  NOT_FOUND = 'NOT_FOUND'
}

export const ORDERED_PROFILE_PARAMETERS_STATUSES: Status[] = [
  Status.VULNERABLE,
  Status.NOT_VULNERABLE,
  Status.NOT_FOUND
];

export const getStatusColor = (status: Status) => {
  switch (status) {
    case Status.VULNERABLE:
      return red[500];
    case Status.NOT_VULNERABLE:
      return green[300];
    case Status.NOT_FOUND:
    default:
      return grey[500];
  }
};
