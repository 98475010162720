import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { checkOk } from '../../api/common';
import packageJson from '../../../package.json';

const About = () => {
  const [backendVersion, setBackendVersion] = useState<string>('1.30.1');

  function getBackendVersion() {
    return fetch(`/api/v1/settings/version`, {
      method: 'GET'
    })
      .then(checkOk)
      .then((response) => {
        response.text().then((r) => setBackendVersion(r));
      });
  }

  useEffect(() => {
    getBackendVersion();
  }, []);

  return (
    <Grid
      container
      alignItems="left"
      justifyContent="left"
      spacing={3}
      sx={{ width: '100%' }}
    >
      <Grid
        item
        lg={12}
        sm={6}
        xl={6}
        xs={12}
        alignItems="left"
        justifyContent="left"
        sx={{ pb: 2 }}
      >
        <Grid item>
          <Typography>
            You are using the RedRays Security Platform for SAP systems.
          </Typography>
          <Typography>
            Below you can find the version of frontend and backend that you are
            using:
          </Typography>
          <Typography sx={{ pt: 3 }}>
            Frontend: {packageJson.name}:{packageJson.version}
          </Typography>
          <Typography>Backend: management-console:{backendVersion}</Typography>
          <Typography sx={{ pt: 3 }}>
            The frontend technology is ReactJs, the backend is Java Spring.
          </Typography>
          <Typography sx={{ pt: 3 }}>
            <b>RedRays, Inc</b> <br />
            <br />
            1111B S Governors Ave STE 7629 <br />
            Dover, DE 19904 US <br />
            <br />
            +1-564-544-85-07 <br />
            <br />
            support@redrays.io
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default About;
