import { checkOk } from '../../../../api/common';

export default function TopBugsService(severity: string) {
  return fetch(
    `/api/v1/vulnerability-assessment/stats/get/last/vulnerabilities?severity=${severity}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  )
    .then(checkOk)
    .then((response) => response.json());
}
