import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  colors,
  Divider,
  useTheme
} from '@mui/material';
import alasql from 'alasql';
import {
  ArcElement,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  Tooltip
} from 'chart.js';
import {
  getSeverityColor,
  ORDERED_VULNERABILITY_SEVERITIES,
  Severity
} from '../Severity';
import { Vulnerability } from '../../../api/vulnerabilityAssessment/vulnerabilityApi';

const SeveritiesWidget = ({
  vulnerabilities,
  ...props
}: {
  vulnerabilities: Vulnerability[];
} & Partial<CardProps>) => {
  const theme = useTheme();

  const counts: { severity: Severity; cnt: number }[] = alasql(
    `
      SELECT actual.severity,
             count(actual.severity) as cnt
      FROM ? as ordered
             LEFT JOIN ? as actual ON actual.severity = ordered.severity
      GROUP BY severity
    `,
    [
      ORDERED_VULNERABILITY_SEVERITIES.map((vulnerabilitySeverity) => {
        return { severity: vulnerabilitySeverity };
      }),
      vulnerabilities
    ]
  );

  const data: ChartData<'doughnut', number[], Severity> = {
    datasets: [
      {
        data: counts.map((cnt) => cnt.cnt),
        backgroundColor: ORDERED_VULNERABILITY_SEVERITIES.map((severity) =>
          getSeverityColor(severity)
        ),
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ORDERED_VULNERABILITY_SEVERITIES
  };

  const options: ChartOptions<'doughnut'> = {
    layout: { padding: 0 },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: theme.palette.background.paper,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        enabled: true,
        footerColor: theme.palette.text.secondary,
        intersect: false,
        mode: 'index',
        titleColor: theme.palette.text.primary
      }
    },
    responsive: true
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  return (
    <Card {...props}>
      <CardHeader title="Severity" style={{ textAlign: 'center' }} />
      <Divider />
      <CardContent>
        <Doughnut data={data} options={options} />
      </CardContent>
    </Card>
  );
};
export default SeveritiesWidget;
