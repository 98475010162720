import React, { useEffect, useState } from 'react';
import { green, grey, orange } from '@mui/material/colors';
import { Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import { getValueByKey, updateValueByKey } from '../mailing/EmailingService';
import { SettingsKeys } from '../SettingsKeys';
import { checkOk } from '../../../api/common';
import HotToast from '../../notifications/HotToast';

const Jira = () => {
  const [jiraURL, setJiraURL] = useState<string>();
  const [jiraUser, setJiraUser] = useState<string>();
  const [jiraPass, setJiraPass] = useState<string>();
  const [jiraProjKey, setJiraProjKey] = useState<string>();

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  function printJiraConfiguration() {
    getValueByKey(SettingsKeys.JIRA_URL).then((resp) => {
      if (resp !== undefined) {
        setJiraURL(resp);
      }
    });
    getValueByKey(SettingsKeys.JIRA_USER).then((resp) => {
      if (resp !== undefined) {
        setJiraUser(resp);
      }
    });
    getValueByKey(SettingsKeys.JIRA_PASS).then((resp) => {
      if (resp !== undefined) {
        setJiraPass(resp);
      }
    });
    getValueByKey(SettingsKeys.JIRA_PROJECT_KEY).then((resp) => {
      if (resp !== undefined) {
        setJiraProjKey(resp);
      }
    });
  }

  function createTestIssue() {
    return fetch(`/api/v1/vulnerability-assessment/createTestIssue`, {
      method: 'GET'
    })
      .then(checkOk)
      .then((response) => response.text());
  }

  function handleCreateTestIssue() {
    if (
      jiraURL !== undefined &&
      jiraUser !== undefined &&
      jiraPass !== undefined &&
      jiraProjKey !== undefined
    ) {
      createTestIssue()
        .then((r) =>
          r === '0'
            ? notifySuccess('Test issue has been created.')
            : notifyError(r)
        )
        .catch((reason) => notifyError(reason));
    }
  }

  function handleSave() {
    if (
      jiraURL !== undefined &&
      jiraUser !== undefined &&
      jiraPass !== undefined &&
      jiraProjKey !== undefined
    ) {
      updateValueByKey(SettingsKeys.JIRA_URL, jiraURL);
      updateValueByKey(SettingsKeys.JIRA_USER, jiraUser);
      updateValueByKey(SettingsKeys.JIRA_PASS, jiraPass);
      updateValueByKey(SettingsKeys.JIRA_PROJECT_KEY, jiraProjKey);

      notifySuccess('Jira ITSM configuration successfully saved');
    }
  }

  useEffect(() => {
    printJiraConfiguration();
  }, []);

  return (
    <Grid container>
      {/* Left side of the UI (Form) */}
      <Grid item xs={12} md={6}>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Please enter the Jira ITSM configuration parameters below:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={jiraURL}
              onChange={(event) => setJiraURL(event.target.value)}
              variant="outlined"
              label={jiraURL ? '' : 'Jira ITSM URL'}
              placeholder="https://jira/"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={jiraProjKey}
              onChange={(event) => setJiraProjKey(event.target.value)}
              variant="outlined"
              label={jiraProjKey ? '' : 'Jira ITSM Project Key'}
              placeholder="Project Key"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={jiraUser}
              onChange={(event) => setJiraUser(event.target.value)}
              variant="outlined"
              label={jiraUser ? '' : 'Jira ITSM username'}
              placeholder="Username"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="password"
              value={jiraPass}
              onChange={(event) => setJiraPass(event.target.value)}
              variant="outlined"
              label={jiraPass ? '' : 'Jira ITSM password'}
              placeholder="Password"
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{ pt: 3 }}
            >
              <Grid item>
                <Button
                  sx={{
                    ml: 0,
                    backgroundColor: green[500],
                    '&:hover': {
                      backgroundColor: green[700]
                    }
                  }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={() => handleSave()}
                >
                  Save
                </Button>
              </Grid>

              <Grid item>
                <Button
                  sx={{
                    backgroundColor: grey[500],
                    '&:hover': {
                      backgroundColor: grey[700]
                    }
                  }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={() => handleCreateTestIssue()}
                >
                  Test Issue
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Right side of the UI (Empty space or other content can be added here) */}
      <Grid item xs={12} md={6}>
        {/* Add any additional content or components you want to display on the right side */}
      </Grid>

      <HotToast />
    </Grid>
  );
};
export default Jira;
