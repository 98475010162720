import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import toast from 'react-hot-toast';
import ReactJson from '@vahagn13/react-json-view';
import {
  getBTPCurrentStateById,
  getClassIdText,
  setBTPAlertCollection
} from '../../../api/sapBtp/sapBtpService';
import {
  ActivatedPoliciesInterface,
  BtpCliResponse,
  REDRAYS_VALUES
} from '../../../api/sapBtp/sapBtpApi';
import AlertEventsDialog from '../AlertEventsDialog';
import HotToast from '../../notifications/HotToast';

interface SimpleJsonDrawProps {
  id: number;
}

const SimpleJsonDraw: React.FC<SimpleJsonDrawProps> = ({ id }) => {
  const [jsonConfig, setJsonConfig] = useState({});
  const [btpCliResponse, setBtpCliResponse] = useState<BtpCliResponse>({
    id: 0,
    classId: '',
    json: '',
    globalAccountGuid: '',
    subaccountGuid: '',
    userEmail: '',
    createdAt: ''
  });

  const [alertEvents, setAlertEvents] = useState<ActivatedPoliciesInterface>({
    id: 0,
    classId: '',
    changePath: '',
    changeType: '',
    trackChange: '',
    createdAt: '',
    globalAccountGuid: '',
    subaccountGuid: '',
    comment: '',
    userEmail: '',
    redRaysPoliciesSettings: {
      id: 0,
      classId: '',
      changeType: '',
      changePath: '',
      title: '',
      businessImpact: '',
      severity: '',
      activated: false
    }
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const notifySuccess = (message: string) => toast.success(message);

  const handleSetupAlert = () => {
    const isAnyFieldEmpty =
      !alertEvents.classId &&
      !alertEvents.changePath &&
      !alertEvents.changeType &&
      !alertEvents.globalAccountGuid &&
      !alertEvents.subaccountGuid &&
      !alertEvents.trackChange &&
      !alertEvents.userEmail;

    if (!isAnyFieldEmpty && alertEvents.changeType !== '') {
      setBTPAlertCollection(alertEvents);
      notifySuccess('Alert settings have been applied.');
      setIsDialogOpen(false);
    }
  };

  const handleCopy = (copy: any) => {
    const path = `root['${copy.namespace.join("']['")}']`.replace(
      "['root']",
      ''
    );

    let trackChangeValue: string;
    if (copy.src === null) {
      trackChangeValue = copy.src;
    } else if (typeof copy.src !== 'object') {
      trackChangeValue = copy.src;
    } else {
      trackChangeValue = REDRAYS_VALUES.REDRAYS_ANY_OBJECT;
    }

    setAlertEvents((prevState) => ({
      ...prevState,
      trackChange: trackChangeValue,
      classId: btpCliResponse.classId,
      globalAccountGuid: btpCliResponse.globalAccountGuid,
      subaccountGuid: btpCliResponse.subaccountGuid,
      userEmail: btpCliResponse.userEmail,
      changePath: path,
      changeType: ''
    }));

    setIsDialogOpen(true);
  };

  useEffect(() => {
    if (id !== null || id !== -1)
      getBTPCurrentStateById(id.toString())
        .then((cliResponse) => {
          setBtpCliResponse(cliResponse);
          setJsonConfig(JSON.parse(cliResponse.json));
        })
        .catch((error) => {
          console.error('Error fetching global account list:', error);
        });
  }, [id]);

  const onSelect = (e: any) => {
    // Build and set selected path
    console.log(e);
    // setSelectedPath(`['${e.namespace.join("']['")}']['${e.name}']`);
  };

  const handleParameterChange = (paramName: string, value: string) => {
    setAlertEvents((prevState) => ({
      ...prevState,
      [paramName]: value
    }));
  };

  return (
    <div>
      <ReactJson
        collapseStringsAfterLength={80}
        displayDataTypes={false}
        displayArrayKey={false}
        displayObjectSize={false}
        src={jsonConfig}
        onSelect={onSelect}
        enableClipboard={handleCopy}
      />
      <AlertEventsDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        alertEvents={alertEvents}
        onSetupAlert={handleSetupAlert}
        onParameterChange={handleParameterChange}
      />
      <HotToast />
    </div>
  );
};

export default SimpleJsonDraw;
