/* eslint-disable import/prefer-default-export */
export enum SettingsKeys {
  REPORT_SENDING_FROM_EMAIL = 'REPORT_SENDING_FROM_EMAIL',
  REPORT_SENDING_TO_EMAIL = 'REPORT_SENDING_TO_EMAIL',
  SMTP_HOST = 'SMTP_HOST',
  SMTP_PORT = 'SMTP_PORT',
  FROM_USER_PASSWORD = 'FROM_USER_PASSWORD',
  MAIL_TRANSPORT_PROTOCOL = 'MAIL_TRANSPORT_PROTOCOL',
  MAIL_SMTP_AUTH = 'MAIL_SMTP_AUTH',
  MAIL_SMTP_STARTTLS_ENABLE = 'MAIL_SMTP_STARTTLS_ENABLE',
  MAIL_DEBUG = 'MAIL_DEBUG',
  JIRA_URL = 'JIRA_URL',
  JIRA_USER = 'JIRA_USER',
  JIRA_PASS = 'JIRA_PASS',
  JIRA_PROJECT_KEY = 'JIRA_PROJECT_KEY'
}
