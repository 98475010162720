import { blue, green, red } from '@mui/material/colors';
import { Avatar, Tooltip } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// eslint-disable-next-line import/prefer-default-export
export const getOverallAvatar = (status) => {
  switch (status) {
    case 20:
      return (
        <Tooltip title="Overall risk is extremely high">
          <Avatar
            sx={{
              backgroundColor: red[900],
              height: 56,
              width: 56
            }}
          >
            <WarningIcon />
          </Avatar>
        </Tooltip>
      );
    case 40:
      return (
        <Tooltip title="Overall risk is high">
          <Avatar
            sx={{
              backgroundColor: red[400],
              height: 56,
              width: 56
            }}
          >
            <WarningIcon />
          </Avatar>
        </Tooltip>
      );
    case 70:
      return (
        <Tooltip title="Overall risk is medium">
          <Avatar
            sx={{
              backgroundColor: blue[400],
              height: 56,
              width: 56
            }}
          >
            <CheckCircleIcon />
          </Avatar>
        </Tooltip>
      );
    case 90:
      return (
        <Tooltip title="Overall risk is low">
          <Avatar
            sx={{
              backgroundColor: green[400],
              height: 56,
              width: 56
            }}
          >
            <CheckCircleIcon />
          </Avatar>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="The systems are secure or not scanned yet">
          <Avatar
            sx={{
              backgroundColor: green[400],
              height: 56,
              width: 56
            }}
          >
            <CheckCircleIcon />
          </Avatar>
        </Tooltip>
      );
  }
};
