import React, { useEffect, useRef, useState } from 'react';
import { DagreFundFlowGraph, IGraph } from '@ant-design/charts';
import { grey } from '@mui/material/colors';
import { IEdge } from '@antv/g6';
import Button from '@mui/material/Button';
import { AlertColor, Box, Grid, Tooltip, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
  getChartMetadata,
  getConnectionNames,
  updateGraph
} from './ThreatModellingServices';
import HotToast from '../../components/notifications/HotToast';

const ThreatModelling = () => {
  interface VulnerabilityItemProps {
    bgcolor: string;
    text: string;
  }

  const VulnerabilityItem = ({ bgcolor, text }: VulnerabilityItemProps) => (
    <Grid container xl={12} sx={{ marginTop: bgcolor === '#000000' ? 3 : 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor,
          width: 24,
          height: 24,
          marginRight: 1
        }}
      >
        <></>
      </Box>
      <Typography variant="subtitle2">{text}</Typography>
    </Grid>
  );

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };

  interface ISnackbarProps {
    opened: boolean;
    text: string;
    severity: AlertColor;
  }

  const hiddenSnackbar: ISnackbarProps = {
    opened: false,
    text: '',
    severity: 'error'
  };
  const [open, setOpen] = React.useState(false);
  const [connectionName, setConnectionName] = React.useState<string>();
  const [snackbar, setSnackbar] = useState(hiddenSnackbar);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setConnectionName('');
    setOpen(false);
  };

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  function handleUpdate() {
    updateGraph().then((resp) => {
      if (resp === 'OK')
        notifySuccess(
          `The request for graph updating was sent. You will receive an email when it will do.`
        );
    });
  }

  const onConnectionClick = (source: string, target: string) => {
    // get connection name
    getConnectionNames(source, target).then((resp) => {
      let s = '';
      for (let i = 0; i < resp.length; i++) {
        s = s.concat(resp[i], '\n'); // Use newline instead of space
      }
      setConnectionName(s);
    });
    handleOpen();
  };

  const [nnodes, setNnodes] = useState<{ id: string; label: string }[]>();
  const [nedges, setNedges] = useState<
    {
      source: string;
      target: string;
      dataType: string;
      label: string;
    }[]
  >();
  const [chartLoaded, setChartLoaded] = useState(false); // New state to track chart loading

  useEffect(() => {
    getChartMetadata().then((resp) => {
      const { nodes, edges } = resp;
      setNnodes(nodes);
      setNedges(edges);
    });
  }, []);

  const [config, setConfig] = useState({
    data: {
      nodes: nnodes,
      edges: nedges
    },
    colorMap: {
      0: '#000000',
      1: '#72CC4A',
      2: '#FFAA15',
      3: '#ff1515',
      4: '#b70000'
    },
    behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node']
  });

  useEffect(() => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      data: {
        nodes: nnodes,
        edges: nedges
      }
    }));
    setChartLoaded(true); // Mark chart as loaded
  }, [nnodes, nedges]);

  const ref = useRef();
  const handleEdgeClick = (item: IEdge, graph: IGraph) => {
    // graph.setItemState(item, 'selected', true);
    const source = item.getSource().getModel().id;
    const target = item.getTarget().getModel().id;
    console.log(source, '>', target);
    if (source !== undefined && target !== undefined)
      onConnectionClick(source, target);
  };
  const handleNodeClick = (item: any, graph: any) => {
    graph.setItemState(item, 'selected', true);
  };
  const handleCanvasClick = (graph: any) => {
    const selectedEdges = graph.findAllByState('edge', 'selected');
    selectedEdges.forEach((edge: any) => {
      graph.setItemState(edge, 'selected', false);
    });
    const selectedNodes = graph.findAllByState('node', 'selected');
    selectedNodes.forEach((node: any) => {
      graph.setItemState(node, 'selected', false);
    });
  };
  const edgeStateStyles = {
    hover: {
      stroke: '#1890ff',
      lineWidth: 2
    },
    selected: {
      stroke: '#f00',
      lineWidth: 3
    }
  };
  const nodeStateStyles = {
    hover: {
      stroke: '#1890ff',
      lineWidth: 2
    },
    selected: {
      stroke: '#f00',
      lineWidth: 3
    }
  };

  const nodeStyleConst = { stroke: '#40a9ff' };

  let windowHeight = 0;

  useEffect(() => {
    const handleResize = () => {
      windowHeight = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <title>Threat Modelling Map | RedRays</title>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{
          width: '100%',
          height: 'calc(100vh - 170px)',
          pl: 1,
          pt: 2.5,
          ml: 0
        }}
      >
        <Grid
          xl={10}
          sm={10}
          xs={12}
          sx={{ boxShadow: 1, backgroundColor: '#fff' }}
        >
          {nnodes !== undefined && nedges !== undefined && chartLoaded ? (
            <DagreFundFlowGraph
              height={1000}
              nodeStyle={nodeStyleConst}
              {...config}
              graphRef={ref}
              handleEdgeClick={handleEdgeClick}
              handleCanvasClick={handleCanvasClick}
              edgeStateStyles={edgeStateStyles}
              nodeStateStyles={nodeStateStyles}
              handleNodeClick={handleNodeClick}
              autoFit={false}
            />
          ) : (
            <Grid container>
              <Grid item sm={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ pt: '10%' }}
                >
                  {nnodes === undefined &&
                  nedges === undefined &&
                  !chartLoaded ? (
                    <Typography>
                      The threat map is not available. Please start
                      Vulnerability Assessment or define the landscape and press
                      the &apos;Update threat map&apos; button.
                    </Typography>
                  ) : (
                    <Typography>Chart is loading, Please wait.</Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid xl={2} sm={10} xs={12} sx={{ pl: 2, pb: 2 }}>
          <Grid item xs={12} lg={12} xl={12}>
            <Box
              sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                p: 2
              }}
            >
              <Typography
                variant="h5"
                sx={{ width: '100%', marginBottom: '16px', fontWeight: 'bold' }}
              >
                Supported Connections
              </Typography>
              <div>
                <Tooltip title="HTTP Connection to an SAP System">
                  <Typography variant="subtitle2">Type H ℹ️ ️</Typography>
                </Tooltip>
                <Tooltip title="HTTP Connection to External Server">
                  <Typography variant="subtitle2">Type G ℹ️</Typography>
                </Tooltip>
                <Tooltip title="ABAP Connections">
                  <Typography variant="subtitle2">Type 3 ℹ️</Typography>
                </Tooltip>
                <Tooltip title="Logical Destination">
                  <Typography variant="subtitle2">Type L ℹ️</Typography>
                </Tooltip>
                <Tooltip title="Websocket RFC Destination">
                  <Typography variant="subtitle2">Type W ℹ️</Typography>
                </Tooltip>
              </div>
              <VulnerabilityItem bgcolor="#000000" text="No vulnerability" />
              <VulnerabilityItem bgcolor="#72CC4A" text="Low vulnerability" />
              <VulnerabilityItem
                bgcolor="#FFAA15"
                text="Medium vulnerability"
              />
              <VulnerabilityItem bgcolor="#ff1515" text="High vulnerability" />
              <VulnerabilityItem
                bgcolor="#b70000"
                text="Critical vulnerability"
              />

              <Button
                variant="contained"
                onClick={() => handleUpdate()}
                sx={{ marginTop: '16px', backgroundColor: grey[500] }}
              >
                Refresh Map
              </Button>
              {nnodes?.length === 0 && nedges?.length === 0 && chartLoaded ? (
                <Typography>
                  ⚠️ The threat map is currently unavailable. To access it,
                  please ensure that the SAP systems and system credentials are
                  properly checked.
                </Typography>
              ) : (
                console.log(nnodes?.length, nedges?.length, chartLoaded)
              )}
            </Box>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle
            sx={{
              backgroundColor: 'green',
              color: 'white',
              fontSize: '1.2rem',
              mb: 2
            }}
          >
            Connections name between SAP Systems
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              sx={{ mt: 2, whiteSpace: 'pre-line', fontWeight: 'bold' }}
            >
              The following connections contain sensitive authentication data
              for other SAP systems.
            </Typography>
            <Typography
              variant="body2"
              sx={{ mt: 2, whiteSpace: 'pre-line', fontStyle: 'italic' }}
            >
              {connectionName === undefined || connectionName?.length === 0
                ? 'Connections are loading...'
                : connectionName}
            </Typography>
          </DialogContent>
        </Dialog>
        <HotToast />
      </Grid>
    </>
  );
};

export default ThreatModelling;
