import * as React from 'react';
import {
  DataGrid,
  GridCellParams,
  GridRenderCellParams
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import formatDateTime from '../../components/formatDateTime';
import theme from '../../theme';

export interface ReportMetadata {
  id: number;
  createdAt: string;
  name: string;
}

interface Props {
  onChange: (reportMetadata: ReportMetadata | null) => void;
  selectedName?: string;
}

const ProfileParametersReportDataGrid: React.FC<Props> = ({
  onChange,
  selectedName
}: Props) => {
  const [reports, setReports] = React.useState<ReportMetadata[]>([]);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const gridRef = React.useRef<HTMLDivElement>(null);

  const calculatePageSize = () => {
    if (gridRef.current) {
      const gridHeight = gridRef.current.clientHeight;
      const rowHeight = 52; // Default row height in MUI DataGrid
      const headerHeight = 56; // Default header height
      const calculatedPageSize =
        Math.floor((gridHeight - headerHeight) / rowHeight) - 1;
      setPageSize(Math.max(calculatedPageSize, 1)); // Ensure at least 1 row is shown
    }
  };

  React.useEffect(() => {
    calculatePageSize();
    window.addEventListener('resize', calculatePageSize);
    return () => window.removeEventListener('resize', calculatePageSize);
  }, []);

  const handleCellClick = (params: GridCellParams) => {
    const clickedReportId = params.id;
    const clickedRow = reports.find((report) => report.id === clickedReportId);
    if (clickedRow) {
      onChange(clickedRow);
    } else {
      console.error(`Report with ID ${clickedReportId} not found.`);
    }
  };

  const columns = [
    { field: 'id', headerName: 'Report ID', width: 90 },
    { field: 'name', headerName: 'Report Name', width: 300 },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 250,
      renderCell: (params: GridRenderCellParams) => (
        <span>{formatDateTime(new Date(params.value))}</span>
      )
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 250,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          variant="contained"
          color="success"
          onClick={() => handleCellClick(params)}
        >
          Open Report
        </Button>
      )
    }
  ];

  const fetchReports = React.useCallback(async () => {
    try {
      const response = await fetch(
        '/api/v1/profile-parameters/reports/metadata',
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        }
      );
      const data = await response.json();

      setReports(data);
      if (data.length === 0) {
        console.log('No reports available');
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
    }
  }, []);

  React.useEffect(() => {
    fetchReports();
    const intervalId = setInterval(fetchReports, 1000);
    return () => clearInterval(intervalId);
  }, [fetchReports]);

  return (
    <div ref={gridRef} style={{ height: 'calc(100vh - 196px)', width: '100%' }}>
      <DataGrid
        sx={{
          flexGrow: 1,
          '& .MuiDataGrid-virtualScrollerContent': {
            backgroundColor: theme.palette.background.paper
          },
          '&.MuiDataGrid-root': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
            {
              display: 'none'
            },
          boxShadow: 0.5
        }}
        disableSelectionOnClick
        rows={reports}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />
    </div>
  );
};

export default ProfileParametersReportDataGrid;
