import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';

interface BruteforceOptionDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (
    library: string,
    customPassword: string,
    bruteforcePatternCustom: string
  ) => void;
  libraries: string[];
  patterns: string[];
}

const BruteforceOptionDialog = ({
  open,
  onClose,
  onConfirm,
  libraries,
  patterns
}: BruteforceOptionDialogProps) => {
  const [bruteforceLibrary, setBruteforceLibrary] = useState('');
  const [customPassword, setCustomPassword] = useState('');
  const [customPattern, setCustomPattern] = useState('');

  const handleLibraryChange = (event: SelectChangeEvent<string>) => {
    setBruteforceLibrary(event.target.value);
  };

  const handleCustomPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomPassword(event.target.value);
  };

  const handleCustomPatternChange = (event: SelectChangeEvent<string>) => {
    setCustomPattern(event.target.value);
  };

  const handleConfirmClick = () => {
    if (bruteforceLibrary) {
      onConfirm(bruteforceLibrary, customPassword, customPattern);
      onClose();
    }
  };

  const isSaveButtonDisabled = () => {
    if (bruteforceLibrary === '') {
      return true;
    }
    return (
      bruteforceLibrary === 'Custom' &&
      (customPassword === '' || customPattern === '')
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: 'white',
          color: 'black',
          fontSize: '1.2rem',
          mb: 2
        }}
      >
        <Typography variant="h2">Bruteforce Settings</Typography>
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Please select a bruteforce library and configure the options below to
          proceed.
        </Typography>

        <FormControl fullWidth sx={{ mb: '16px', mt: 2 }}>
          <InputLabel id="library-label" required>
            Select a bruteforce library
          </InputLabel>
          <Select
            labelId="library-label"
            value={bruteforceLibrary}
            onChange={handleLibraryChange}
            required
            label="Select a bruteforce library"
          >
            <MenuItem value="" disabled>
              <em>Select a bruteforce library</em>
            </MenuItem>
            {libraries.map((library) => (
              <MenuItem key={library} value={library}>
                {library}
              </MenuItem>
            ))}
          </Select>
          {bruteforceLibrary === '' && (
            <FormHelperText>
              Choose the library to use for the operation.
            </FormHelperText>
          )}
        </FormControl>

        {bruteforceLibrary === 'Custom' && (
          <>
            <FormControl fullWidth sx={{ mb: '16px', ml: 1, pr: 2 }}>
              <InputLabel htmlFor="custom-password" required>
                Type your initial password
              </InputLabel>
              <Input
                id="custom-password"
                value={customPassword}
                onChange={handleCustomPasswordChange}
              />
              {customPassword === '' && (
                <FormHelperText>
                  Enter the starting password for the custom bruteforce process.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth sx={{ mb: '16px' }}>
              <InputLabel id="pattern-label" required>
                Select a pattern
              </InputLabel>
              <Select
                labelId="pattern-label"
                value={customPattern}
                onChange={handleCustomPatternChange}
                required
                label="Select a pattern"
              >
                <MenuItem value="" disabled>
                  Select a pattern
                </MenuItem>
                {patterns.map((pattern) => (
                  <MenuItem key={pattern} value={pattern}>
                    {pattern}
                  </MenuItem>
                ))}
              </Select>
              {customPattern === '' && (
                <FormHelperText>
                  Choose the pattern that defines how passwords are generated.
                </FormHelperText>
              )}
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleConfirmClick}
          sx={{
            backgroundColor: isSaveButtonDisabled() ? 'gray' : 'green',
            color: 'white',
            '&:hover': {
              backgroundColor: isSaveButtonDisabled() ? 'gray' : 'darkgreen'
            }
          }}
          disabled={isSaveButtonDisabled()}
        >
          Start Bruteforce
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BruteforceOptionDialog;
