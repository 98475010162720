// BruteforcePasswordService.tsx
import { checkOk } from '../../../../api/common';
import { SystemsConnectionMetadata } from '../interface/SystemsConnectionInterface';

export function getNetworkMetadata() {
  return fetch('/api/v1/bruteforce/networks/metadata', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function getNetworkConnectionInformation(networkId: number) {
  return fetch(
    `/api/v1/bruteforce/networks/connection/information?networkId=${networkId}`,
    {
      method: 'GET'
    }
  )
    .then(checkOk)
    .then((response) => response.json());
}

export function sendSystemsConnectionMetadata(item: SystemsConnectionMetadata) {
  return fetch('/api/v1/bruteforce/get/hash/count', {
    method: 'POST',
    body: JSON.stringify(item),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function startHashesBruteforce(item: SystemsConnectionMetadata) {
  return fetch('/api/v1/bruteforce/start', {
    method: 'POST',
    body: JSON.stringify(item),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function getStatusOfBruteforce() {
  return fetch('/api/v1/bruteforce/stats', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text())
    .then((text) => {
      return text === 'true';
    });
}

export function stopBruteforce() {
  return fetch('/api/v1/bruteforce/stop', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export async function downloadCsv(id: number): Promise<Blob> {
  const response = await fetch(`/api/v1/bruteforce/download/csv?id=${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/csv'
    }
  });
  const blob = await response.blob();
  return blob;
}

export async function getProgress() {
  return fetch('/api/v1/bruteforce/progress', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}
export async function getCurrentId() {
  return fetch('/api/v1/bruteforce/current/id', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}
export async function getBruteforceLibrary() {
  return fetch('/api/v1/bruteforce/libraries', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}
export async function getBruteforcePattern() {
  return fetch('/api/v1/bruteforce/patterns', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}
