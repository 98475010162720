import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import {
  DataGrid,
  GridCellParams,
  GridRenderCellParams,
  GridRenderEditCellParams
} from '@mui/x-data-grid';
import LinearProgress, {
  LinearProgressProps
} from '@mui/material/LinearProgress';

import { Box, Button, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { NetworkStatus } from '../../api/network/treeApi';
import formatDateTime from '../../components/formatDateTime';
import {
  getNetworkScanProgress,
  getReportsMetadataByNetworkId
} from '../../api/vulnerabilityAssessment/vulnerabilityAssessmentService';
import {
  ReportMetadata,
  ScanProgressDTO
} from '../../api/vulnerabilityAssessment/vulnerabilityApi';
import theme from '../../theme';

function renderStatusCell(params: GridRenderEditCellParams): ReactNode {
  console.log('Rendering status cell with params:', params);
  switch (params.value) {
    case NetworkStatus.SCANNED:
      return (
        <Tooltip title="The vulnerability assessment has been done successfully">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleIcon style={{ fontSize: '0.9rem', color: 'green' }} />
            <Typography
              variant="body2"
              style={{ fontSize: '0.9rem', color: 'green' }}
              sx={{ ml: 1 }}
            >
              Report is Ready
            </Typography>
          </div>
        </Tooltip>
      );
    case NetworkStatus.SCANNING:
      return (
        <Tooltip title="The scan is in progress">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TimelapseIcon style={{ fontSize: '0.9rem', color: '#ffad00' }} />
            <Typography
              variant="body2"
              style={{ fontSize: '0.9rem', color: '#ffad00' }}
              sx={{ ml: 1 }}
            >
              Scanning...
            </Typography>
          </div>
        </Tooltip>
      );
    case NetworkStatus.ERROR:
      return (
        <Tooltip title="Аn error occurred during the scan">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RunningWithErrorsIcon
              style={{ fontSize: '0.9rem', color: 'red' }}
            />
            <Typography
              variant="body2"
              style={{ fontSize: '0.9rem', color: 'red' }}
              sx={{ ml: 1 }}
            >
              Error
            </Typography>
          </div>
        </Tooltip>
      );
    case NetworkStatus.ORIGINAL:
      return (
        <Tooltip title="Original Network">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AcUnitIcon style={{ fontSize: '0.9rem', color: 'primary' }} />
            <Typography
              variant="body2"
              style={{ fontSize: '0.9rem', color: 'primary' }}
              sx={{ ml: 1 }}
            >
              Unscanned
            </Typography>
          </div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="ERROR, Please contact with RedRays support">
          <NewReleasesIcon color="error" />
        </Tooltip>
      );
  }
}

function LinearProgressWithLabel({
  value,
  ...props
}: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value} {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

interface Props {
  onChange: (reportMetadata: ReportMetadata | null) => void;
  networkId: number;
  uuid: string;
}

const ReportDataGrid: React.FC<Props> = ({ onChange, networkId, uuid }) => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState<ReportMetadata[]>([]);
  const [scanProgressNumber, setScaProgressNumber] =
    useState<ScanProgressDTO>();
  const [pageSize, setPageSize] = useState(15); // Default page size
  const networkIdRef = React.useRef(networkId);

  useEffect(() => {
    const calculatePageSize = () => {
      const gridHeight = window.innerHeight - 196; // Adjust height based on the grid's container
      const rowHeight = 52; // Default row height in MUI DataGrid
      const visibleRows = Math.floor(gridHeight / rowHeight);
      setPageSize(visibleRows);
    };

    calculatePageSize();

    window.addEventListener('resize', calculatePageSize);

    return () => window.removeEventListener('resize', calculatePageSize);
  }, []);

  const handleCellClick = (params: GridCellParams) => {
    const clickedReportId = params.id; // Assuming reportId is the identifier
    const clickedRow = reports.find((report) => report.id === clickedReportId);
    if (clickedRow) {
      onChange(clickedRow);
    } else {
      console.error(`Report with ID ${clickedReportId} not found.`);
    }
  };

  const columns = [
    { field: 'id', headerName: 'Report ID', width: 90 },
    { field: 'name', headerName: 'Report Name', width: 300 },
    {
      field: 'status',
      headerName: 'Status',
      width: 250,
      renderCell: renderStatusCell
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 250,
      renderCell: (params: GridRenderCellParams) => (
        <span>{formatDateTime(new Date(params.value))}</span>
      )
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        const isScanning = params.row.status === NetworkStatus.SCANNING;

        if (
          isScanning &&
          scanProgressNumber &&
          !scanProgressNumber.isFinished
        ) {
          let percentComplete = Math.round(
            (scanProgressNumber.completedScans /
              scanProgressNumber.totalScans) *
              100
          );
          if (percentComplete > 100) {
            percentComplete = 100;
          }
          return (
            <Box sx={{ width: '100%' }}>
              <LinearProgressWithLabel value={percentComplete} />
            </Box>
          );
        }
        return (
          <Button
            variant="contained"
            onClick={() => handleCellClick(params)}
            color="success"
            disabled={isScanning}
          >
            {isScanning ? 'Scanning...' : 'Open Report'}
          </Button>
        );
      }
    }
  ];

  const fetchReports = (networkIdLocal: number) => {
    getReportsMetadataByNetworkId(networkIdLocal).then(
      (data: ReportMetadata[]) => {
        const sortedReports = data.sort((a, b) => b.id - a.id);
        setReports(sortedReports);
        console.log('Fetched reports:', sortedReports);
        setLoading(false);
      }
    );
  };

  const fetchProgress = (networkIdLocal: number) => {
    getNetworkScanProgress(networkIdLocal).then(
      (data: ScanProgressDTO | null) => {
        if (data !== null) {
          setScaProgressNumber(data);
          if (data.isFinished) {
            fetchReports(networkIdLocal); // Call fetchReports with the local networkId
          }
        }
      }
    );
  };

  useEffect(() => {
    setLoading(true);
    const intervalId = setInterval(() => {
      fetchProgress(networkId);
      fetchReports(networkIdRef.current);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [networkId]);

  useEffect(() => {
    setLoading(true);
    fetchReports(networkId);
  }, [networkId, uuid]);

  React.useEffect(() => {
    networkIdRef.current = networkId;
  }, [networkId]);

  return (
    <>
      <DataGrid
        sx={{
          flexGrow: 1,
          '& .MuiDataGrid-virtualScrollerContent': {
            backgroundColor: theme.palette.background.paper
          },
          '&.MuiDataGrid-root': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
            {
              display: 'none'
            },
          boxShadow: 0.5,
          height: 'calc(100vh - 196px)'
        }}
        disableSelectionOnClick
        rows={reports}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
      />
    </>
  );
};

export default ReportDataGrid;
