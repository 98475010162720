import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Grid, TextField, Typography } from '@mui/material';

const RenameDialog = ({
  name,
  opened,
  handleApply,
  handleClose,
  windowName
}: {
  name: string;
  opened: boolean;
  handleApply: (newName: string) => void;
  handleClose: () => void;
  windowName: string;
}) => {
  const [newName, setNewName] = React.useState(name);
  React.useEffect(() => {
    if (!opened) {
      setNewName('');
    }
  }, [opened]);

  return (
    <>
      {opened && (
        <Dialog
          open={opened}
          onClose={handleClose}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleApply(newName);
            }
          }}
          aria-labelledby="rename-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="rename-dialog-title"
            sx={{
              backgroundColor: 'white',
              color: 'black',
              fontSize: '1.2rem', // Increase font size of the title
              mb: 2 // Add margin bottom between title and input label
            }}
          >
            <Typography variant="h2">{windowName}</Typography>
            <Divider sx={{ mt: 2 }} />
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={3} sx={{ pt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  id="rename-dialog-old-name"
                  label="Old name"
                  value={name}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="rename-dialog-new-name"
                  label="New name"
                  value={newName}
                  onChange={(event) => setNewName(event.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="success"
              onClick={() => handleApply(newName)}
              autoFocus
            >
              Apply
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default RenameDialog;
