export const checkOk = (response: Response): Response | Promise<never> => {
  if (!response.ok) {
    return Promise.reject(response);
  }
  return response;
};

export function perform<T>(
  url: RequestInfo,
  requestOptions: RequestInit,
  onSuccess: (data: T) => void,
  onFail: (reason: any) => void
): void {
  fetch(url, requestOptions)
    .then(checkOk)
    .then((response) => response.json())
    .then((data: T) => onSuccess(data))
    .catch((reason) => onFail(reason));
}
