import React, { useEffect, useState } from 'react';
import {
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { blue } from '@mui/material/colors';
import TableHead from '@mui/material/TableHead';
import '../../components/bruteforcepasswords/abap/styles.css';
import { getNetworkMetadata } from '../../components/bruteforcepasswords/abap/service/BruteforcePasswordService';
import { NetworkMetadataResponseInterface } from '../../components/bruteforcepasswords/abap/interface/NetworkMetadataResponseInterface';
import BruteforceFramePage from '../../components/bruteforcepasswords/abap/BruteforceOptionFrame';
import JavaSystemsUI from '../../components/bruteforcepasswords/java/JavaSystemsUI';

const BruteforcePasswordsMain = () => {
  const [selected, setSelected] = useState<number>();
  const [data, setData] = useState<NetworkMetadataResponseInterface[]>([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    getNetworkMetadata()
      .then((resp) => setData(resp))
      .catch((error) => console.error(error));
  }, []);
  const columns = [{ field: 'name', headerName: 'Networks', width: 200 }];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleRowClick = (selection: any, id: number) => {
    setSelected(id);
    console.log(`Clicked network: ${id}`);
  };

  return (
    <>
      <Tabs
        value={tabValue}
        sx={{ pl: '20px', backgroundColor: '#F8F7F7' }}
        onChange={handleTabChange}
      >
        <Tab label="ABAP SYSTEMS" />
        <Tab label="JAVA SYSTEMS" />
      </Tabs>

      {tabValue === 0 && (
        <Grid
          container
          spacing={2}
          style={{ paddingLeft: '20px', backgroundColor: '#F8F7F7' }}
        >
          <Grid xs={12} item sm={3}>
            <TableContainer
              style={{
                height: 'calc(100vh - 160px)',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#ffffff'
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: '#fcfcfc',
                        color: '#333',
                        fontWeight: 'bold',
                        fontSize: '16px',
                        flex: 1,
                        textAlign: 'center'
                      }}
                    >
                      Networks
                      <Tooltip
                        title={
                          <>
                            <Typography color="inherit">
                              Available only for authenticated ABAP systems.
                            </Typography>
                          </>
                        }
                      >
                        <HelpIcon
                          sx={{ fontSize: 'small', ml: '5px' }}
                          style={{ color: blue[500] }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length === 0 ? ( // Check if data array is empty
                    <TableRow>
                      <TableCell style={{ textAlign: 'center' }}>
                        No networks available
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((row) => (
                      <TableRow
                        style={{
                          flex: 1,
                          backgroundColor:
                            selected && selected === row.id
                              ? 'lightgray'
                              : '#ffffff',
                          cursor: 'pointer'
                        }}
                        key={row.id}
                        onClick={(event) => handleRowClick(event, row.id)}
                        className={selected === row.id ? 'selected' : ''}
                      >
                        <TableCell>{row.name}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid
            xs={9}
            container
            spacing={2}
            style={{
              marginTop: 2,
              paddingLeft: 2,
              backgroundColor: '#F8F7F7'
            }}
          >
            <BruteforceFramePage id={selected} />
          </Grid>
        </Grid>
      )}

      {tabValue === 1 && <JavaSystemsUI />}
    </>
  );
};

export default BruteforcePasswordsMain;
