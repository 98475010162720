import { grey } from '@mui/material/colors';
import { Box, Container, Grid, Typography } from '@mui/material';
import TotalChecks from '../components/dashboard/TotalChecks';
import TotalDiscoveredVulnerabilities from '../components/dashboard/TotalDiscoveredVulnerabilities';
import SystemsOverallRisk from '../components/dashboard/SystemsOverallRisk';
import VulnerabilityByStatus from '../components/dashboard/VulnerabilitiesByStatus';
import SapSystems from '../components/dashboard/SapSystems';
import VulnerabilityBySeverity from '../components/dashboard/VulnerabilityBySeverity';
import LatestBugs from '../components/dashboard/LatestBugs';
import TopBugs from '../components/dashboard/tsx/TopBugs';
import { Severity } from '../features/vulnerabilities/Severity';

const Dashboard = () => (
  <>
    <title>Dashboard | RedRays</title>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 1
      }}
    >
      <Container maxWidth={false}>
        <Typography
          style={{ color: grey[600] }}
          display="inline"
          variant="h6"
          paddingLeft={0}
          marginBottom={2}
        >
          RedRays Security Platform &gt;
        </Typography>
        <Typography
          display="inline"
          variant="h6"
          paddingLeft={1}
          paddingTop={2}
        >
          Dashboard
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalChecks />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <SapSystems sx={{ height: '100%' }} />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalDiscoveredVulnerabilities />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <SystemsOverallRisk />
          </Grid>

          <Grid item lg={8} md={12} xl={6} xs={12}>
            <LatestBugs />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <VulnerabilityByStatus />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <VulnerabilityBySeverity />
          </Grid>
          <Grid item lg={4} md={6} xl={6} xs={12}>
            <TopBugs typeOfBugs={Severity.CRITICAL} />
          </Grid>
          <Grid item lg={4} md={6} xl={6} xs={12}>
            <TopBugs typeOfBugs={Severity.HIGH} />
          </Grid>
          <Grid item lg={4} md={6} xl={6} xs={12}>
            <TopBugs typeOfBugs={Severity.MEDIUM} />
          </Grid>
          <Grid item lg={4} md={6} xl={6} xs={12}>
            <TopBugs typeOfBugs={Severity.LOW} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default Dashboard;
