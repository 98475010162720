import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

const Logo = (
  props: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
): JSX.Element => <img alt="Logo2" src="/static/logo_new.png" {...props} />;

export default Logo;
