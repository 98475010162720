import { checkOk } from '../../../api/common';

/* eslint-disable import/prefer-default-export */
export function activateLicense(encryptedLicenseKey: string) {
  return fetch(`/api/v1/license/activate`, {
    method: 'POST',
    body: encryptedLicenseKey
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function getLicenseInfo() {
  return fetch(`/api/v1/license/info`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function getAvailableIP() {
  return fetch(`/api/v1/license/check/getAvailableIp`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}
export function getPortScanProgress() {
  return fetch(`/api/v1/networks/portscan/status`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}
