import { checkOk } from '../../api/common';
import {
  ThreatModelingMetadata,
  ThreatModellingDataInterface,
  ThreatModellingLinksInterface
} from './ThreatModellingInterface';

/* eslint-disable import/prefer-default-export */
export function getAllTMData(): Promise<ThreatModellingDataInterface[]> {
  return fetch(`/api/v1/tm/getTMData`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      return response.json();
    });
}

export function getAllTMLinks(): Promise<ThreatModellingLinksInterface[]> {
  return fetch(`/api/v1/tm/getTMLinks`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      return response.json();
    });
}

export function getChartMetadata(): Promise<ThreatModelingMetadata> {
  return fetch(`/api/v1/tm/chart/metadata`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      return response.json();
    });
}

export function getConnectionNames(source: string, target: string) {
  return fetch(`/api/v1/tm/get/connections/source/${source}/target/${target}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      return response.json();
    });
}

export function updateGraph() {
  return fetch(`/api/v1/tm/update`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      return response.text();
    });
}
