import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import NotFound from './pages/NotFound';
import PortScan from './features/portscan/PortScan';
import VulnerabilityAssessment from './features/vulnerabilities/VulnerabilityAssessment';
import RequireAuth from './components/auth/RequireAuth';
import ProfileParameters from './features/profileparameters/ProfileParameters';
import ComponentVersions from './features/componentversions/ComponentVersions';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import ThreatModelling from './features/threatmodelling/ThreatModelling';
import BruteforcePasswordsMain from './features/bruteforcepasswords/BruteforcePasswordMain';
import SapBtpMain from './features/cloud/SapBtpMain';
import SapNotesMonitoring from './components/sapnotes/SapNotesMonitoring';

const routes = [
  {
    path: 'app',
    element: (
      <RequireAuth>
        <DashboardLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: 'portscan',
        element: <PortScan />
      },
      {
        path: 'vulnerability-assessment',
        element: <VulnerabilityAssessment />
      },
      {
        path: 'profile-parameters',
        element: <ProfileParameters />
      },
      {
        path: 'component-versions',
        element: <ComponentVersions />
      },
      {
        path: '/app/password-security',
        element: <BruteforcePasswordsMain />
      },
      {
        path: '/app/threat-modelling',
        element: <ThreatModelling />
      },
      {
        path: '/app/sap-btp-monitoring',
        element: <SapBtpMain />
      },
      {
        path: '/app/sap-notes',
        element: <SapNotesMonitoring />
      },
      // { path: 'account', element: <Account /> },
      // { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      // { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      // { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
