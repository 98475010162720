import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, Grid, TextField, Typography } from '@mui/material';
import Check from './Check';
import ColoredEnumSelector from '../../components/coloredenumselector/ColoredEnumSelector';
import { getStatusColor, Status } from './Status';
import {
  RowDialog,
  RowDialogProps
} from '../../components/reportgrid/ReportGrid';
import formatDateTime from '../../components/formatDateTime';

const CheckDialog: RowDialog<Check> = ({
  selectedRow,
  opened,
  handleClose,
  reloadReport
}: RowDialogProps<Check>) => {
  return (
    <>
      {selectedRow && opened && (
        <Dialog
          open={opened}
          onClose={handleClose}
          aria-labelledby="check-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="check-dialog-title"
            sx={{
              backgroundColor: 'white',
              color: 'black',
              fontSize: '1.2rem', // Increase font size of the title
              mb: 2 // Add margin bottom between title and input label
            }}
          >
            <Typography variant="h2">
              {'Component Name: '.concat(selectedRow.name)}
            </Typography>
            <Divider sx={{ mt: 2 }} />
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={3} sx={{ pt: 1 }}>
              <Grid item xs={12}>
                <ColoredEnumSelector<Status, Check>
                  id="check-dialog-status"
                  property="status"
                  EnumValues={Status}
                  getColor={getStatusColor}
                  entity={selectedRow}
                  reloadReport={reloadReport}
                  readonly
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="check-dialog-name"
                  label="Name"
                  value={selectedRow.name}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="check-dialog-version"
                  label="Version"
                  value={selectedRow.version}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="check-dialog-patch"
                  label="Patch"
                  value={selectedRow.patch}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="check-dialog-latestPatch"
                  label="Latest Patch"
                  value={selectedRow.latestPatch}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="check-dialog-latestPatch"
                  label="Foundet at"
                  value={formatDateTime(new Date(selectedRow.createdAt))}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CheckDialog;
