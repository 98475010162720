import React from 'react';
import SapBtpMonitoring from '../../components/sapbtp/SapBtpMonitoring';

const SapBtpMain = () => {
  return (
    <>
      <title>SAP BTP Monitoring | RedRays</title>
      <SapBtpMonitoring />
    </>
  );
};

export default SapBtpMain;
