import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InstanceMetadata } from '../../../../../components/instances/InstanceMetadata';
import { INSTANCES_METADATA } from '../../../../../components/instances/InstancesMetadata';

interface Props {
  onChange: (serviceId: number) => void;
  selectedId: number;
}

const InstanceSelector: React.FC<Props> = ({ onChange, selectedId }: Props) => {
  return (
    <Autocomplete
      size="small"
      disableClearable
      sx={{ width: 180 }}
      onChange={(event, option) => {
        if (option?.id !== undefined) {
          onChange(option.id);
        }
      }}
      value={INSTANCES_METADATA.find((option) => option.id === selectedId)}
      isOptionEqualToValue={(option, inputValue) =>
        option?.name === inputValue?.name
      }
      getOptionLabel={(option: InstanceMetadata) =>
        // Format the label with leading zeros if needed
        option.id >= 0 && option.id <= 9
          ? `0${option.id}`
          : `${option.id === -1 ? 'Common Service' : option.name}`
      }
      options={INSTANCES_METADATA}
      renderInput={(params) => (
        <TextField
          variant="standard"
          label="Instance"
          {...params}
          InputProps={{
            ...params.InputProps
            // endAdornment: <>{params.InputProps.endAdornment}</>
          }}
        />
      )}
    />
  );
};

export default InstanceSelector;
