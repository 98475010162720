import * as React from 'react';
import { ReactNode } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import Check from './Check';
import {
  ColumnsGetter,
  ColumnsGetterProps,
  ReportGrid
} from '../../components/reportgrid/ReportGrid';
import CheckDialog from './CheckDialog';
import ColoredEnumSelector from '../../components/coloredenumselector/ColoredEnumSelector';
import { getStatusColor, Status } from './Status';

const columns: ColumnsGetter<Check> = ({
  rows,
  reloadReport
}: ColumnsGetterProps<Check>) => {
  const checkById = new Map(rows.map((check) => [check.id, check]));

  function renderStatusCell(params: GridRenderEditCellParams): ReactNode {
    return (
      <ColoredEnumSelector<Status, Check>
        id={`check-status-${params.id}`}
        property="status"
        EnumValues={Status}
        getColor={getStatusColor}
        entity={checkById.get(params.id as number)!}
        reloadReport={reloadReport}
        variant="standard"
        inputWidth={200}
        readonly
      />
    );
  }

  return [
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: renderStatusCell
    },
    {
      field: 'name',
      headerName: 'Component name',
      width: 200
    },
    {
      field: 'version',
      headerName: 'Component Version',
      width: 200
    },
    {
      field: 'patch',
      headerName: 'Current Patch',
      width: 150
    },
    {
      field: 'latestPatch',
      headerName: 'Available Patch',
      width: 150
    }
  ];
};

const Checks = ({
  rows,
  reloadReport
}: {
  rows: Check[];
  reloadReport: () => void;
}): JSX.Element => (
  <ReportGrid
    rows={rows}
    columnsGetter={columns}
    reloadReport={reloadReport}
    Dialog={CheckDialog}
    noRowsText="Outdated components are not found, or the audit report is not selected. Please run the scan and choose a new report."
  />
);

export default Checks;
