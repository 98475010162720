import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  TextField,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StorageIcon from '@mui/icons-material/Storage';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Add, Cookie } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import toast from 'react-hot-toast';
import DeleteButton from '../delete-button/DeleteButton';
import { Instance, Ip } from '../../../../api/network/treeApi';
import UniqueKeysManager from '../../../../components/UniqueKeysManager';
import InstanceNode from './instance/InstanceNode';
import INSTANCES_METADATA from '../../../../components/instances/InstancesMetadata';
import { SapSidStructure } from '../../SapSidStructure';
import {
  getSidByIp,
  getSystemTypeByIp,
  setSystemTypeByIp
} from '../../../../api/network/networkService';
import {
  deleteCustomCookies,
  getCustomCookies,
  saveCustomCookies
} from '../../../../components/settings/customcookie/CustomCookiesService';
import HotToast from '../../../../components/notifications/HotToast';

interface Props {
  ip: Ip;
  onChange: (ip: Ip) => void;
  onDelete: () => void;
  expandedAll: boolean;
  collapsedAll: boolean;
  showSid: boolean;
}

export interface SystemTypeInterface {
  host: string;
  systemType: string;
}

const IpNode: React.FC<Props> = ({
  ip,
  onChange,
  onDelete,
  expandedAll,
  collapsedAll,
  showSid
}: Props) => {
  const [sapSid, setSapSid] = useState<SapSidStructure[] | null>(null);
  const [loading, setLoading] = useState(true);
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const SystemTypeOptions = [
    { value: '1', label: 'Select System Type' },
    { value: '2', label: 'DEV' },
    { value: '3', label: 'TEST' },
    { value: '4', label: 'QA' },
    { value: '5', label: 'STAGING' },
    { value: '6', label: 'PROD' }
  ];
  const [systemType, setSystemType] = useState<SystemTypeInterface | null>(
    null
  );

  const fetchSidAndSystemTypeData = async () => {
    try {
      if (ip.ip !== '' && showSid) {
        setLoading(true);
        const sidData = await getSidByIp(ip.ip);

        const systemTypeData = await getSystemTypeByIp(ip.ip);
        setSapSid(sidData);
        setSystemType(systemTypeData);

        setLoading(false);
      }
    } catch (error) {
      // setSnackMessage('Error fetching SAP SID');
      // setOpenSnack(true);
      console.error('Error fetching SapSidStructure:', error);
    }
  };

  const handleSystemTypeChange = async (event: SelectChangeEvent<string>) => {
    const newTypeId = event.target.value;
    const selectedSystemType = SystemTypeOptions.find(
      (option) => option.value === newTypeId
    );

    try {
      await setSystemTypeByIp(ip.ip, Number(selectedSystemType?.value));
      fetchSidAndSystemTypeData(); // Call the shared function

      notifySuccess('System type updated successfully');
    } catch (error) {
      notifyError('Error setting system type');
    }
  };

  useEffect(() => {
    if (showSid) fetchSidAndSystemTypeData(); // Call the shared function
  }, [ip.ip, showSid]);

  const renderBadges = () => {
    const sids = sapSid?.map((item) => item.sid) || [];
    const uniqueSids = [...new Set(sids)];

    return uniqueSids.map((sid, index) => (
      <Badge sx={{ mr: 5 }} key={sid} color="secondary" badgeContent={sid}>
        <></>
      </Badge>
    ));
  };

  const ADD_BUTTON_INSTANCE_ID = -2;

  const [expanded, setExpanded] = React.useState(true);
  React.useEffect(() => {
    setExpanded(!collapsedAll && (expandedAll || expanded));
  }, [expandedAll, collapsedAll]);

  const keysRef = React.useRef(new UniqueKeysManager<Instance>());
  const keys = keysRef.current;

  const existingInstanceIds = ip.instances.map(
    (instance) => instance.instanceId
  );
  const existingInstanceIdsSet = new Set(existingInstanceIds);
  const nextInstanceId = INSTANCES_METADATA.map((metadata) => metadata.id).find(
    (id) => !existingInstanceIdsSet.has(id)
  );

  const [selectedInstance, setSelectedInstance] = React.useState(
    existingInstanceIds[0] ?? ADD_BUTTON_INSTANCE_ID
  );

  const handleAddInstance = () => {
    const newInstance: Instance = {
      // Add button is disabled when nextInstanceId === undefined,
      // so it's safe to assume it's not undefined
      instanceId: nextInstanceId!,
      services: []
    };
    onChange({
      ...ip,
      instances: [...ip.instances, { ...newInstance }].sort(
        (a, b) => a.instanceId - b.instanceId
      )
    });
    setSelectedInstance(nextInstanceId!);
  };

  const handleDeleteInstance = (instance: Instance) => {
    keys.delete(instance);
    onChange({
      ...ip,
      instances: ip.instances.filter((anInstance) => instance !== anInstance)
    });
    const firstBeforeRemoved =
      existingInstanceIds
        .slice()
        .reverse()
        .find((id) => id < instance.instanceId) ?? existingInstanceIds[1];
    setSelectedInstance(firstBeforeRemoved);
  };

  const [isCookieDialogOpen, setCookieDialogOpen] = useState(false);

  const [selectedIp, setSelectedIp] = useState('');
  const [cookieValue, setCookieValue] = useState('');

  const handleCookieClick = (ipForCustomCookie: string) => {
    setSelectedIp(ipForCustomCookie);
    setCookieDialogOpen(true);
  };

  useEffect(() => {
    if (ip.ip) {
      getCustomCookies(ip.ip)
        .then((customCookiesData) => {
          setCookieValue(customCookiesData.cookieValue);
        })
        .catch((error) => {
          console.error('Error fetching custom cookies:', error);
        });
    }
  }, [ip.ip]);

  const handleCookieDialogClose = () => {
    setCookieDialogOpen(false);
    getCustomCookies(ip.ip)
      .then((customCookiesData) => {
        setCookieValue(customCookiesData.cookieValue);
      })
      .catch((error) => {
        setCookieValue('');
        console.error('Error fetching custom cookies:', error);
      });
  };

  const handleSaveButtonClick = (host: string) => {
    saveCustomCookies({ host, cookieValue })
      .then((response) => {
        notifySuccess(`Custom Cookies Saved For ${host}`);
        console.log('Response:', response);
        handleCookieDialogClose();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleDeleteButtonClick = (host: string) => {
    deleteCustomCookies(host)
      .then((response) => {
        notifySuccess(`Custom Cookies Deleted For ${host}`);
        console.log('Response:', response);
        handleCookieDialogClose();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      {/* Container for relative positioning */}
      <Accordion
        expanded={expanded}
        onChange={(event: React.SyntheticEvent, isExpanded: boolean) => {
          setExpanded(isExpanded);
        }}
        sx={{ background: grey[50] }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${ip.ip}-content`}
          id={`${ip.ip}-header`}
        >
          <Stack
            direction="row"
            alignItems="center"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
          >
            <TextField
              variant="standard"
              value={ip.ip}
              placeholder="Host"
              size="small"
              sx={{ width: '400px', pr: 3 }}
              onChange={(e) => {
                onChange({ ...ip, ip: e.target.value });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Cookie
                      color="action"
                      style={{
                        color:
                          cookieValue && cookieValue.trim() !== ''
                            ? 'green'
                            : 'gray',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleCookieClick(ip.ip)}
                    />
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                shrink: true
              }}
              label={
                <span>
                  <StorageIcon
                    style={{
                      marginRight: 5,
                      color: 'gray',
                      verticalAlign: 'bottom' // Aligns icon with text
                    }}
                  />
                  Host
                </span>
              }
            />

            {showSid && (
              <>
                <Select
                  value={systemType?.systemType || '1'}
                  sx={{ height: '36px', mr: 4 }}
                  onChange={handleSystemTypeChange}
                >
                  {SystemTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Box display="flex" alignItems="center">
                        <Box ml={1}>
                          <Typography variant="h6">{option.label}</Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  {renderBadges()}
                </Box>
              </>
            )}
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <TabContext value={`${selectedInstance}`}>
            <Stack
              direction="row"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab
                label="Add a new instance"
                icon={<Add />}
                iconPosition="end"
                sx={{ textTransform: 'none' }}
                value={ADD_BUTTON_INSTANCE_ID}
                disabled={nextInstanceId === undefined}
                onClick={handleAddInstance}
              />
              <TabList
                variant="scrollable"
                scrollButtons="auto"
                // value passed as string to conform with value type in
                // TabPanel which is always a string type
                onChange={(event: React.SyntheticEvent, newValue: string) => {
                  if (+newValue !== ADD_BUTTON_INSTANCE_ID) {
                    setSelectedInstance(+newValue);
                  }
                }}
              >
                {ip.instances !== undefined &&
                  ip.instances.length > 0 &&
                  ip.instances.map((instance: Instance) => {
                    const name = INSTANCES_METADATA.find(
                      (option) => option.id === instance.instanceId
                    )?.name;
                    return (
                      <Tab
                        key={keys.ensure(instance)}
                        label={
                          instance.instanceId === -1
                            ? name
                            : `Instance: ${
                                instance.instanceId >= 0 &&
                                instance.instanceId <= 9 // fix 0, 1, 2 instance names to 00, 01, 02 and others
                                  ? String(instance.instanceId).padStart(2, '0')
                                  : name
                              }`
                        }
                        icon={
                          selectedInstance === instance.instanceId ? (
                            <DeleteButton
                              onClick={() => handleDeleteInstance(instance)}
                              tooltipTitle="Delete instance"
                            />
                          ) : (
                            <></>
                          )
                        }
                        iconPosition="end"
                        sx={{
                          textTransform: 'none',
                          backgroundColor:
                            selectedInstance === instance.instanceId
                              ? '#ffffff'
                              : 'inherit', // Set background color conditionally
                          borderRadius:
                            selectedInstance === instance.instanceId
                              ? '10px'
                              : '0' // Set border radius conditionally
                        }}
                        // value passed as string to conform with value type in
                        // TabPanel which is always a string type
                        // if not to do so, the selected tab is not highlighted
                        // as selected
                        value={`${instance.instanceId}`}
                      />
                    );
                  })}
              </TabList>
            </Stack>
            {ip.instances !== undefined &&
              ip.instances.length > 0 &&
              ip.instances.map((instance: Instance) => (
                <TabPanel
                  key={keys.ensure(instance)}
                  value={`${instance.instanceId}`}
                  sx={{ backgroundColor: '#ffffff' }}
                >
                  <InstanceNode
                    instance={instance}
                    onChange={(updatedInstance) => {
                      keys.replace(instance, updatedInstance);
                      onChange({
                        ...ip,
                        instances: ip.instances
                          .map((anInstance) =>
                            instance === anInstance
                              ? updatedInstance
                              : anInstance
                          )
                          .sort((a, b) => a.instanceId - b.instanceId)
                      });
                      setSelectedInstance(updatedInstance.instanceId);
                    }}
                    onDelete={() => handleDeleteInstance(instance)}
                  />
                </TabPanel>
              ))}
          </TabContext>
        </AccordionDetails>

        <Button
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          variant="outlined"
          size="small"
          style={{
            bottom: 0,
            right: 0,
            marginLeft: 16,
            marginBottom: 8,
            color: '#ff0000',
            borderRadius: 0
          }}
        >
          Delete Host
        </Button>
      </Accordion>

      <Dialog
        open={isCookieDialogOpen}
        onClose={handleCookieDialogClose}
        fullWidth
      >
        <DialogTitle
          sx={{
            backgroundColor: 'white',
            color: 'black',
            fontSize: '1.2rem', // Increase font size of the title
            mb: 2 // Add margin bottom between title and input label
          }}
        >
          <Typography variant="h2">
            Add Custom Cookie for {selectedIp}
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </DialogTitle>
        <DialogContent>
          <Box mb={2} display="flex" alignItems="center">
            <InputLabel sx={{ minWidth: 80, marginRight: 1, color: 'black' }}>
              Cookie:
            </InputLabel>
            <TextField
              sx={{ mt: 2 }}
              value={cookieValue}
              onChange={(e) => setCookieValue(e.target.value)}
              fullWidth
              multiline
              rows={6}
              variant="outlined"
              label="Your Custom Cookie Here."
              size="small"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ color: 'white', backgroundColor: 'green' }}
            onClick={() => handleSaveButtonClick(selectedIp)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            sx={{ color: 'red' }}
            onClick={() => handleDeleteButtonClick(selectedIp)}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCookieDialogClose}
          >
            Close
          </Button>
        </DialogActions>
        <HotToast />
      </Dialog>
    </>
  );
};

export default IpNode;
