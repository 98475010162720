import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import IpNode from './ip/IpNode';
import { Ip, Tree } from '../../../api/network/treeApi';
import UniqueKeysManager from '../../../components/UniqueKeysManager';
import AddButton from '../../../components/tree/AddButton';

interface Props {
  tree: Tree;
  onChange: (tree: Tree) => void;
  expandedAll: boolean;
  collapsedAll: boolean;
  showSid: boolean;
  isScanningStatus: boolean;
}

function renderIpNodes(
  tree: Tree,
  keys: UniqueKeysManager<Ip>,
  onChange: (updatedTree: Tree) => void,
  expandedAll: boolean,
  collapsedAll: boolean,
  showSid: boolean
) {
  return tree.ips.map((ip) => (
    <IpNode
      key={keys.ensure(ip)}
      ip={ip}
      onChange={(updatedIp) => {
        keys.replace(ip, updatedIp);
        onChange({
          ...tree,
          ips: tree.ips.map((anIp) => (ip === anIp ? updatedIp : anIp))
        });
      }}
      onDelete={() => {
        keys.delete(ip);
        onChange({
          ...tree,
          ips: tree.ips.filter((anIp) => ip !== anIp)
        });
      }}
      expandedAll={expandedAll}
      collapsedAll={collapsedAll}
      showSid={showSid}
    />
  ));
}

function TreeNode({
  tree,
  onChange,
  collapsedAll,
  expandedAll,
  showSid,
  isScanningStatus
}: Props): JSX.Element {
  const keysRef = React.useRef(new UniqueKeysManager<Ip>());
  const keys = keysRef.current;

  const emptyIp: Ip = {
    ip: '',
    instances: []
  };

  return (
    <Box>
      <Stack>
        {!isScanningStatus ? (
          <>
            {renderIpNodes(
              tree,
              keys,
              onChange,
              expandedAll,
              collapsedAll,
              showSid
            )}

            <Box sx={{ mt: 2 }}>
              <AddButton
                onClick={() => {
                  onChange({
                    ...tree,
                    ips: [...tree.ips, { ...emptyIp }]
                  });
                }}
              >
                Add host
              </AddButton>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              mt: '25%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <Typography>
              The network scan is currently underway. The outcomes will be
              accessible once the scan is complete.
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
}

export default TreeNode;
