import * as React from 'react';
import { useEffect, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import CircleIcon from '@mui/icons-material/Circle';
import { v4 as uuidv4 } from 'uuid';
import {
  getSeverityColor,
  Severity
} from '../../../features/vulnerabilities/Severity';
import TopBugsService from './services/DashboardService';

interface TopBugsProps {
  typeOfBugs: string; // Adjust the type if needed
}

function createData(title: string, status: string, criticality: string) {
  return { title, status, criticality };
}

function parseDataFromApi(latestVulnerabilities: any[]) {
  let i = 0;
  const lengthOfTotalVulnerabilities = Math.min(
    latestVulnerabilities.length / 3,
    7
  );
  const funcArrayOfData = [];
  while (i <= lengthOfTotalVulnerabilities) {
    if (latestVulnerabilities[i * 3] !== undefined)
      funcArrayOfData.push(
        createData(
          latestVulnerabilities[i * 3 + 2],
          latestVulnerabilities[i * 3 + 1],
          latestVulnerabilities[i * 3]
        )
      );
    i++;
  }

  // Add empty rows if the length is less than 8
  while (funcArrayOfData.length < 8) {
    funcArrayOfData.push(createData(' ', ' ', ' '));
  }

  return funcArrayOfData;
}

const TopBugs: React.FC<TopBugsProps> = ({ typeOfBugs }) => {
  const [latestVulnerabilities, setLatestVulnerabilities] = useState<any[]>([]);

  useEffect(() => {
    TopBugsService(typeOfBugs)
      .then((res) => {
        setLatestVulnerabilities(res);
      })
      .catch((reason) => console.log(reason));
  }, [typeOfBugs]);

  const rows = parseDataFromApi(latestVulnerabilities);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              Top Discovered <b>{`${typeOfBugs}`}</b> Vulnerabilities
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={uuidv4()}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  pt: row.title.trim() === '' ? '26px' : undefined,
                  pb: row.title.trim() === '' ? '26px' : undefined
                }}
              >
                {row.criticality === Severity.CRITICAL && (
                  <CircleIcon
                    sx={{
                      color: getSeverityColor(Severity.CRITICAL),
                      fontSize: 'small',
                      mr: 2
                    }}
                  />
                )}
                {row.criticality === Severity.HIGH && (
                  <CircleIcon
                    sx={{
                      color: getSeverityColor(Severity.HIGH),
                      fontSize: 'small',
                      mr: 2
                    }}
                  />
                )}
                {row.criticality === Severity.MEDIUM && (
                  <CircleIcon
                    sx={{
                      color: getSeverityColor(Severity.MEDIUM),
                      fontSize: 'small',
                      mr: 2
                    }}
                  />
                )}
                {row.criticality === Severity.LOW && (
                  <CircleIcon
                    sx={{
                      color: getSeverityColor(Severity.LOW),
                      fontSize: 'small',
                      mr: 2
                    }}
                  />
                )}
                {row.title}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TopBugs;
