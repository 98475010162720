import * as React from 'react';
import { useEffect } from 'react';
import useAuth from './useAuth';

const RequireAuth = ({
  children
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const { authenticated, signIn } = useAuth();

  useEffect(() => {
    signIn('').then(() => {
      // keycloak will redirect to the appropriate page
    });
  }, []);

  return authenticated ? children : null;
};

export default RequireAuth;
