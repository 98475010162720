import * as React from 'react';
import { useState, useEffect } from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import toast from 'react-hot-toast';
import theme from '../../theme';
import HotToast from '../notifications/HotToast';

export interface BasicGridRowProps {
  id: number;
}

export type ColumnsGetterProps<R extends BasicGridRowProps> = {
  rows: R[];
  reloadReport: () => void;
};

export type ColumnsGetter<R extends BasicGridRowProps> = ({
  rows,
  reloadReport
}: ColumnsGetterProps<R>) => GridColDef[];

export interface RowDialogProps<T extends BasicGridRowProps> {
  selectedRow: T;
  opened: boolean;
  handleClose: () => void;
  reloadReport: () => void;
}

export type RowDialog<T extends BasicGridRowProps> = React.FC<
  RowDialogProps<T>
>;

export const ReportGrid = <P extends BasicGridRowProps>({
  rows,
  columnsGetter,
  reloadReport,
  Dialog,
  noRowsText
}: {
  rows: P[];
  columnsGetter: ColumnsGetter<P>;
  reloadReport: () => void;
  Dialog: RowDialog<P>;
  noRowsText: string;
}): JSX.Element => {
  const [vulnerability, setVulnerability] = useState(rows[0]);
  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = useState(10);

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  useEffect(() => {
    const calculatePageSize = () => {
      const gridHeight = window.innerHeight - 210; // Calculate available height
      const rowHeight = 52; // Default row height in MUI DataGrid
      const visibleRows = Math.floor(gridHeight / rowHeight);
      setPageSize(visibleRows);
    };

    calculatePageSize();

    window.addEventListener('resize', calculatePageSize);

    return () => window.removeEventListener('resize', calculatePageSize);
  }, []);

  useEffect(() => {
    if (rows.length > 0 && vulnerability) {
      setVulnerability(rows.find((x) => x.id === vulnerability.id) || rows[0]);
    }
  }, [rows]);

  return (
    <>
      <DataGrid
        components={{
          NoRowsOverlay: () => (
            <Stack
              height="calc(100vh - 210px)"
              alignItems="center"
              justifyContent="center"
              style={{
                position: 'relative',
                zIndex: 1
              }}
            >
              <BugReportIcon
                sx={{ fontSize: '80px' }}
                style={{ color: 'red' }}
              />
              <pre>{noRowsText}</pre>
            </Stack>
          )
        }}
        rows={rows}
        columns={columnsGetter({ rows, reloadReport })}
        pageSize={pageSize} // Set dynamic page size
        rowsPerPageOptions={[pageSize]}
        sx={{
          flexGrow: 1,
          '& .MuiDataGrid-virtualScrollerContent': {
            backgroundColor: theme.palette.background.paper
          },
          '&.MuiDataGrid-root': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
            {
              display: 'none'
            },
          boxShadow: 0.5,
          height: 'calc(100vh - 210px)',
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer'
          }
        }}
        disableSelectionOnClick
        onCellClick={(params) => {
          setVulnerability(
            rows.find((value) => value.id === params.id) || rows[0]
          );
          setOpen(true);
        }}
      />
      <Dialog
        selectedRow={vulnerability}
        opened={open}
        handleClose={() => setOpen(false)}
        reloadReport={reloadReport}
      />
      <HotToast />
    </>
  );
};
