import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Card,
  CardContent,
  CardHeader,
  CardProps,
  colors,
  Divider,
  useTheme
} from '@mui/material';
import alasql from 'alasql';
import {
  ArcElement,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  Tooltip
} from 'chart.js';
import {
  getStatusColor,
  ORDERED_PROFILE_PARAMETERS_STATUSES,
  Status
} from './Status';
import Check from './Check';

const StatusesWidget = ({
  checks,
  ...props
}: {
  checks: Check[];
} & Partial<CardProps>): JSX.Element => {
  const theme = useTheme();

  let data: ChartData<'doughnut', number[], Status> | null = null;

  if (checks && checks.length > 0) {
    const counts: { status: Status; cnt: number }[] = alasql(
      `
        SELECT actual.status,
               count(actual.status) as cnt
        FROM ? as ordered
               LEFT JOIN ? as actual ON actual.status = ordered.status
        GROUP BY status
      `,
      [
        ORDERED_PROFILE_PARAMETERS_STATUSES.map((profileParameterStatus) => {
          return { status: profileParameterStatus };
        }),
        checks
      ]
    );

    data = {
      datasets: [
        {
          data: counts.map((cnt) => cnt.cnt),
          backgroundColor: ORDERED_PROFILE_PARAMETERS_STATUSES.map((status) =>
            getStatusColor(status)
          ),
          borderWidth: 8,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white
        }
      ],
      labels: ORDERED_PROFILE_PARAMETERS_STATUSES
    };
  }

  const options: ChartOptions<'doughnut'> = {
    layout: { padding: 0 },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: theme.palette.background.paper,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        enabled: true,
        footerColor: theme.palette.text.secondary,
        intersect: false,
        mode: 'index',
        titleColor: theme.palette.text.primary
      }
    },
    responsive: true
  };

  ChartJS.register(ArcElement, Tooltip, Legend);

  return (
    <Card {...props}>
      <CardHeader title="Status" style={{ textAlign: 'center' }} />
      <Divider />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80%'
        }}
      >
        {data ? (
          <Doughnut data={data} options={options} />
        ) : (
          <p style={{ textAlign: 'center' }}>No data available.</p>
        )}
      </CardContent>
    </Card>
  );
};
export default StatusesWidget;
