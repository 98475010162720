import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getServicesMetadata } from '../../../../../../components/services/ServicesMetadataLoader';
import { ServiceMetadata } from '../../../../../../api/service/serviceApi';

interface Props {
  onChange: (serviceMetadata: ServiceMetadata) => void;
  selectedId: string;
}

const ServiceSelector: React.FC<Props> = ({ onChange, selectedId }: Props) => {
  const options = getServicesMetadata();

  return (
    <Autocomplete
      size="small"
      disableClearable
      onChange={(event, serviceMetadata) => {
        if (serviceMetadata?.id !== undefined) {
          onChange(serviceMetadata);
        }
      }}
      value={options.find(
        (serviceMetadata: ServiceMetadata) => serviceMetadata.id === selectedId
      )}
      isOptionEqualToValue={(option, value) =>
        value !== undefined && option.name === value.name
      }
      getOptionLabel={(option: ServiceMetadata) => option.name}
      options={options.sort((a: ServiceMetadata, b: ServiceMetadata) =>
        a.name.localeCompare(b.name)
      )}
      renderInput={(params) => (
        <TextField
          variant="standard"
          placeholder="Service Name"
          {...params}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: '0.9rem' },
            endAdornment: <>{params.InputProps.endAdornment}</>
          }}
        />
      )}
    />
  );
};

export default ServiceSelector;
