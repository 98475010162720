import { InstanceMetadata } from './InstanceMetadata';

export const INSTANCES_METADATA: InstanceMetadata[] = [
  ...Array(101).keys()
].map((x) => {
  return {
    id: x - 1,
    name: x === 0 ? 'Common Services' : `${x - 1}`
  } as InstanceMetadata;
});

export default INSTANCES_METADATA;
