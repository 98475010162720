import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

interface Props {
  value: string;
  onChange: (value: number) => void;
}

function PortSelector({ value, onChange }: Props): JSX.Element {
  const [isEmpty, setIsEmpty] = React.useState(value === '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(+newValue);
    setIsEmpty(newValue === '');
  };

  React.useEffect(() => {
    setIsEmpty(value === '');
  }, [value]);

  return (
    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ marginRight: '8px', fontSize: '0.9rem' }}>
        Port:
      </Typography>
      <TextField
        size="small"
        variant="standard"
        placeholder="443"
        value={value}
        onChange={handleChange}
        error={isEmpty}
        helperText={isEmpty ? 'Port cannot be empty' : ''}
        sx={{
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
            {
              borderColor: red[500]
            },
          '& input': {
            textAlign: 'center', // Center aligns the text inside TextField
            fontSize: '0.9rem'
          }
        }}
      />
    </Grid>
  );
}

export default PortSelector;
