import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import { BtpConnectionProperties } from '../../../api/sapBtp/sapBtpApi';
import {
  deleteBtpConnectionById,
  getBtpConnectionsList,
  getBtpSupportedRegionList,
  saveBtpConnection,
  startBtpMonitoring,
  testConnectToBtp
} from '../../../api/sapBtp/sapBtpService';
import HotToast from '../../notifications/HotToast';

const SapBtpLogin: React.FC = () => {
  const [regionList, setRegionList] = useState<string[]>([]);
  const [possibleConnections, setPossibleConnections] = useState<
    BtpConnectionProperties[]
  >([]);
  const [possibleFormattedConnections, setPossibleFormattedConnections] =
    useState<string[]>([]);
  const [connectionTestResult, setConnectionTestResult] = useState<
    boolean | null
  >(null);
  const [newConnection, setNewConnection] = useState<BtpConnectionProperties>({
    id: 0,
    customIdp: '',
    region: '',
    email: '',
    password: '',
    createdAt: '',
    isMonitoring: false
  });
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const findConnectionById = (targetId: number) => {
    return possibleConnections.find((connection) => connection.id === targetId);
  };

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const getSupportedRegions = async () => {
    try {
      const regionArray = await getBtpSupportedRegionList();
      setRegionList(regionArray);
    } catch (error) {
      console.error('Error fetching supported regions:', error);
    }
  };

  const [hasExecuted, setHasExecuted] = useState(false);

  const getPossibleConnections = async () => {
    try {
      const connections = await getBtpConnectionsList();
      setPossibleConnections(connections);
      const formattedConnections = connections.map(
        (connection: BtpConnectionProperties) => {
          return `${connection.id}:${connection.region}@${connection.email}/**********`;
        }
      );
      setPossibleFormattedConnections(formattedConnections);
    } catch (error) {
      console.error('Error fetching possible connections:', error);
    }
  };

  const handleDeleteConnection = async (selectedConnection: number) => {
    try {
      const parsedId = Number(selectedConnection);

      if (!Number.isNaN(parsedId)) {
        await deleteBtpConnectionById(parsedId);

        notifySuccess('Connection deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting connection:', error);
    }
  };

  useEffect(() => {
    if (connectionTestResult !== null) {
      if (connectionTestResult) toast.success('Connection test successful');
      else toast.error('Connection test failed');
      setSaveButtonDisabled(!connectionTestResult);
    }
    setConnectionTestResult(null);
  }, [connectionTestResult]);

  useEffect(() => {
    try {
      getSupportedRegions();
      getPossibleConnections();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  const handleTestConnection = async () => {
    try {
      if (newConnection.email !== '' && newConnection.password !== '') {
        const success = await testConnectToBtp(newConnection);
        setConnectionTestResult(success);
      }
    } catch (error) {
      console.error('Error testing connection to SAP BTP:', error);
    }
  };

  const handleSaveConnection = async () => {
    try {
      const success = await saveBtpConnection(newConnection);
      if (success) {
        notifySuccess('Connection saved successfully');
      } else {
        notifyError('Connection already exists');
      }
    } catch (error) {
      console.error('Error saving BtpConnectionProperties:', error);
    }
  };

  const handleConnectionSelect = (selectedConnection: number) => {
    const parsedId = Number(selectedConnection);

    if (!Number.isNaN(parsedId)) {
      const foundConnection = findConnectionById(parsedId);
      if (foundConnection !== undefined) {
        setNewConnection(foundConnection);
      } else {
        notifyError(`Connection with ID ${parsedId} not found.`);
      }
    } else {
      notifyError(`Invalid ID`);
    }
  };

  return (
    <Grid container spacing={3} sx={{ mt: '20vh' }}>
      <Grid item xs={false} md={3} />
      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Add BTP Connection
          </Typography>
        </Grid>

        <Paper elevation={3} style={{ padding: '20px' }}>
          {possibleConnections.length > 0 && (
            <div>
              <Typography variant="h6" align="center">
                Choose an Existing Connection
              </Typography>
              <Select
                label="Possible Connections"
                variant="outlined"
                fullWidth
                value={newConnection.id.toString()}
                onChange={(e) => handleConnectionSelect(Number(e.target.value))}
              >
                {possibleConnections.map((connection) => (
                  <MenuItem key={connection.id} value={connection.id}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{`${connection.id}:${connection.region}@${connection.email}/**********`}</span>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteConnection(Number(connection.id));
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}

          <form>
            <TextField
              label="Custom IDP"
              variant="outlined"
              fullWidth
              margin="normal"
              value={newConnection.customIdp}
              onChange={(e) =>
                setNewConnection({
                  ...newConnection,
                  customIdp: e.target.value
                })
              }
              disabled
            />
            <FormControl fullWidth>
              <InputLabel id="select-region-label">Select region</InputLabel>
              <Select
                labelId="select-region-label"
                id="select-region-select"
                label="Select region"
                fullWidth
                value={newConnection.region}
                onChange={(e) => {
                  const selectedRegion = e.target.value;
                  setNewConnection({
                    ...newConnection,
                    region: selectedRegion
                  });
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '350px' // Set the max height here to control visible options
                    }
                  }
                }}
              >
                {regionList.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={newConnection.email}
              onChange={(e) =>
                setNewConnection({ ...newConnection, email: e.target.value })
              }
            />
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              value={newConnection.password}
              onChange={(e) =>
                setNewConnection({
                  ...newConnection,
                  password: e.target.value
                })
              }
            />
            <Grid container spacing={3} sx={{ pt: 3 }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ marginRight: 3 }}
                  disabled={!saveButtonDisabled}
                  onClick={handleTestConnection}
                >
                  Test Connection
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  disabled={saveButtonDisabled}
                  sx={{ marginRight: 3 }}
                  onClick={handleSaveConnection}
                >
                  Save Connection
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
      <Grid item xs={false} md={3} />
      <HotToast />
    </Grid>
  );
};
export default SapBtpLogin;
