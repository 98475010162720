import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { indigo } from '@mui/material/colors';
import StorageIcon from '@mui/icons-material/Storage';
import React, { useEffect, useState } from 'react';

const SapSystems = (props) => {
  const [report, setReport] = useState();
  useEffect(() => {
    fetch(`/api/v1/vulnerability-assessment/stats/ipcountwithbugs`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response;
      })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setReport(res);
      })
      .catch((reason) => console.log(reason));
  }, []);
  return (
    <Card {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              Total Vulnerable Systems
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {report}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: indigo[600],
                height: 56,
                width: 56
              }}
            >
              <StorageIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default SapSystems;
