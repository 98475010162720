// HotToast.tsx

import React from 'react';
import { Toaster } from 'react-hot-toast';

const HotToast = () => {
  return (
    <Toaster
      position="bottom-left"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        className: '',
        duration: 5000,
        style: {
          background: '#363636',
          color: '#fff'
        },
        success: {
          duration: 5000
        }
      }}
    />
  );
};

export default HotToast;
