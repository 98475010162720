import { amber, green, grey } from '@mui/material/colors';

export enum Status {
  NEW = 'NEW',
  FIXED = 'FIXED',
  ACCEPTED = 'ACCEPTED'
}

export const ORDERED_VULNERABILITY_STATUSES: Status[] = [
  Status.NEW,
  Status.FIXED,
  Status.ACCEPTED
];

export const getStatusColor = (status: Status) => {
  switch (status) {
    case Status.NEW:
      return grey[400];
    case Status.FIXED:
      return green[300];
    case Status.ACCEPTED:
      return amber[300];
    default:
      return grey[500];
  }
};
