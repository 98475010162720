import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from '@mui/material/colors';

const DeleteButton = ({
  onClick,
  tooltipTitle
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tooltipTitle?: string;
}): JSX.Element => {
  return (
    <Tooltip
      title={tooltipTitle === undefined ? 'Delete' : tooltipTitle}
      placement="left"
    >
      <IconButton
        sx={{
          color: red[700]
        }}
        className="EditableItem-Button_remove"
        onClick={onClick}
      >
        <DeleteIcon
          sx={{
            fontSize: '16px'
          }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default DeleteButton;
