import React, { useEffect, useState } from 'react';
import { green, grey, orange } from '@mui/material/colors';
import { Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import HotToast from '../../notifications/HotToast';
import { ServiceNowInterface } from './ServiceNowInterface';
import {
  createTestIncident,
  getServiceNowConfiguration,
  saveServiceNowConfiguration
} from './ServiceNowService';

const ServiceNow = () => {
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const [serviceNowConfiguration, setServiceNowConfiguration] =
    useState<ServiceNowInterface>({
      serviceNowUsername: '',
      serviceNowPassword: '',
      serviceNowBaseUrl: '',
      serviceNowCallerId: ''
    });

  useEffect(() => {
    // Call the function to get the ServiceNow configuration and initialize the state
    getServiceNowConfiguration()
      .then((config) => {
        setServiceNowConfiguration(config);
      })
      .catch((error) => {
        console.error('Error fetching ServiceNow configuration:', error);
      });
  }, []); // Empty dependency array to run the effect only once on mount

  function handleCreateTestIncident() {
    if (
      serviceNowConfiguration?.serviceNowUsername !== undefined &&
      serviceNowConfiguration?.serviceNowPassword !== undefined &&
      serviceNowConfiguration?.serviceNowBaseUrl !== undefined &&
      serviceNowConfiguration?.serviceNowCallerId !== undefined
    ) {
      createTestIncident()
        .then((responseText) => {
          // Handle the successful response here, if needed
          notifySuccess('Test incident has been created.');
        })
        .catch((error) => {
          // Handle errors here
          notifyError(
            'Something went wrong, please check the configuration of ServiceNow'
          );
        });
    }
  }

  function handleSave() {
    if (
      serviceNowConfiguration?.serviceNowUsername !== undefined &&
      serviceNowConfiguration?.serviceNowPassword !== undefined &&
      serviceNowConfiguration?.serviceNowBaseUrl !== undefined &&
      serviceNowConfiguration?.serviceNowCallerId !== undefined
    ) {
      saveServiceNowConfiguration(serviceNowConfiguration);

      notifySuccess('ServiceNow configuration successfully saved');
    }
  }

  return (
    <Grid container>
      {/* Left side of the UI (Form) */}
      <Grid item xs={12} md={6}>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Please enter the ServiceNow configuration parameters below:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={serviceNowConfiguration?.serviceNowBaseUrl}
              onChange={(event) =>
                setServiceNowConfiguration({
                  ...serviceNowConfiguration,
                  serviceNowBaseUrl: event.target.value
                })
              }
              variant="outlined"
              label={
                serviceNowConfiguration?.serviceNowBaseUrl
                  ? ''
                  : 'ServiceNow URL'
              }
              placeholder="https://customdomain.service-now.com/"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={serviceNowConfiguration?.serviceNowCallerId}
              onChange={(event) =>
                setServiceNowConfiguration({
                  ...serviceNowConfiguration,
                  serviceNowCallerId: event.target.value
                })
              }
              variant="outlined"
              label={
                serviceNowConfiguration?.serviceNowCallerId
                  ? ''
                  : 'ServiceNow Caller Id'
              }
              placeholder="ServiceNow Caller Id"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              value={serviceNowConfiguration?.serviceNowUsername}
              onChange={(event) =>
                setServiceNowConfiguration({
                  ...serviceNowConfiguration,
                  serviceNowUsername: event.target.value
                })
              }
              variant="outlined"
              label={
                serviceNowConfiguration?.serviceNowUsername
                  ? ''
                  : 'ServiceNow Username'
              }
              placeholder="Username"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="password"
              value={serviceNowConfiguration?.serviceNowPassword}
              onChange={(event) =>
                setServiceNowConfiguration({
                  ...serviceNowConfiguration,
                  serviceNowPassword: event.target.value
                })
              }
              variant="outlined"
              label={
                serviceNowConfiguration?.serviceNowPassword
                  ? ''
                  : 'ServiceNow User Password'
              }
              placeholder="Password"
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              justifyContent="flex-start"
              sx={{ pt: 3 }}
            >
              <Grid item>
                <Button
                  sx={{
                    ml: 0,
                    backgroundColor: green[500],
                    '&:hover': {
                      backgroundColor: green[700]
                    }
                  }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={() => handleSave()}
                >
                  Save
                </Button>
              </Grid>

              <Grid item>
                <Button
                  sx={{
                    backgroundColor: grey[500],
                    '&:hover': {
                      backgroundColor: grey[700]
                    }
                  }}
                  type="submit"
                  variant="contained"
                  size="medium"
                  onClick={() => handleCreateTestIncident()}
                >
                  Test Issue
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Right side of the UI (Empty space or other content can be added here) */}
      <Grid item xs={12} md={6}>
        {/* Add any additional content or components you want to display on the right side */}
      </Grid>

      <HotToast />
    </Grid>
  );
};
export default ServiceNow;
