import { ServiceNowInterface } from './ServiceNowInterface';
import { checkOk } from '../../../api/common';

export function saveServiceNowConfiguration(
  configuration: ServiceNowInterface
) {
  return fetch(`/api/v1/settings/servicenow/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(configuration)
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function createTestIncident() {
  return fetch(`/api/v1/settings/servicenow/test/incident/create`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function getServiceNowConfiguration(): Promise<ServiceNowInterface> {
  return fetch(`/api/v1/settings/servicenow/get`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}
