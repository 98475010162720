import { Report, ReportMetadata, ScanProgressDTO } from './vulnerabilityApi';
import { checkOk } from '../common';

export function getReportsMetadata(): Promise<ReportMetadata[]> {
  return fetch('/api/v1/vulnerability-assessment/reports/metadata', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function getReportsMetadataByNetworkId(
  id: number
): Promise<ReportMetadata[]> {
  return fetch(`/api/v1/vulnerability-assessment/reports/${id}/metadata`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function renameReport(
  reportId: number,
  aNewName: string
): Promise<ReportMetadata> {
  return fetch(`/api/v1/vulnerability-assessment/reports/${reportId}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ newName: aNewName })
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function scan(
  newName: string,
  selectedNetworkId: number,
  isFullScan: boolean
): Promise<number> {
  return fetch(
    `/api/v1/networks/${selectedNetworkId}/vulnerability-assessment/reports?isFullScan=${isFullScan}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: newName })
    }
  )
    .then(checkOk)
    .then((response) => response.json());
}

export function loadReport(reportId: number): Promise<Report> {
  return fetch(`/api/v1/vulnerability-assessment/reports/${reportId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function downloadReport(reportId: number, sort: number): Promise<void> {
  return fetch(
    `/api/v1/vulnerability-assessment/reports/${reportId}/download/${sort}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  )
    .then(checkOk)
    .then((response) => {
      const filename = response.headers
        ?.get('Content-Disposition')
        ?.split('filename=')[1]
        .replaceAll('"', '');
      if (filename === undefined) {
        return;
      }
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
}

export function downloadCSVeport(reportId: number): Promise<void> {
  return fetch(
    `/api/v1/vulnerability-assessment/reports/${reportId}/downloadCSV`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
  )
    .then(checkOk)
    .then((response) => {
      const filename = response.headers
        ?.get('Content-Disposition')
        ?.split('filename=')[1]
        .replaceAll('"', '');
      if (filename === undefined) {
        return;
      }
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
}
export function exportToJira(reportId: number | undefined) {
  return fetch(
    `/api/v1/vulnerability-assessment/reports/${reportId}/exportITSM`,
    { method: 'GET' }
  ).then(async (response) => {
    // Enhanced for error handling
    if (!response.ok) {
      const errorText = await response.text(); // Read error message
      throw new Error(`HTTP Error ${response.status}: ${errorText}`);
    }
    const successMessage = await response.text(); // Extract success message
    console.log(successMessage);
  });
}

export function exportToServiceNow(reportId: number | undefined) {
  return fetch(
    `/api/v1/vulnerability-assessment/reports/${reportId}/servicenow/export`,
    {
      method: 'GET'
    }
  ).then(async (response) => {
    // Enhanced for error handling
    if (!response.ok) {
      const errorText = await response.text(); // Read error message
      throw new Error(`HTTP Error ${response.status}: ${errorText}`);
    }
    const successMessage = await response.text(); // Extract success message
    console.log(successMessage);
  });
}
export function initSchedule(networkId: number | undefined, period?: number) {
  return fetch(`/api/v1/schedule/create/${networkId}/${period}`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => {
      console.log(response.text());
    });
}
export function removeSchedule(networkId: number | undefined) {
  return fetch(`/api/v1/schedule/remove/${networkId}`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => {
      console.log(response.text());
    });
}
export function checkSchedule(networkId: number | undefined) {
  return fetch(`/api/v1/schedule/check/${networkId}`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}
export function getNetworkScanProgress(networkId: number | undefined) {
  if (networkId === undefined || networkId === null) {
    return Promise.reject(new Error('Invalid network ID'));
  }

  return fetch(`/api/v1/networks/${networkId}/scan-progress`, {
    method: 'GET'
  }).then((response): Promise<ScanProgressDTO> | null => {
    if (response.status === 204) {
      // No Content - сканирование еще не начато или нет данных
      console.log('Scan has not started yet or no data available.');
      return null;
    }
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  });
}
