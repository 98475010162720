import {
  Box,
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Typography,
  useTheme
} from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useEffect, useState } from 'react';

const VulnerabilityByStatus = (props) => {
  const roundTo = function (num, places) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  const [status, setStatus] = useState('[0,0,0]');
  useEffect(() => {
    fetch(`/api/v1/vulnerability-assessment/stats/getstatuses`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response;
      })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setStatus(res);
      })
      .catch((reason) => console.log(reason));
  }, []);

  let totalSum = status[0] + status[1] + status[2];
  totalSum = totalSum === 0 ? 1 : totalSum;

  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: status,
        backgroundColor: [
          colors.grey[400],
          colors.orange[400],
          colors.green[400]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['New', 'Accepted', 'Fixed']
  };

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const Criticality = [
    {
      title: 'New',
      value: roundTo((status[0] / totalSum) * 100, 2),
      color: colors.grey[400]
    },
    {
      title: 'Accepted',
      value: roundTo((status[1] / totalSum) * 100, 2),
      color: colors.orange[400]
    },
    {
      title: 'Fixed',
      value: roundTo((status[2] / totalSum) * 100, 2),
      color: colors.green[600]
    }
  ];

  ChartJS.register(ArcElement, Tooltip, Legend);

  return (
    <Card {...props} sx={{ height: '100%' }}>
      <CardHeader title="Vulnerabilities by Status" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {Criticality.map(({ color, title, value }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >
              <Typography color="textPrimary" variant="body1">
                {title}
              </Typography>
              <Typography style={{ color }} variant="h3">
                {value}%
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};
export default VulnerabilityByStatus;
