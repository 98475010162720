import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import MoreIcon from '@mui/icons-material/More';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Tooltip, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import {
  BtpMonitoringSettingsResponse,
  IgnoreEventsInterface
} from '../../../api/sapBtp/sapBtpApi';
import {
  getBtpMonitoringSettings,
  getClassIdText,
  getGlobalAccountName,
  getIgnorablePolicyList,
  getSubaccountName,
  humanizeChangeType,
  removeBTPIgnorableCollection
} from '../../../api/sapBtp/sapBtpService';
import formatDateTime from '../../formatDateTime';

const IgnorableEventsUi: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<IgnoreEventsInterface[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const PAGE_SIZE_OPTIONS = [10, 20, 25];
  const notifySuccess = (message: string) => toast.success(message);
  const [btpMonitoringSettings, setBtpMonitoringSettings] =
    useState<BtpMonitoringSettingsResponse>();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getBtpMonitoringSettings();
        setBtpMonitoringSettings(settings);
      } catch (error) {
        console.error('Error fetching BTP monitoring settings:', error);
      }
    };

    fetchSettings();
  }, []);
  const fetchData = async () => {
    try {
      const response = await getIgnorablePolicyList(page, pageSize);

      const updatedRows = response.content.map((alert) => ({
        ...alert,
        changePath: alert.changePath.replace('REDRAYS_', '')
      }));

      setRows(updatedRows);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Error fetching event logs:', error);
    }
  };

  useEffect(() => {
    setRows([]);
    fetchData();
  }, [page]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleButtonClick = async (row: IgnoreEventsInterface) => {
    try {
      await removeBTPIgnorableCollection(row.id);
      setRows(rows.filter((item) => item.id !== row.id));
    } catch (error) {
      console.error('Error removing alert:', error);
    }
  };

  const [hoveredGlobalAccountId, setHoveredGlobalAccountId] = useState<
    number | null
  >(null);
  const [hoveredSubaccountId, setHoveredSubaccountId] = useState<number | null>(
    null
  );
  const [globalAccountName, setGlobalAccountName] = useState<string | null>(
    null
  );
  const [subaccountName, setSubaccountName] = useState<string | null>(null);

  const handleMouseOverGlobalAccount = async (guid: string, rowId: number) => {
    setHoveredGlobalAccountId(rowId);
    try {
      const response = await getGlobalAccountName(guid);
      setGlobalAccountName(response);
    } catch (error) {
      console.error('Error fetching global account name:', error);
    }
  };

  const handleMouseOutGlobalAccount = () => {
    setHoveredGlobalAccountId(null);
    setGlobalAccountName(null);
  };

  const handleMouseOverSubaccount = async (guid: string, rowId: number) => {
    setHoveredSubaccountId(rowId);
    try {
      const response = await getSubaccountName(guid);
      setSubaccountName(response);
    } catch (error) {
      console.error('Error fetching subaccount name:', error);
    }
  };

  const handleMouseOutSubaccount = () => {
    setHoveredSubaccountId(null);
    setSubaccountName(null);
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        pt: 1,
        backgroundColor: 'rgba(159,159,159,0.05)'
      }}
    >
      <Typography
        sx={{
          width: '90%',
          backgroundColor: '#ffffff',
          fontSize: 'small',
          mb: 1,
          ml: '5%',
          color: '#CACACA',
          textAlign: 'center',
          borderRadius: '4px'
        }}
      >
        Policies ignored from tracking
      </Typography>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          ml: 2,
          mr: 2
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>BTP Object</TableCell>
                <TableCell>Change Type</TableCell>
                {btpMonitoringSettings?.showPath === 'true' && (
                  <TableCell>Change Path</TableCell>
                )}
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No ignorable events found
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <div>
                      <strong>{`Context: ${getClassIdText(
                        row.classId
                      )}`}</strong>
                    </div>
                    {row.subaccountGuid && (
                      <div>
                        {`Subaccount: ${row.subaccountGuid}`}
                        <Tooltip
                          title={
                            hoveredSubaccountId === row.id
                              ? subaccountName || ''
                              : ''
                          }
                          open={
                            hoveredSubaccountId === row.id &&
                            Boolean(subaccountName)
                          }
                          disableHoverListener
                        >
                          <IconButton
                            onMouseOver={() =>
                              handleMouseOverSubaccount(
                                row.subaccountGuid,
                                row.id
                              )
                            }
                            onMouseOut={handleMouseOutSubaccount}
                          >
                            <MoreIcon style={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {row.globalAccountGuid &&
                      row.subaccountGuid.length === 0 && (
                        <div>
                          {`Global Account: ${row.globalAccountGuid}`}
                          <Tooltip
                            title={
                              hoveredGlobalAccountId === row.id
                                ? globalAccountName || ''
                                : ''
                            }
                            open={
                              hoveredGlobalAccountId === row.id &&
                              Boolean(globalAccountName)
                            }
                            disableHoverListener
                          >
                            <IconButton
                              onMouseOver={() =>
                                handleMouseOverGlobalAccount(
                                  row.globalAccountGuid,
                                  row.id
                                )
                              }
                              onMouseOut={handleMouseOutGlobalAccount}
                            >
                              <MoreIcon style={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    {row.userEmail && (
                      <div>{`User Email: ${row.userEmail}`}</div>
                    )}
                  </TableCell>
                  <TableCell>{humanizeChangeType(row.changeType)}</TableCell>
                  {btpMonitoringSettings?.showPath === 'true' && (
                    <TableCell>{row.changePath}</TableCell>
                  )}
                  <TableCell>
                    {formatDateTime(new Date(row.createdAt))}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleButtonClick(row)}
                      aria-label="Remove tracking"
                      placeholder="Remove tracking"
                      color="error"
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalPages * pageSize}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default IgnorableEventsUi;
