import React, { useEffect } from 'react';
import { grey } from '@mui/material/colors';
import toast from 'react-hot-toast';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import HotToast from '../../components/notifications/HotToast';
import RenameDialog from '../../components/networkselector/RenameDialog';
import ScanDialog from './ScanDialog';
import {
  downloadCSVReport,
  downloadReport,
  exportToJira,
  loadReport,
  renameReport,
  scan
} from './Service';
import ReportSelector, { ReportMetadata } from './ReportSelector';
import Checks from './Checks';
import Check from './Check';
import StatusesWidget from './StatusesWidget';

const ComponentVersions = (): JSX.Element => {
  const [selectedReportId, setSelectedReportId] = React.useState<
    number | undefined
  >(undefined);
  const [selectedReportName, setSelectedReportName] = React.useState('');
  const [openedReportRenameDialog, setOpenedReportRenameDialog] =
    React.useState(false);
  const [openedReportScanDialog, setOpenedReportScanDialog] =
    React.useState(false);
  const [checks, setChecks] = React.useState<Check[]>([]);
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const handleReportSelectorChange = (
    event: any,
    reportMetadata: ReportMetadata | null
  ) => {
    if (reportMetadata !== null) {
      setSelectedReportId(reportMetadata.id);
      setSelectedReportName(reportMetadata.name);
    }
  };

  useEffect(() => {
    if (selectedReportId === undefined) {
      return;
    }
    loadReport(selectedReportId, setChecks);
  }, [selectedReportId]);

  const handleOpenReportRenameDialog = () => {
    setOpenedReportRenameDialog(true);
  };

  const handleDownloadReport = () => {
    if (selectedReportId === undefined) {
      return;
    }
    downloadReport(selectedReportId).catch((reason) =>
      notifyError(
        `Failed to download the report with id=${selectedReportId}. Reason: ${reason.status}`
      )
    );
  };
  const handleDownloadCSVReport = () => {
    if (selectedReportId === undefined) {
      return;
    }
    downloadCSVReport(selectedReportId).catch((reason) =>
      notifyError(
        `Failed to download the report with id=${selectedReportId}. Reason: ${reason.status}`
      )
    );
  };
  const handleExportToJira = () => {
    if (selectedReportId === undefined) {
      return;
    }
    notifySuccess(`Export to Jira has been started`);
    exportToJira(selectedReportId)
      .then(() =>
        notifySuccess(
          `Outdated Components have been successfully exported to Jira ITSM`
        )
      )
      .catch((reason) => {
        notifyError(`Failed to export vulnerabilities: ${reason.message}`); // Display error message
      });
  };
  return (
    <>
      <title>Outdated Components | RedRays</title>
      <Typography
        style={{ color: grey[600] }}
        display="inline"
        variant="h6"
        paddingLeft={2}
        paddingBottom={0}
      >
        RedRays Security Platform &gt;
      </Typography>
      <Typography display="inline" variant="h6" paddingLeft={1} paddingTop={1}>
        Outdated Components Scan Module
      </Typography>
      <Grid container spacing={2} sx={{ pl: 2, height: '85%' }}>
        <Grid item container xs={10}>
          <Grid
            item
            xs={12}
            sm
            sx={{
              backgroundColor: '#ffffff',
              pl: 1,
              pt: 1,
              mb: 1,
              pr: 1,
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
              sx={{ mb: 1, mt: 1, alignItems: 'center' }}
            >
              <Button
                sx={{ mt: 0 }}
                onClick={() => setOpenedReportScanDialog(true)}
                variant="contained"
                color="success"
                startIcon={<CenterFocusWeakIcon />}
                size="small"
              >
                Run single scan
              </Button>
              <Button
                sx={{ mt: 0, ml: 1 }}
                onClick={handleOpenReportRenameDialog}
                variant="contained"
                color="success"
                size="small"
                startIcon={<EditIcon />}
                disabled={selectedReportName === ''}
              >
                Rename
              </Button>
              <Button
                variant="contained"
                color="success"
                size="small"
                startIcon={<FileDownloadIcon />}
                disabled={selectedReportName === ''}
                onClick={handleDownloadReport}
              >
                Download HTML report
              </Button>
              <Button
                variant="contained"
                color="success"
                size="small"
                startIcon={<FileDownloadIcon />}
                disabled={selectedReportName === ''}
                onClick={handleDownloadCSVReport}
              >
                Download CSV report
              </Button>
              <Button
                variant="contained"
                color="success"
                size="small"
                startIcon={<ShareIcon />}
                disabled={selectedReportName === ''}
                onClick={handleExportToJira}
              >
                Export to Jira
              </Button>
            </Stack>
            <Box sx={{ mt: 2, mb: 2 }}>
              <ReportSelector
                onChange={handleReportSelectorChange}
                selectedName={selectedReportName}
              />
            </Box>
          </Grid>
          <Grid item xs={12} height="calc(100vh - 270px)">
            <Checks
              rows={checks}
              reloadReport={() => {
                // checks are immutable
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <StatusesWidget
            checks={checks}
            sx={{ height: 225, width: 170, mt: 16 }}
          />
        </Grid>
      </Grid>
      {selectedReportId && (
        <RenameDialog
          name={selectedReportName}
          opened={openedReportRenameDialog}
          handleApply={(newName) => {
            renameReport(selectedReportId, newName, setSelectedReportName);
            setOpenedReportRenameDialog(false);
          }}
          handleClose={() => setOpenedReportRenameDialog(false)}
          windowName="Select a new name for the report"
        />
      )}
      <ScanDialog
        opened={openedReportScanDialog}
        handleScan={(scanProperties) => {
          scan(scanProperties);
          setOpenedReportScanDialog(false);
        }}
        handleClose={() => setOpenedReportScanDialog(false)}
      />
      <HotToast />
    </>
  );
};

export default ComponentVersions;
