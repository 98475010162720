import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, TextField, Typography } from '@mui/material';

const ErrorsDialog = ({
  errors,
  opened,
  handleClose
}: {
  errors: string[];
  opened: boolean;
  handleClose: () => void;
}) => {
  return (
    <Dialog
      open={opened}
      onClose={handleClose}
      aria-labelledby="errors-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        id="errors-dialog-title"
        sx={{
          backgroundColor: 'white',
          color: 'black',
          fontSize: '1.2rem', // Increase font size of the title
          mb: 2 // Add margin bottom between title and input label
        }}
      >
        <Typography variant="h2">Errors</Typography>
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5">
          The scan was done with errors. Please check the network configuration
          or contact with RedRays Support Team
        </Typography>
        <Divider orientation="horizontal" sx={{ background: 'red' }} flexItem />
        {errors.map((error) => (
          <TextField
            key={error}
            multiline
            maxRows={10}
            id="errors-dialog-errors"
            sx={{ input: { color: 'black' }, pt: 3 }}
            value={error}
            fullWidth
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorsDialog;
