import React from 'react';
import { Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import HelpIcon from '@mui/icons-material/Help';
import ServiceNode from './service/ServiceNode';
import InstanceSelector from './InstanceSelector';
import { Instance, Service } from '../../../../../api/network/treeApi';
import UniqueKeysManager from '../../../../../components/UniqueKeysManager';
import AddButton from '../../../../../components/tree/AddButton';

interface Props {
  instance: Instance;
  onChange: (instance: Instance) => void;
  onDelete: () => void;
}

const InstanceNode: React.FC<Props> = ({
  instance,
  onChange,
  onDelete
}: Props) => {
  const keysRef = React.useRef(new UniqueKeysManager<Service>());
  const keys = keysRef.current;

  const emptyService: Service = {
    templateId: '',
    port: undefined,
    secure: false,
    anonymous: true,
    username: '',
    password: ''
  };
  const helpToUnderstandCommonServices = () => {
    if (instance.instanceId === -1)
      return (
        <Tooltip
          title={
            <Typography color="inherit">
              Common Services are services that have static ports like
              SAPHostControl - 1128 or SAP IGS - 40080
            </Typography>
          }
        >
          <HelpIcon sx={{ fontSize: 'medium' }} style={{ color: blue[500] }} />
        </Tooltip>
      );
    return '';
  };
  return (
    <Paper sx={{ background: grey[100], p: 2 }}>
      <Stack direction="row" alignItems="center" sx={{ pb: 2 }}>
        {/*
        <Box sx={{ mt: 1 }}>
          <DeleteButton onClick={onDelete} tooltipTitle="Delete instance" />
        </Box>
        */}
        <InstanceSelector
          onChange={(anInstanceId: number) =>
            onChange({
              ...instance,
              instanceId: anInstanceId
            })
          }
          selectedId={instance.instanceId}
        />
        {helpToUnderstandCommonServices()}
      </Stack>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {instance.services !== undefined &&
          instance.services.length > 0 &&
          instance.services.map((service: Service) => (
            <Grid key={keys.ensure(service)} item xs={12} md={4} lg={12} xl={4}>
              <ServiceNode
                service={service}
                onChange={(updatedService) => {
                  keys.replace(service, updatedService);
                  onChange({
                    ...instance,
                    services: instance.services.map((aService) =>
                      service === aService ? updatedService : aService
                    )
                  });
                }}
                onDelete={() => {
                  keys.delete(service);
                  onChange({
                    ...instance,
                    services: instance.services.filter(
                      (aService) => service !== aService
                    )
                  });
                }}
              />
            </Grid>
          ))}
        <Grid item xs={12} md={6} lg={12} xl={4}>
          <AddButton
            style={{ backgroundColor: grey[200] }}
            onClick={() => {
              onChange({
                ...instance,
                services: [...instance.services, { ...emptyService }]
              });
            }}
          >
            Add service
          </AddButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InstanceNode;
