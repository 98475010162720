import React, { useState } from 'react';
import { grey } from '@mui/material/colors';
import toast from 'react-hot-toast';
import { Box, Grid, Typography } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import { NetworkMetadata, Tree } from '../../api/network/treeApi';
import SapNotesNetworkSelector from '../networkselector/SapNotesNetworkSelector';
import SapNotesTable from './SapNotesTable';
import SapNotesPossibleAreNotInstalled from '../../api/sapnotes/serviceApi';
import { getSapNotes } from '../../api/sapnotes/serviceService';
import HotToast from '../notifications/HotToast';

const SapNotesMonitoring: React.FC = () => {
  const emptyTree: Tree = {
    ips: []
  };
  const [selectedNetworkMetadata, setSelectedNetworkMetadata] = useState<
    NetworkMetadata | undefined
  >(undefined);
  const [messages, setMessages] = useState<string[]>([]);
  const [
    forceReloadNetworkSelectorNeeded,
    setForceReloadNetworkSelectorNeeded
  ] = useState(false);

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const [sapNotes, setSapNotes] = React.useState<
    SapNotesPossibleAreNotInstalled[]
  >([]);

  React.useEffect(() => {
    if (selectedNetworkMetadata?.id !== undefined) {
      getSapNotes(selectedNetworkMetadata?.id)
        .then((data) => {
          if (data.length !== 0) {
            notifySuccess('SAP Notes report has been loaded');
          } else {
            notifyError('SAP Notes report is empty');
          }

          setSapNotes(data);
        })
        .catch((error) => {
          notifyError('SError fetching data.');
          console.error('Error fetching data:', error);
        });
    }
  }, [selectedNetworkMetadata?.id]);

  return (
    <>
      <title>SAP Security Note Check | RedRays</title>
      <Grid container sx={{ backgroundColor: '#F8F7F7' }}>
        <Grid
          item
          xs={12}
          lg={5}
          xl={4}
          sx={{ height: 'calc(100vh - 70px)', backgroundColor: '#F8F7F7' }}
        >
          <Box
            sx={{
              height: 'calc(100vh - 70px)',
              backgroundColor: '#F2F2F2',
              ml: 3,
              mr: 2,
              pt: 1
            }}
          >
            <Typography
              sx={{
                width: '90%',
                backgroundColor: '#ffffff',
                fontSize: 'small',
                mb: 1,
                ml: '5%',
                color: '#CACACA',
                textAlign: 'center',
                borderRadius: '4px'
              }}
            >
              Select a network to see the reports.
            </Typography>
            <Accordion
              sx={{
                backgroundColor: '#F2F2F2',
                boxShadow: 'none', // Remove the default box-shadow to get rid of borders
                '&:before': {
                  // This targets the pseudo-element that MUI uses for the default border
                  // Set it to a white line or make it transparent if you want to completely remove it
                  backgroundColor: 'transparent'
                },
                '&.Mui-expanded': {
                  margin: '0 !important' // Remove the margin added when the accordion is expanded
                },
                '& .MuiAccordionSummary-root': {
                  // Add white lines at the top and bottom of the summary part
                  borderTop: '1px solid white',
                  borderBottom: '1px solid white'
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="network-selector-content"
                id="network-selector-header"
              >
                <Typography>
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Load
                  </Box>{' '}
                  Network
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  height: 'calc(100vh - 196px)',
                  backgroundColor: '#ffffff'
                }}
              >
                <SapNotesNetworkSelector
                  onChange={setSelectedNetworkMetadata}
                  selectedId={selectedNetworkMetadata?.id}
                  forceReloadNeeded={forceReloadNetworkSelectorNeeded}
                  onAfterReloaded={(networkMetadata) => {
                    setSelectedNetworkMetadata(networkMetadata);
                    setForceReloadNetworkSelectorNeeded(false);
                  }}
                  allowSelectNetworksWithErrors
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>

        {/* Move the right side Grid item to xl={8} */}
        <Grid item xs={12} lg={5} xl={8}>
          <Box
            sx={{
              height: 'calc(100vh - 70px)',
              backgroundColor: '#F2F2F2',
              mr: 1,
              pt: 1,
              pl: 1,
              pr: 1
            }}
          >
            {selectedNetworkMetadata?.id === undefined && (
              <div
                style={{ textAlign: 'center', marginTop: 'calc(50vh - 154px)' }}
              >
                <WifiTetheringOffIcon
                  sx={{ fontSize: 200, color: grey[400] }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: 36,
                    mt: 2,
                    ml: '30%',
                    mr: '30%',
                    color: grey[400],
                    textAlign: 'center'
                  }}
                >
                  Select Network to get a SAP Notes report
                </Typography>
              </div>
            )}

            {selectedNetworkMetadata?.id !== undefined &&
              sapNotes.length === 0 && (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 'calc(50vh - 184px)'
                  }}
                >
                  <BugReportIcon sx={{ fontSize: 200, color: grey[400] }} />
                  <Typography>
                    The SAP Notes report is unavailable.
                    <div>
                      To perform the Missing SAP Security Notes check, follow
                      these steps:
                    </div>
                    <Typography sx={{ mt: 2 }}>
                      1. Go to the Port and Service scan module.
                    </Typography>
                    <Typography>
                      2. Set up credentials for the ABAP stack.
                    </Typography>
                    <Typography>
                      3. Initiate the authentication scan in the Vulnerability
                      Assessment module.
                    </Typography>
                  </Typography>
                </div>
              )}

            {selectedNetworkMetadata?.id !== undefined &&
              sapNotes.length !== 0 && <SapNotesTable rows={sapNotes} />}
          </Box>
        </Grid>
      </Grid>
      <HotToast />
    </>
  );
};

export default SapNotesMonitoring;
