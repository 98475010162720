import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {
  BtpConnectionProperties,
  BtpMonitoringSettingsResponse
} from '../../api/sapBtp/sapBtpApi';
import SimpleJsonDraw from './simpleElements/SimpleJsonDraw';
import AdvancedSettingsUi from './tabs/AdvancedSettingsUi';
import BtpLogs from './tabs/BtpLogs';
import ActivatedPoliciesUi from './tabs/ActivatedPoliciesUi';
import IgnorableEventsUi from './tabs/IgnorableEventsUi';
import RecommendedPoliciesUi from './tabs/RecommendedPoliciesUi';
import AlertsHistoryUi from './tabs/AlertsHistoryUi';
import BtpDashboard from './tabs/BtpDashboard';
import {
  btpMonitoringStartStop,
  btpSignOut,
  getBtpMonitoringMedata,
  getBtpMonitoringSettings
} from '../../api/sapBtp/sapBtpService';
import SettingsUi from './tabs/SettingsUi';
import SapBtpLogin from './login/SapBtpLogin';
import SapGlobalAccountList from './GlobalAccountList/SapGlobalAccountList';

const SapBtpMonitoring: React.FC = () => {
  const [metadata, setMetadata] = useState<BtpConnectionProperties[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tabId, setTabId] = useState(0);
  const [selectedId, setSelectedId] = useState<number>(-1); // State to hold the selected row ID
  const [monitoringOn, setMonitoringOn] = useState(true);
  const [btpMonitoringSettings, setBtpMonitoringSettings] =
    useState<BtpMonitoringSettingsResponse>();

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getBtpMonitoringSettings();
        setBtpMonitoringSettings(settings);
      } catch (error) {
        console.error('Error fetching BTP monitoring settings:', error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    // Function to fetch the data
    const fetchData = async () => {
      try {
        const response = await getBtpMonitoringMedata();
        setMetadata(response);
        setIsLoading(false);
      } catch (error) {
        setMetadata([]);

        console.error('Error fetching metadata:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 3000);
    return () => clearInterval(intervalId);
  }, []);

  let content = null;

  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (metadata) {
    content = <SapGlobalAccountList />;
  } else {
    content = <SapBtpLogin />;
  }

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 100px)' // Set the container height to 100% of the viewport height
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabId(newValue);
    setSelectedId(-1);
  };

  const toggleMonitoring = () => {
    setMonitoringOn((prev) => prev);

    if (metadata) btpMonitoringStartStop(metadata);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)'
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#5dc608' : '#37cd20'
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'rgba(255,255,255,.35)'
          : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box'
    }
  }));
  return (
    <>
      {metadata.length > 0 ? (
        <Grid
          container
          style={{
            height: '100vh',
            backgroundColor: 'rgba(243, 246, 249, 0.6)'
          }}
        >
          <Grid
            item
            xs={selectedId !== -1 ? 8 : 12}
            sx={{ pl: 2, pr: 2, pt: 1 }}
          >
            <Grid container>
              <Grid item xs={9}>
                <Tabs
                  value={tabId}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: '#ffffff',
                    '.MuiTabs-indicator': {
                      backgroundColor: 'red' // Change this to your desired color
                    }
                  }}
                  aria-label="simple tabs example"
                >
                  <Tab label="Dashboard" />
                  <Tab label="BTP Logs" />
                  <Tab label="Alerts History" />
                  <Tab label="Recommended Policies" />
                  <Tab label="Activated Policies" />
                  <Tab label="Ignorable Events" />
                  <Tab label="Advanced Settings" />
                  <Tab label="Settings" />
                </Tabs>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  backgroundColor: '#ffffff',
                  pr: 1
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center" // Centering horizontally
                >
                  <Divider orientation="vertical" flexItem />
                  <Typography>Monitoring Off</Typography>
                  <AntSwitch
                    onChange={toggleMonitoring}
                    defaultChecked={metadata.some((item) => item.isMonitoring)}
                    inputProps={{ 'aria-label': 'ant design' }}
                  />
                  <Typography>On</Typography>
                </Stack>
              </Grid>

              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  backgroundColor: '#ffffff',
                  pr: 1
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Divider orientation="vertical" flexItem />
                  <Button
                    onClick={() => {
                      setMetadata([]);
                      btpSignOut();
                    }}
                    style={{ color: '#ff0000' }}
                  >
                    Sign Out
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <Box>
              {tabId === 0 && <BtpDashboard />}
              {tabId === 1 && <BtpLogs />}
              {tabId === 2 && <AlertsHistoryUi />}
              {tabId === 3 && <RecommendedPoliciesUi />}
              {tabId === 4 && <ActivatedPoliciesUi />}
              {tabId === 5 && <IgnorableEventsUi />}
              {tabId === 6 && <AdvancedSettingsUi onSelectId={setSelectedId} />}
              {tabId === 7 && <SettingsUi />}
            </Box>
          </Grid>

          {tabId === 6 && selectedId !== -1 && (
            <Grid item xs={4} sx={{ pt: 11 }}>
              <SimpleJsonDraw id={selectedId} />{' '}
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid container style={containerStyle}>
          <Grid item>
            <SapBtpLogin />
            <Grid container style={containerStyle}>
              <Grid item>{content}</Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default SapBtpMonitoring;
