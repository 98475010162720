import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, TextField, Typography } from '@mui/material';

const ProvideNameDialog = ({
  opened,
  handleApply,
  handleClose,
  autoGenerateName,
  windowName
}: {
  opened: boolean;
  handleApply: (newName: string) => void;
  handleClose: () => void;
  autoGenerateName: string;
  windowName: string;
}): JSX.Element => {
  const [newName, setNewName] = React.useState(autoGenerateName);
  React.useEffect(() => {
    if (!opened) {
      setNewName('');
    }
  }, [opened]);

  return (
    <>
      {opened && (
        <Dialog
          open={opened}
          onClose={handleClose}
          aria-labelledby="provide-name-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="provide-name-dialog-title"
            sx={{
              backgroundColor: 'white',
              color: 'black',
              fontSize: '1.2rem', // Increase font size of the title
              mb: 2 // Add margin bottom between title and input label
            }}
          >
            <Typography variant="h2">{windowName}</Typography>
            <Divider sx={{ mt: 2 }} />
          </DialogTitle>

          <DialogContent>
            <TextField
              id="provide-name-dialog-new-name"
              label="New name"
              value={newName}
              onChange={(event) => setNewName(event.target.value)}
              fullWidth
              sx={{ mt: 1 }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="success"
              variant="contained"
              onClick={() => handleApply(newName)}
              autoFocus
            >
              Apply
            </Button>
            <Button color="primary" variant="contained" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ProvideNameDialog;
