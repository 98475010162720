import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TableHead from '@mui/material/TableHead';
import DialogTitle from '@mui/material/DialogTitle';
import toast from 'react-hot-toast';
import { getSapBtpDashboardMetadata } from '../../../api/sapBtp/sapBtpService';
import { BtpDashboardMetadataInterface } from '../../../api/sapBtp/sapBtpApi';
import EChartsChart from '../EChartsChart';
import RecommendedPoliciesUi from './RecommendedPoliciesUi';
import SimpleCard from '../simpleElements/SimpleCard';
import HotToast from '../../notifications/HotToast';

// Loading Indicator Component
const LoadingIndicator = () => (
  <Box
    sx={{
      display: 'flex',
      backgroundColor: '#ffffff',
      justifyContent: 'center',
      alignItems: 'center',
      mt: 2,
      height: '150px'
    }}
  >
    <CircularProgress />
  </Box>
);

const BtpDashboard: React.FC = () => {
  const [dashboardMetadata, setDashboardMetadata] = useState<
    BtpDashboardMetadataInterface | undefined
  >(undefined);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [prevEventData, setPrevEventData] = useState<{
    xData: string[];
    yData: number[];
  }>({ xData: [], yData: [] });
  const [prevAlertsHistoryData, setPrevAlertsHistoryData] = useState<{
    xData: string[];
    yData: number[];
  }>({ xData: [], yData: [] });

  const notifySuccess = (message: string) => toast.success(message);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSapBtpDashboardMetadata();
        setDashboardMetadata(data);
        setLoading(false);
        setIsInitialLoad(false);
      } catch (error) {
        console.error('Error fetching BTP dashboard metadata:', error);
        setLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleShowUsers = (guid: string) => {
    const users =
      dashboardMetadata?.usersMap
        ?.filter((user) => user.guid === guid)
        ?.map((user) => user.email) || [];

    setSelectedUsers(users);
    setDialogOpen(true);
    notifySuccess(`Users for ${guid} have been loaded`);
  };

  const eventData = {
    xData: Object.keys(dashboardMetadata?.eventCountPerDay || {}),
    yData: Object.values(dashboardMetadata?.eventCountPerDay || {})
  };

  const alertsHistoryData = {
    xData: Object.keys(dashboardMetadata?.alertsHistoryPerDay || {}),
    yData: Object.values(dashboardMetadata?.alertsHistoryPerDay || {})
  };

  useEffect(() => {
    if (alertsHistoryData.xData.length > 29 && eventData.xData.length > 29) {
      if (JSON.stringify(prevEventData) !== JSON.stringify(eventData)) {
        notifySuccess('New events detected in SAP BTP');
        setPrevEventData(eventData);
      }
      if (
        JSON.stringify(prevAlertsHistoryData) !==
        JSON.stringify(alertsHistoryData)
      ) {
        notifySuccess('New events detected in SAP BTP');
        setPrevAlertsHistoryData(alertsHistoryData);
      }
      setIsInitialLoad(false);
      setLoading(false);
    }
  }, [alertsHistoryData, eventData]);

  return (
    <>
      <Box sx={{ backgroundColor: '#ffffff', height: '100vh', p: 2 }}>
        <Grid
          container
          spacing={2}
          sx={{
            pb: 2,
            ml: 0,
            pl: 0,
            mr: 2,
            backgroundColor: 'rgba(243, 246, 249, 0.6)'
          }}
        >
          {dashboardMetadata?.globalAccountGuidNameMap &&
            Object.entries(dashboardMetadata.globalAccountGuidNameMap).map(
              ([key, value]) => (
                <Grid key={key} item xs={2}>
                  <SimpleCard
                    cardHeader="Global Account"
                    cardTitle={'Name: '.concat(value)}
                    cardText={key}
                    showUsersUi={handleShowUsers}
                  />
                </Grid>
              )
            )}
          {dashboardMetadata?.subAccountGuidNameMap &&
            Object.entries(dashboardMetadata.subAccountGuidNameMap).map(
              ([key, value]) => (
                <Grid key={key} item xs={2}>
                  <SimpleCard
                    cardHeader="Sub Account"
                    cardTitle={'Name: '.concat(value)}
                    cardText={key}
                    subaccountStatus={
                      dashboardMetadata.subaccountUsersStatus.find(
                        (s) => s.subaccountGuid === key
                      )?.subaccountStatus || ''
                    }
                    subaccountUsedForProduction={
                      dashboardMetadata.subaccountUsersStatus.find(
                        (s) => s.subaccountGuid === key
                      )?.subaccountUsedForProduction || ''
                    }
                    showUsersUi={handleShowUsers}
                  />
                </Grid>
              )
            )}
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'rgba(243, 246, 249, 0.6)',
            ml: 0,
            pb: 2
          }}
        >
          <Grid item xs={6}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <EChartsChart
                data={eventData}
                chartText="Total detected changes"
                chartSubtitle="Changes by last 30 days"
                color="blue"
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {loading ? (
              <LoadingIndicator />
            ) : (
              <EChartsChart
                data={alertsHistoryData}
                chartText="Total reported changes"
                chartSubtitle="Reported by last 30 days"
                color="red"
              />
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            pb: 2,
            mt: 2,
            pt: 2,
            backgroundColor: 'rgba(243, 246, 249, 0.6)'
          }}
        >
          <RecommendedPoliciesUi />
        </Box>
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle
            sx={{
              backgroundColor: 'white',
              color: 'black',
              fontSize: '1.2rem',
              mb: 2
            }}
          >
            <Typography variant="h2">Users</Typography>
            <Divider sx={{ mt: 2 }} />
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                maxHeight: selectedUsers.length > 10 ? '300px' : 'auto',
                minWidth: '30vh',
                overflowY: 'auto'
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedUsers.map((user, index) => (
                      <TableRow key={user}>
                        <TableCell>{user}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </DialogContent>
        </Dialog>
        <HotToast />
      </Box>
    </>
  );
};

export default BtpDashboard;
