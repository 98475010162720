import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MoreIcon from '@mui/icons-material/More';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SettingsIcon from '@mui/icons-material/Settings';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import { Tooltip, Typography } from '@mui/material';
import {
  getBTPCurrentState,
  getBtpMonitoringSettings,
  getClassIdText,
  getGlobalAccountName,
  getSubaccountName
} from '../../../api/sapBtp/sapBtpService';
import {
  BtpCliResponse,
  BtpMonitoringSettingsResponse
} from '../../../api/sapBtp/sapBtpApi';
import formatDateTime from '../../formatDateTime';

const PAGE_SIZE_OPTIONS = [10, 20, 25];

const AdvancedSettingsUi: React.FC<{ onSelectId: (id: number) => void }> = ({
  onSelectId
}) => {
  const [rows, setRows] = useState<BtpCliResponse[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalPages, setTotalPages] = useState(0);

  const fetchData = async () => {
    try {
      const response = await getBTPCurrentState(page, pageSize);
      const formattedData: BtpCliResponse[] = response.content.map(
        (item: any) => ({
          classId: item.classId,
          json: item.json,
          globalAccountGuid: item.globalAccountGuid,
          subaccountGuid: item.subaccountGuid,
          userEmail: item.userEmail,
          createdAt: item.createdAt,
          id: item.id.toString()
        })
      );
      setRows(formattedData);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize]); // Refetch data when page or pageSize changes

  const handleButtonClick = (row: BtpCliResponse) => {
    onSelectId(row.id);
    console.log('Button clicked for row ID:', row.id);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing page size
  };
  const [hoveredGlobalAccountId, setHoveredGlobalAccountId] = useState<
    number | null
  >(null);
  const [hoveredSubaccountId, setHoveredSubaccountId] = useState<number | null>(
    null
  );
  const [globalAccountName, setGlobalAccountName] = useState<string | null>(
    null
  );
  const [subaccountName, setSubaccountName] = useState<string | null>(null);

  const handleMouseOverGlobalAccount = async (guid: string, rowId: number) => {
    setHoveredGlobalAccountId(rowId);
    try {
      const response = await getGlobalAccountName(guid);
      setGlobalAccountName(response);
    } catch (error) {
      console.error('Error fetching global account name:', error);
    }
  };

  const handleMouseOutGlobalAccount = () => {
    setHoveredGlobalAccountId(null);
    setGlobalAccountName(null);
  };

  const handleMouseOverSubaccount = async (guid: string, rowId: number) => {
    setHoveredSubaccountId(rowId);
    try {
      const response = await getSubaccountName(guid);
      setSubaccountName(response);
    } catch (error) {
      console.error('Error fetching subaccount name:', error);
    }
  };

  const handleMouseOutSubaccount = () => {
    setHoveredSubaccountId(null);
    setSubaccountName(null);
  };
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        pt: 1,
        backgroundColor: 'rgba(159,159,159,0.05)'
      }}
    >
      <Typography
        sx={{
          width: '90%',
          backgroundColor: '#ffffff',
          fontSize: 'small',
          mb: 1,
          ml: '5%',
          color: '#CACACA',
          textAlign: 'center',
          borderRadius: '4px'
        }}
      >
        Advanced Settings Configuration for SAP BTP parameters tracking
      </Typography>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          ml: 2,
          mr: 2
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>State</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <div>
                      <strong>{`Context: ${getClassIdText(
                        row.classId
                      )}`}</strong>
                    </div>

                    {row.subaccountGuid && (
                      <div>
                        {`Subaccount: ${row.subaccountGuid}`}
                        <Tooltip
                          title={
                            hoveredSubaccountId === row.id
                              ? subaccountName || ''
                              : ''
                          }
                          open={
                            hoveredSubaccountId === row.id &&
                            Boolean(subaccountName)
                          }
                          disableHoverListener
                        >
                          <IconButton
                            onMouseOver={() =>
                              handleMouseOverSubaccount(
                                row.subaccountGuid,
                                row.id
                              )
                            }
                            onMouseOut={handleMouseOutSubaccount}
                          >
                            <MoreIcon style={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {row.globalAccountGuid &&
                      row.subaccountGuid.length === 0 && (
                        <div>
                          {`Global Account: ${row.globalAccountGuid}`}
                          <Tooltip
                            title={
                              hoveredGlobalAccountId === row.id
                                ? globalAccountName || ''
                                : ''
                            }
                            open={
                              hoveredGlobalAccountId === row.id &&
                              Boolean(globalAccountName)
                            }
                            disableHoverListener
                          >
                            <IconButton
                              onMouseOver={() =>
                                handleMouseOverGlobalAccount(
                                  row.globalAccountGuid,
                                  row.id
                                )
                              }
                              onMouseOut={handleMouseOutGlobalAccount}
                            >
                              <MoreIcon style={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    {row.userEmail && (
                      <div>{`User Email: ${row.userEmail}`}</div>
                    )}
                  </TableCell>
                  <TableCell>
                    {formatDateTime(new Date(row.createdAt))}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleButtonClick(row)}
                      aria-label="Get configuration"
                      placeholder="Get configuration"
                      color="success"
                    >
                      <SettingsIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalPages * pageSize}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
        />
      </Box>
    </Box>
  );
};

export default AdvancedSettingsUi;
