import React from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardProps,
  Theme,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Severity } from '../Severity';
import { Vulnerability } from '../../../api/vulnerabilityAssessment/vulnerabilityApi';
import { Status } from '../Status';

export const cardHeaderEnum = {
  VULNERABILITIES: 'Vulnerabilities',
  STATUS: 'Status',
  SEVERITY: 'Severity'
};

const WidgetTemplate = ({
  cardHeaders,
  vulnerabilities,
  ...props
}: {
  cardHeaders: string;
  vulnerabilities: Vulnerability[];
} & Partial<CardProps>) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(0)
        }
      }
    })
  );

  function printStatistic() {
    /* fixme, make me most smaller
     */
    const newVulnerabilities = vulnerabilities.filter(
      (s) => s.status === Status.NEW
    ).length;
    const acceptedVulnerabilities = vulnerabilities.filter(
      (s) => s.status === Status.ACCEPTED
    ).length;
    const fixedVulnerabilities = vulnerabilities.filter(
      (s) => s.status === Status.FIXED
    ).length;

    const criticalVulnerabilities = vulnerabilities.filter(
      (s) => s.severity === Severity.CRITICAL
    ).length;
    const highVulnerabilities = vulnerabilities.filter(
      (s) => s.severity === Severity.HIGH
    ).length;
    const mediumVulnerabilities = vulnerabilities.filter(
      (s) => s.severity === Severity.MEDIUM
    ).length;
    const lowVulnerabilities = vulnerabilities.filter(
      (s) => s.severity === Severity.LOW
    ).length;

    switch (cardHeaders) {
      case cardHeaderEnum.VULNERABILITIES:
        return 'Total vulnerabilities - '.concat(
          vulnerabilities.length.toString()
        );
      case cardHeaderEnum.STATUS:
        return `New - ${newVulnerabilities} \nAccepted - ${acceptedVulnerabilities} \nFixed - ${fixedVulnerabilities}`;
      case cardHeaderEnum.SEVERITY:
        return `Critical - ${criticalVulnerabilities} \nHigh - ${highVulnerabilities} \nMedium - ${mediumVulnerabilities} \nLow - ${lowVulnerabilities}`;
      default:
        return 4;
    }
  }
  const classes = useStyles();
  return (
    <Box className={classes.root} sx={{ m: 1, pr: 1, mt: 0 }}>
      <Card sx={{ width: '100%' }}>
        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="h3">{cardHeaders}</Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            style={{ whiteSpace: 'pre-line', alignItems: 'left' }}
            align="center"
            variant="h6"
          >
            {printStatistic()}
          </Typography>
        </CardActions>
      </Card>
    </Box>
  );
};
export default WidgetTemplate;
