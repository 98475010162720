import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

interface Props {
  anonymousOnly: boolean;
  anonymous: boolean;
  onChange: (anonymous: boolean) => void;
}

const AnonymousSelector: React.FC<Props> = ({
  anonymousOnly,
  anonymous,
  onChange
}: Props): JSX.Element => {
  return (
    <FormControlLabel
      control={
        <Switch
          color="success"
          checked={anonymous}
          onChange={(e) => onChange(e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={anonymousOnly}
        />
      }
      label="Anonymous"
    />
  );
};

export default AnonymousSelector;
