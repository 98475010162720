import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer, IconButton, List } from '@mui/material';
import {
  Cloud,
  CloudLightning,
  Edit2,
  Grid,
  Hexagon,
  Key,
  Package,
  Search,
  Settings,
  Tool
} from 'react-feather';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import NavItem from './NavItem';
import { getLicenseInfo } from './settings/license/LicensingService';
import { LicenseInterface } from './settings/license/LicenseInterface';

const items = [
  {
    id: 1,
    href: '/app/dashboard',
    icon: Grid,
    title: 'Dashboard'
  },
  {
    id: 2,
    href: '/app/portscan',
    icon: Search,
    title: 'Port and Service Scan'
  },
  {
    id: 3,
    href: '/app/vulnerability-assessment',
    icon: CloudLightning,
    title: 'Vulnerability Assessment'
  },
  {
    id: 4,
    href: '/app/sap-notes',
    icon: Edit2,
    title: 'Missing Security Notes'
  },
  {
    id: 5,
    href: '/app/threat-modelling',
    icon: Hexagon,
    title: 'Threat Modelling'
  },
  {
    id: 6,
    href: '/app/profile-parameters',
    icon: Tool,
    title: 'Profile Parameters'
  },
  {
    id: 7,
    href: '/app/component-versions',
    icon: Package,
    title: 'Outdated Components'
  },
  {
    id: 8,
    href: '/app/password-security',
    icon: Key,
    title: 'Passwords Security'
  },
  {
    id: 9,
    href: '/app/sap-btp-monitoring',
    icon: Cloud,
    title: 'SAP BTP Security'
  },
  {
    id: 10,
    href: '/app/settings',
    icon: Settings,
    title: 'Platform Settings'
  }
];

const DashboardSidebar = (): JSX.Element => {
  const location = useLocation();
  const [mini, setMini] = useState(true);
  const [licenseInfo, setLicenseInfo] = useState<LicenseInterface>();

  useEffect(() => {
    if (!mini) {
      setMini(true);
    }
    getLicenseInfo().then((resp) => {
      if (resp !== undefined) {
        setLicenseInfo(resp);
      }
    });
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ p: 1, pt: 2 }}>
        <IconButton
          sx={{
            pb: 2,
            color: '#a9a9a9'
          }}
          onClick={() => setMini(!mini)}
          size="medium"
        >
          {mini ? (
            <FormatIndentIncreaseIcon sx={{ fontSize: '25px' }} />
          ) : (
            <FormatIndentDecreaseIcon sx={{ fontSize: '25px' }} />
          )}
        </IconButton>
        <List sx={{ py: 0 }}>
          {items.map((item, index) => {
            if (
              (licenseInfo &&
                item.href === '/app/threat-modelling' &&
                licenseInfo?.tm === 0) ||
              (item.href === '/app/profile-parameters' &&
                licenseInfo?.pp === 0) ||
              (item.href === '/app/component-versions' &&
                licenseInfo?.oc === 0) ||
              (item.href === '/app/password-security' && licenseInfo?.bf === 0)
            ) {
              return <></>;
            }
            if (
              !licenseInfo &&
              item.title !== 'Dashboard' &&
              item.title !== 'Platform Settings'
            ) {
              return <></>;
            }

            return (
              <NavItem
                href={item.href}
                key={item.id} // Use item.id if available, otherwise fallback to index
                title={mini ? '' : item.title}
                icon={item.icon}
              />
            );
          })}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        open
        variant="persistent"
        PaperProps={{
          sx: {
            width: 58,
            top: 64,
            height: 'calc(100% - 64px)',
            overflowX: 'hidden'
          }
        }}
      >
        {content}
      </Drawer>
      {!mini && (
        <Drawer
          anchor="left"
          onClose={() => setMini(true)}
          open
          variant="temporary"
          PaperProps={{
            sx: {
              width: 'min(100%, 256px)',
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          <Box sx={{ width: 256 }}>{content}</Box>
        </Drawer>
      )}
    </>
  );
};

export default DashboardSidebar;
