import {
  ActivatedPoliciesInterface,
  AlertsHistoryInterface,
  BtpCliResponse,
  BtpConnectionProperties,
  BtpDashboardMetadataInterface,
  BtpMonitoringSettingsRequest,
  BtpMonitoringSettingsResponse,
  CLASS_ID_MAP,
  EventsInterface,
  GlobalAccountListResponse,
  IgnoreEventsInterface,
  RedRaysPoliciesInterface
} from './sapBtpApi';
import { checkOk } from '../common';

const BASE_URL = '/api/v1'; // Your API base URL

export async function getBtpSupportedRegionList(): Promise<string[]> {
  return fetch(`${BASE_URL}/btp/regions/list`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export async function testConnectToBtp(
  btpConnectionProperties: BtpConnectionProperties
): Promise<boolean> {
  try {
    const response = await fetch(`${BASE_URL}/btp/connection/test`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(btpConnectionProperties)
    });

    if (response.ok) {
      return await response.json();
    }
    throw new Error('Failed to test connection to SAP BTP');
  } catch (error) {
    console.error('Error testing connection to SAP BTP:', error);
    throw error;
  }
}

export async function saveBtpConnection(
  btpConnectionProperties: BtpConnectionProperties
): Promise<boolean> {
  try {
    const response = await fetch(`${BASE_URL}/btp/connection/save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(btpConnectionProperties)
    });

    if (response.ok) {
      return await response.json();
    }
    throw new Error('Failed to save BtpConnectionProperties');
  } catch (error) {
    console.error('Error saving BtpConnectionProperties:', error);
    throw error;
  }
}

export async function getBtpConnectionsList() {
  return fetch(`${BASE_URL}/btp/connections/list`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export async function deleteBtpConnectionById(id: number) {
  return fetch(`${BASE_URL}/btp/connection/delete/${id}`, {
    method: 'DELETE'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export async function startBtpMonitoring(
  btpConnectionProperties: BtpConnectionProperties
) {
  return fetch(`${BASE_URL}/btp/start/monitoring`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(btpConnectionProperties)
  })
    .then(checkOk)
    .then((response) => response.json());
}

export async function getBtpMonitoringMedata(): Promise<
  BtpConnectionProperties[]
> {
  return fetch(`${BASE_URL}/btp/monitoring/metadata`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export async function btpMonitoringStartStop(
  btpConnectionProperties: BtpConnectionProperties[]
) {
  return fetch(`${BASE_URL}/btp/monitoring/change/state`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(btpConnectionProperties)
  })
    .then(checkOk)
    .then((response) => response.json());
}

export async function getGlobalAccountList(): Promise<
  GlobalAccountListResponse[]
> {
  return fetch(`${BASE_URL}/btp/monitoring/global/account/list/metadata`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export async function getBtpMonitoringSettings(): Promise<BtpMonitoringSettingsResponse> {
  try {
    const response = await fetch(`${BASE_URL}/btp/settings/metadata`, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch BTP monitoring settings');
    }

    return response.json();
  } catch (error) {
    throw new Error(`Error fetching BTP monitoring settings: ${error}`);
  }
}

export async function updateBtpMonitoringSettings(
  settings: BtpMonitoringSettingsRequest
): Promise<void> {
  try {
    const response = await fetch(`${BASE_URL}/btp/settings/metadata`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(settings)
    });

    if (!response.ok) {
      throw new Error('Failed to update BTP monitoring settings');
    }
  } catch (error) {
    throw new Error(`Error updating BTP monitoring settings: ${error}`);
  }
}

export async function patchBtpMonitoringSettings(
  settings: BtpMonitoringSettingsRequest
): Promise<void> {
  try {
    const response = await fetch(`${BASE_URL}/btp/settings/metadata`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(settings)
    });

    if (!response.ok) {
      throw new Error('Failed to patch BTP monitoring settings');
    }
  } catch (error) {
    throw new Error(`Error patching BTP monitoring settings: ${error}`);
  }
}

export async function getBTPCurrentState(
  page: number,
  pageSize: number
): Promise<{ content: BtpCliResponse[]; totalPages: number }> {
  return fetch(
    `${BASE_URL}/btp/monitoring/current/state/get?page=${page}&size=${pageSize}`,
    {
      method: 'GET'
    }
  )
    .then(checkOk)
    .then((response) => response.json());
}

export async function getBTPCurrentStateById(
  id: string
): Promise<BtpCliResponse> {
  return fetch(`${BASE_URL}/btp/monitoring/current/state/${id}/get`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export async function btpSignOut(): Promise<boolean> {
  return fetch(`${BASE_URL}/btp/sign-out`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

// todo change to send by event id
export async function setBTPAlertCollection(
  alertEvent: ActivatedPoliciesInterface
) {
  return fetch(`${BASE_URL}/btp/monitoring/alerts/events/set`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(alertEvent)
  })
    .then(checkOk)
    .then((response) => response.text());
}

export async function setBTPIgnorableCollection(
  ignoreEvent: IgnoreEventsInterface
) {
  return fetch(`${BASE_URL}/btp/monitoring/ignorable/events/set`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(ignoreEvent)
  })
    .then(checkOk)
    .then((response) => response.text());
}

export async function removeBTPAlertCollection(id: number) {
  return fetch(`${BASE_URL}/btp/monitoring/alerts/events/${id}/remove`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export async function removeBTPIgnorableCollection(id: number) {
  return fetch(`${BASE_URL}/btp/monitoring/ignorable/events/${id}/remove`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export async function activateRedRaysRule(id: number) {
  return fetch(
    `${BASE_URL}/btp/monitoring/redrays/tracking/settings/${id}/activate`,
    {
      method: 'GET'
    }
  )
    .then(checkOk)
    .then((response) => response.text());
}

export async function removeRedRaysRule(id: number) {
  return fetch(
    `${BASE_URL}/btp/monitoring/redrays/tracking/settings/${id}/remove`,
    {
      method: 'GET'
    }
  )
    .then(checkOk)
    .then((response) => response.text());
}

export async function getGlobalAccountsGuid() {
  return fetch(`${BASE_URL}/btp/monitoring/globalAccounts/list`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export async function getSubAccountsGuid() {
  return fetch(`${BASE_URL}/btp/monitoring/subAccounts/list`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function getClassIdText(classId: string) {
  return CLASS_ID_MAP[classId] || classId;
}

export function normalizeChangeType(changeType: string) {
  if (changeType.includes('ITEM_ADDED')) return 'ITEM_ADDED';
  if (changeType.includes('ITEM_REMOVED')) return 'ITEM_REMOVED';

  return changeType;
}

export async function getEventsList(
  page: number,
  pageSize: number,
  searchQuery: string // Add searchQuery parameter
): Promise<{ content: EventsInterface[]; totalPages: number }> {
  const response = await fetch(
    `${BASE_URL}/btp/monitoring/events/list/get?page=${page}&size=${pageSize}&searchQuery=${encodeURIComponent(
      searchQuery
    )}`, // Encode the search query parameter
    {
      method: 'GET'
    }
  );
  const data = await response.json();
  return { content: data.content, totalPages: data.totalPages };
}

export async function getActivatedPoliciesList(
  page: number,
  pageSize: number,
  searchQuery: string // Add searchQuery parameter
): Promise<{ content: ActivatedPoliciesInterface[]; totalPages: number }> {
  const response = await fetch(
    `${BASE_URL}/btp/monitoring/alerts/list/get?page=${page}&size=${pageSize}&searchQuery=${encodeURIComponent(
      searchQuery
    )}`, // Encode the search query parameter
    {
      method: 'GET'
    }
  );
  const data = await response.json();
  return { content: data.content, totalPages: data.totalPages };
}

export async function getAlertsHistoryList(
  page: number,
  pageSize: number,
  searchQuery: string // Add searchQuery parameter
): Promise<{ content: AlertsHistoryInterface[]; totalPages: number }> {
  const response = await fetch(
    `${BASE_URL}/btp/monitoring/alerts/history/get?page=${page}&size=${pageSize}&searchQuery=${encodeURIComponent(
      searchQuery
    )}`, // Encode the search query parameter
    {
      method: 'GET'
    }
  );
  const data = await response.json();
  return { content: data.content, totalPages: data.totalPages };
}

export async function getIgnorablePolicyList(
  page: number,
  pageSize: number
): Promise<{ content: IgnoreEventsInterface[]; totalPages: number }> {
  const response = await fetch(
    `${BASE_URL}/btp/monitoring/ignorable/list/get?page=${page}&size=${pageSize}`, // Encode the search query parameter

    {
      method: 'GET'
    }
  );
  const data = await response.json();
  return { content: data.content, totalPages: data.totalPages };
}

export async function getRedRaysPolicySettingsList(
  page: number,
  pageSize: number
): Promise<{ content: RedRaysPoliciesInterface[]; totalPages: number }> {
  const response = await fetch(
    `${BASE_URL}/btp/monitoring/redrays/tracking/settings/list/get?page=${page}&size=${pageSize}`, // Encode the search query parameter

    {
      method: 'GET'
    }
  );
  const data = await response.json();
  return { content: data.content, totalPages: data.totalPages };
}

export async function getSapBtpDashboardMetadata(): Promise<BtpDashboardMetadataInterface> {
  return fetch(`${BASE_URL}/btp/dashboard/metadata`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export async function getGlobalAccountName(guid: string): Promise<string> {
  try {
    const response = await fetch(
      `${BASE_URL}/btp/monitoring/globalAccount/name/${guid}/get`,
      {
        method: 'GET'
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch global account name');
    }

    return response.text();
  } catch (error) {
    throw new Error(`Error fetching global account name: ${error}`);
  }
}

export async function getSubaccountName(guid: string): Promise<string> {
  try {
    const response = await fetch(
      `${BASE_URL}/btp/monitoring/subAccount/name/${guid}/get`,
      {
        method: 'GET'
      }
    );

    if (!response.ok) {
      throw new Error('Failed to fetch global account name');
    }

    return response.text();
  } catch (error) {
    throw new Error(`Error fetching global account name: ${error}`);
  }
}
export function humanizeChangeType(changeType: string) {
  if (changeType.includes('ITEM_ADDED')) return 'ITEM ADDED';
  if (changeType.includes('ITEM_REMOVED')) return 'ITEM REMOVED';
  if (changeType.includes('VALUES_CHANGED')) return 'VALUES CHANGED';

  return changeType;
}
