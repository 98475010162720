import { checkOk } from '../common';
import SapNotesPossibleAreNotInstalled from './serviceApi';

// eslint-disable-next-line import/prefer-default-export
export function getSapNotes(
  networkId: number
): Promise<SapNotesPossibleAreNotInstalled[]> {
  return fetch(`/api/v1/get/sap/notes/${networkId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}
