import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/More';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TextField from '@mui/material/TextField';
import { Tooltip, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import toast from 'react-hot-toast';
import {
  AlertsHistoryInterface,
  BtpMonitoringSettingsResponse
} from '../../../api/sapBtp/sapBtpApi';
import {
  getAlertsHistoryList,
  getBtpMonitoringSettings,
  getClassIdText,
  getGlobalAccountName,
  getSubaccountName,
  humanizeChangeType
} from '../../../api/sapBtp/sapBtpService';
import formatDateTime from '../../formatDateTime';
import HotToast from '../../notifications/HotToast';

const AlertsCollectionUi: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<AlertsHistoryInterface[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const PAGE_SIZE_OPTIONS = [10, 20, 25];
  const [btpMonitoringSettings, setBtpMonitoringSettings] =
    useState<BtpMonitoringSettingsResponse>();
  const [prevRows, setPrevRows] = useState<AlertsHistoryInterface[]>([]);
  const prevRowsRef = useRef(prevRows);
  const notifySuccess = (message: string) => toast.success(message);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getBtpMonitoringSettings();
        setBtpMonitoringSettings(settings);
      } catch (error) {
        console.error('Error fetching BTP monitoring settings:', error);
      }
    };

    fetchSettings();
  }, []);

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    try {
      const response = await getAlertsHistoryList(page, pageSize, searchQuery);

      const updatedRows = response.content.map((alertHistory) => ({
        ...alertHistory,
        trackChange: alertHistory.trackChange.replace('REDRAYS_', ''),
        changePath: alertHistory.changePath.replace('REDRAYS_', ''),
        createdAt: alertHistory.createdAt
      }));

      setRows(updatedRows);
      setTotalPages(response.totalPages);

      if (JSON.stringify(updatedRows) !== JSON.stringify(prevRowsRef.current)) {
        notifySuccess('New events detected in SAP BTP');
        setPrevRows(updatedRows);
        prevRowsRef.current = updatedRows;
      }
    } catch (error) {
      console.error('Error fetching event logs:', error);
    }
  };

  useEffect(() => {
    setRows([]);
    fetchData();

    const interval = setInterval(fetchData, 10000);

    return () => clearInterval(interval);
  }, [page, searchQuery]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const [hoveredGlobalAccountId, setHoveredGlobalAccountId] = useState<
    number | null
  >(null);
  const [hoveredSubaccountId, setHoveredSubaccountId] = useState<number | null>(
    null
  );
  const [globalAccountName, setGlobalAccountName] = useState<string | null>(
    null
  );
  const [subaccountName, setSubaccountName] = useState<string | null>(null);

  const handleMouseOverGlobalAccount = async (guid: string, rowId: number) => {
    setHoveredGlobalAccountId(rowId);
    try {
      const response = await getGlobalAccountName(guid);
      setGlobalAccountName(response);
    } catch (error) {
      console.error('Error fetching global account name:', error);
    }
  };

  const handleMouseOutGlobalAccount = () => {
    setHoveredGlobalAccountId(null);
    setGlobalAccountName(null);
  };

  const handleMouseOverSubaccount = async (guid: string, rowId: number) => {
    setHoveredSubaccountId(rowId);
    try {
      const response = await getSubaccountName(guid);
      setSubaccountName(response);
    } catch (error) {
      console.error('Error fetching subaccount name:', error);
    }
  };

  const handleMouseOutSubaccount = () => {
    setHoveredSubaccountId(null);
    setSubaccountName(null);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        pt: 1,
        backgroundColor: 'rgba(159,159,159,0.05)'
      }}
    >
      <Typography
        sx={{
          width: '90%',
          backgroundColor: '#ffffff',
          fontSize: 'small',
          mb: 1,
          ml: '5%',
          color: '#CACACA',
          textAlign: 'center',
          borderRadius: '4px'
        }}
      >
        Notified alerts
      </Typography>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          ml: 2,
          mr: 2
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>BTP Object</TableCell>
                <TableCell>Change Type</TableCell>
                {btpMonitoringSettings?.showPath === 'true' && (
                  <TableCell>Change Path</TableCell>
                )}
                <TableCell>Changes</TableCell>
                <TableCell>Threat Name</TableCell>
                <TableCell>Severity</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6}>
                  <TextField
                    sx={{ ml: 0, mt: 1 }}
                    size="small"
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    label="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No notified events found
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <div>
                      <strong>{`Context: ${getClassIdText(
                        row.classId
                      )}`}</strong>
                    </div>
                    {row.subaccountGuid && (
                      <div>
                        {`Subaccount: ${row.subaccountGuid}`}
                        <Tooltip
                          title={
                            hoveredSubaccountId === row.id
                              ? subaccountName || ''
                              : ''
                          }
                          open={
                            hoveredSubaccountId === row.id &&
                            Boolean(subaccountName)
                          }
                          disableHoverListener
                        >
                          <IconButton
                            onMouseOver={() =>
                              handleMouseOverSubaccount(
                                row.subaccountGuid,
                                row.id
                              )
                            }
                            onMouseOut={handleMouseOutSubaccount}
                          >
                            <MoreIcon style={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {row.globalAccountGuid &&
                      row.subaccountGuid.length === 0 && (
                        <div>
                          {`Global Account: ${row.globalAccountGuid}`}
                          <Tooltip
                            title={
                              hoveredGlobalAccountId === row.id
                                ? globalAccountName || ''
                                : ''
                            }
                            open={
                              hoveredGlobalAccountId === row.id &&
                              Boolean(globalAccountName)
                            }
                            disableHoverListener
                          >
                            <IconButton
                              onMouseOver={() =>
                                handleMouseOverGlobalAccount(
                                  row.globalAccountGuid,
                                  row.id
                                )
                              }
                              onMouseOut={handleMouseOutGlobalAccount}
                            >
                              <MoreIcon style={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    {row.userEmail && (
                      <div>{`User Email: ${row.userEmail}`}</div>
                    )}
                  </TableCell>
                  <TableCell>{humanizeChangeType(row.changeType)}</TableCell>
                  {btpMonitoringSettings?.showPath === 'true' && (
                    <TableCell>{row.changePath}</TableCell>
                  )}
                  <TableCell>{row.trackChange}</TableCell>
                  <TableCell>
                    {row.redRaysPoliciesSettings?.title ?? ''}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold', // This line makes the text bold
                      backgroundColor:
                        row.redRaysPoliciesSettings?.severity === 'HIGH'
                          ? red[100]
                          : 'inherit'
                    }}
                  >
                    {row.redRaysPoliciesSettings?.severity ?? 'INFO'}
                  </TableCell>

                  <TableCell>
                    {formatDateTime(new Date(row.createdAt))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <HotToast />
        </TableContainer>
        <TablePagination
          component="div"
          count={totalPages * pageSize}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          rowsPerPageOptions={PAGE_SIZE_OPTIONS}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default AlertsCollectionUi;
