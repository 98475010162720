import { Network, NetworkMetadata, NetworkStatus, Tree } from './treeApi';
import { checkOk, perform } from '../common';
import { SapSidStructure } from '../../features/portscan/SapSidStructure';
import { SystemTypeInterface } from '../../features/portscan/tree/ip/IpNode';

export function updateNetwork(
  tree: Tree,
  networkId: number | undefined,
  status: NetworkStatus | undefined,
  networkName: string | undefined,
  onSuccess: (id: number) => void,
  onFail: (reason: any) => void
): void {
  perform<number>(
    `/api/v1/networks/${networkId}/${status}/update`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: networkName, networkJson: tree })
    },
    (updatedNetworkId) => onSuccess(updatedNetworkId),
    (reason) => onFail(reason)
  );
}

export function saveNetwork(
  tree: Tree,
  newName: string,
  onSuccess: (id: number) => void,
  onFail: (reason: any) => void
): void {
  perform<number>(
    '/api/v1/networks',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: newName, networkJson: tree })
    },
    (networkId) => onSuccess(networkId),
    (reason) => onFail(reason)
  );
}

export function saveMultiHostScanNetwork(
  name: string,
  host: string,
  onSuccess: (id: number) => void,
  onFail: (reason: any) => void
): void {
  perform<number>(
    '/api/v1/massnetworks',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: `{"name":"${name}","networkJson":{"ips":[{"ip":"${host}","instances":[]}]}}` // fixme, pass as a JSON object, not string
    },
    (networkId) => onSuccess(networkId),
    (reason) => onFail(0)
  );
}

export function loadNetwork(
  selectedNetworkId: number,
  onSuccess: (network: Network) => void,
  onFail: (reason: any) => void
): void {
  perform<Network>(
    `/api/v1/networks/${selectedNetworkId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    },
    (network) => onSuccess(network),
    (reason) => onFail(reason)
  );
}

export function scanNetwork(
  selectedNetworkId: number,
  newName: string,
  onSuccess: (networkId: number) => void,
  onFail: (reason: any) => void
): void {
  perform<number>(
    `/api/v1/networks/${selectedNetworkId}/scan`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: newName })
    },
    (networkId) => onSuccess(networkId),
    (reason) => onFail(reason)
  );
}

export function renameNetwork(
  networkId: number,
  aNewName: string,
  onSuccess: (newName: string) => void,
  onFail: (reason: any) => void
): void {
  perform<Network>(
    `/api/v1/networks/${networkId}`,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ newName: aNewName })
    },
    (network) => onSuccess(network.name),
    (reason) => onFail(reason)
  );
}

export function getNetworksMetadata(): Promise<NetworkMetadata[]> {
  return fetch('/api/v1/networks/metadata', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function getVANetworksMetadata(): Promise<NetworkMetadata[]> {
  return fetch('/api/v1/vanetworks/metadata', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function getSapNotesNetworksMetadata(): Promise<NetworkMetadata[]> {
  return fetch('/api/v1/sap/notes/networks/metadata', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function getSidByIp(ip: string): Promise<SapSidStructure[]> {
  return fetch(`/api/v1/networks/ip/${ip}/get/sid`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function getSystemTypeByIp(ip: string): Promise<SystemTypeInterface> {
  return fetch(`/api/v1/networks/ip/${ip}/get/type`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function setSystemTypeByIp(
  ip: string,
  newTypeId: number
): Promise<string> {
  return fetch(`/api/v1/networks/ip/${ip}/set/type/${newTypeId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function uploadIpList(formData: FormData) {
  return fetch('/api/v1/networks/iplist/create', {
    method: 'POST',
    body: formData
  }).then((response) => response.text());
}
