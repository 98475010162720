import * as React from 'react';
import { ReactNode } from 'react';
import { Tooltip, Typography } from '@mui/material';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderEditCellParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValueGetterParams
} from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import { useInterval } from 'usehooks-ts';
import Stack from '@mui/material/Stack';
import BugReportIcon from '@mui/icons-material/BugReport';
import { NetworkMetadata, NetworkStatus } from '../../api/network/treeApi';
import { getNetworksMetadata } from '../../api/network/networkService';
import theme from '../../theme';

interface Props {
  onChange: (networkMetadata: NetworkMetadata) => void;
  selectedId: number | undefined;
  forceReloadNeeded?: boolean;
  onAfterReloaded?: (networkMetadata: NetworkMetadata | undefined) => void;
  hasDefaultFilter?: boolean;
  allowSelectNetworksWithErrors?: boolean;
}

function FilterToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton
        onResize={() => console.log('Resized')}
        onResizeCapture={() => console.log('Resized captured')}
      />{' '}
    </GridToolbarContainer>
  );
}

function renderStatusCell(params: GridRenderEditCellParams): ReactNode {
  switch (params.value) {
    case NetworkStatus.SCANNED:
      return (
        <Tooltip title="The port scan has been done successfully">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckCircleIcon style={{ fontSize: '0.9rem', color: 'green' }} />
            <Typography
              variant="body2"
              style={{ fontSize: '0.9rem', color: 'green' }}
              sx={{ ml: 1 }}
            >
              Complete
            </Typography>
          </div>
        </Tooltip>
      );
    case NetworkStatus.SCANNING:
      return (
        <Tooltip title="The scan is in progress">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TimelapseIcon style={{ fontSize: '0.9rem', color: '#ffad00' }} />
            <Typography
              variant="body2"
              style={{ fontSize: '0.9rem', color: '#ffad00' }}
              sx={{ ml: 1 }}
            >
              Scanning...
            </Typography>
          </div>
        </Tooltip>
      );
    case NetworkStatus.ERROR:
      return (
        <Tooltip title="Аn error occurred during the scan">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RunningWithErrorsIcon
              style={{ fontSize: '0.9rem', color: 'red' }}
            />
            <Typography
              variant="body2"
              style={{ fontSize: '0.9rem', color: 'red' }}
              sx={{ ml: 1 }}
            >
              Error
            </Typography>
          </div>
        </Tooltip>
      );
    case NetworkStatus.ORIGINAL:
      return (
        <Tooltip title="Original Network">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AcUnitIcon style={{ fontSize: '0.9rem', color: 'primary' }} />
            <Typography
              variant="body2"
              style={{ fontSize: '0.9rem', color: 'primary' }}
              sx={{ ml: 1 }}
            >
              Unscanned
            </Typography>
          </div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="ERROR, Please contact with RedRays support">
          <NewReleasesIcon color="error" />
        </Tooltip>
      );
  }
}

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Network',
    flex: 10,
    valueGetter: (params: GridValueGetterParams) => params.row,
    renderCell: (params: GridCellParams) => (
      <div>
        <Typography variant="body1" color="textPrimary">
          {params.value.name}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Created on:{' '}
          {new Date(params.value.createdAt as string).toDateString()}
        </Typography>
      </div>
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: renderStatusCell,
    width: 130,
    align: 'left',
    headerAlign: 'left',
    description:
      'Original - never scanned network. Scanned - successfully finished port scan network and ready for VA scan.'
  }
];
const initialGridState = {
  initialState: {
    filter: {}
  }
};

const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  }
});

const NetworkSelector: React.FC<Props> = ({
  onChange,
  selectedId,
  forceReloadNeeded,
  onAfterReloaded,
  hasDefaultFilter = false,
  allowSelectNetworksWithErrors = true
}: Props) => {
  const classes = useStyles();

  const [networksMetadata, setNetworksMetadata] = React.useState<
    NetworkMetadata[]
  >([]);

  const reload = () => {
    getNetworksMetadata().then((aNetworksMetadata) => {
      setNetworksMetadata(
        aNetworksMetadata.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      );
      const selectedNetworkMetadata = aNetworksMetadata.find(
        (x) => x.id === selectedId
      );
      onAfterReloaded?.(selectedNetworkMetadata);
    });
  };

  useInterval(reload, 5000);

  React.useEffect(() => {
    const doesSelectedIdHaveMetadataLoaded =
      networksMetadata.find((x) => x.id === selectedId) !== undefined;
    if (forceReloadNeeded || !doesSelectedIdHaveMetadataLoaded) {
      reload();
    }
  }, [forceReloadNeeded, selectedId]);

  return (
    <DataGrid
      className={classes.root}
      {...(hasDefaultFilter ? initialGridState : {})}
      sx={{
        border: 0, // Remove the border
        '& .MuiDataGrid-main': {
          borderColor: 'transparent' // Remove cell borders
        },
        // If you want to remove the border for the header as well
        '& .MuiDataGrid-columnHeaders': {
          borderColor: 'transparent' // Remove header borders
        },
        flexGrow: 1,
        '& .MuiDataGrid-virtualScrollerContent': {
          backgroundColor: theme.palette.background.paper
        },
        '&.MuiDataGrid-root': {
          backgroundColor: '#fff'
        },
        '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
          display: 'none'
        },
        boxShadow: 0.5,
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer'
        }
      }}
      rows={networksMetadata}
      columns={columns}
      onRowClick={(params) => {
        const networkMetadata = networksMetadata.filter(
          (it) => it.id === params.id
        )[0];
        if (
          allowSelectNetworksWithErrors ||
          [NetworkStatus.ORIGINAL, NetworkStatus.SCANNED].includes(
            networkMetadata.status
          )
        ) {
          onChange(networkMetadata);
        }
      }}
      selectionModel={selectedId}
      hideFooterSelectedRowCount
      components={{
        /* Toolbar: FilterToolbar, */
        NoRowsOverlay: () => (
          <Stack
            height="100%"
            alignItems="center"
            justifyContent="center"
            style={{ position: 'relative', zIndex: 1 }}
          >
            <BugReportIcon sx={{ fontSize: '80px' }} style={{ color: 'red' }} />
            <pre>No Data</pre>
          </Stack>
        )
      }}
    />
  );
};

export default NetworkSelector;
