import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { grey } from '@mui/material/colors';
import { Box, Container, Grid, Tab, Typography } from '@mui/material';
import Emailing from '../components/settings/mailing/Emailing';
import Licensing from '../components/settings/license/Licensing';
import Jira from '../components/settings/jira/Jira';
import About from '../components/settings/About';
import ServiceNow from '../components/settings/servicenow/ServiceNow';
import Misc from '../components/settings/misc/Misc';
import Openvpn from '../components/settings/openvpn/Openvpn';

export default function LabTabs() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <title>Settings | RedRays</title>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%'
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3} sx={{ pt: 3.5 }}>
            <Box
              sx={{
                ml: 1,
                height: 'calc(100vh - 100px)',
                width: '100%',
                typography: 'body1',
                background: '#fff'
              }}
            >
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Email Settings" value="1" />
                    <Tab label="License" value="2" />
                    <Tab label="Jira ITSM" value="3" />
                    <Tab label="ServiceNow" value="4" />
                    <Tab label="Misc" value="5" />
                    <Tab label="OpenVPN" value="6" />
                    <Tab label="About" value="7" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Emailing />
                </TabPanel>
                <TabPanel value="2">
                  <Licensing />
                </TabPanel>
                <TabPanel value="3">
                  <Jira />
                </TabPanel>
                <TabPanel value="4">
                  <ServiceNow />
                </TabPanel>
                <TabPanel value="5">
                  <Misc />
                </TabPanel>
                <TabPanel value="6">
                  <Openvpn />
                </TabPanel>
                <TabPanel value="7">
                  <About />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
