import * as React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import VulnerabilityDialog from './VulnerabilityDialog';
import { getSeverityColor, Severity } from './Severity';
import { getStatusColor, Status } from './Status';
import {
  ColumnsGetter,
  ColumnsGetterProps,
  ReportGrid
} from '../../components/reportgrid/ReportGrid';
import { Vulnerability } from '../../api/vulnerabilityAssessment/vulnerabilityApi';

const getSeverityValue = (severity: Severity): number => {
  switch (severity) {
    case Severity.CRITICAL:
      return 4;
    case Severity.HIGH:
      return 3;
    case Severity.MEDIUM:
      return 2;
    case Severity.LOW:
      return 1;
    default:
      return 0;
  }
};

const columns: ColumnsGetter<Vulnerability> = ({
  rows,
  reloadReport
}: ColumnsGetterProps<Vulnerability>) => {
  return [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 250,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <span title={params.value}>{params.value}</span>
        </div>
      )
    },
    {
      field: 'ip',
      headerName: 'HOST',
      maxWidth: 150,
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <span title={params.value}>{params.value}</span>
        </div>
      )
    },
    {
      field: 'port',
      headerName: 'Port',
      minWidth: 30,
      renderCell: (params: GridRenderCellParams) => (
        <div style={{ textAlign: 'center' }}>
          <span>{params.value}</span>
        </div>
      )
    },
    {
      field: 'severity',
      headerName: 'Severity',
      maxWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            color: getSeverityColor(params.value as Severity),
            fontWeight: 'bold'
          }}
        >
          {params.value}
        </span>
      ),
      sortComparator: (v1, v2) =>
        getSeverityValue(v1 as Severity) - getSeverityValue(v2 as Severity)
    },
    {
      field: 'status',
      headerName: 'Status',
      maxWidth: 100,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            color: getStatusColor(params.value as Status),
            fontWeight: 'bold'
          }}
        >
          {params.value}
        </span>
      )
    }
  ];
};

const Vulnerabilities = ({
  rows,
  reloadReport
}: {
  rows: Vulnerability[];
  reloadReport: () => void;
}) => (
  <ReportGrid
    rows={rows}
    columnsGetter={columns}
    reloadReport={reloadReport}
    Dialog={VulnerabilityDialog}
    noRowsText="The report is not selected or vulnerabilities are not discovered"
  />
);

export default Vulnerabilities;
