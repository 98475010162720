import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Link, Stack, TextField, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import GetAppIcon from '@mui/icons-material/GetApp';
import ColoredEnumSelector from '../../components/coloredenumselector/ColoredEnumSelector';
import { getStatusColor, Status } from './Status';
import { getSeverityColor, Severity } from './Severity';
import {
  RowDialog,
  RowDialogProps
} from '../../components/reportgrid/ReportGrid';
import { Vulnerability } from '../../api/vulnerabilityAssessment/vulnerabilityApi';
import formatDateTime from '../../components/formatDateTime';
import { downloadRequestResponse } from '../profileparameters/Service';

const VulnerabilityDialog: RowDialog<Vulnerability> = ({
  selectedRow,
  opened,
  handleClose,
  reloadReport
}: RowDialogProps<Vulnerability>) => {
  const combineValues = (value1: string, value2: string) => {
    const trimmedValue1 = value1?.trim() || '';
    const trimmedValue2 = value2?.trim() || '';

    if (trimmedValue1 && trimmedValue2) {
      return `${trimmedValue1}\n\n${trimmedValue2}`;
    }
    return trimmedValue1 || trimmedValue2;
  };

  return (
    <>
      {selectedRow && opened && (
        <Dialog
          open={opened}
          onClose={handleClose}
          aria-labelledby="vulnerability-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="vulnerability-dialog-title"
            sx={{
              backgroundColor:
                selectedRow.status === Status.FIXED ||
                selectedRow.status === Status.ACCEPTED
                  ? green[500]
                  : getSeverityColor(selectedRow.severity),
              color: 'white',
              fontSize: '1.2rem', // Increase font size of the title
              mb: 2 // Add margin bottom between title and input label
            }}
          >
            <Typography variant="h2">{selectedRow.title}</Typography>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={3} sx={{ pt: 2 }}>
              <Grid item xs={12} md={3}>
                <Typography variant="h6">Status</Typography>
                <ColoredEnumSelector<Status, Vulnerability>
                  id="vulnerability-dialog-status"
                  property="status"
                  EnumValues={Status}
                  getColor={getStatusColor}
                  entity={selectedRow}
                  reloadReport={reloadReport}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6">Severity</Typography>
                <ColoredEnumSelector<Severity, Vulnerability>
                  id="vulnerability-dialog-severity"
                  property="severity"
                  EnumValues={Severity}
                  getColor={getSeverityColor}
                  entity={selectedRow}
                  reloadReport={reloadReport}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6"> Severity by RedRays</Typography>
                <ColoredEnumSelector<Severity, Vulnerability>
                  id="vulnerability-dialog-redrays-severity"
                  property="redraysSeverity"
                  readonly
                  EnumValues={Severity}
                  getColor={getSeverityColor}
                  entity={selectedRow}
                  reloadReport={reloadReport}
                  style={{ pointerEvents: 'none' }} // Disable user interaction
                />
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Typography variant="h6">Severity by SAP</Typography>

                <ColoredEnumSelector<Severity, Vulnerability>
                  id="vulnerability-dialog-sap-severity"
                  property="sapSeverity"
                  readonly
                  EnumValues={Severity}
                  getColor={getSeverityColor}
                  entity={selectedRow}
                  reloadReport={reloadReport}
                  style={{ pointerEvents: 'none' }} // Disable user interaction
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="vulnerability-dialog-createdAt"
                  label="Found At"
                  value={formatDateTime(new Date(selectedRow.createdAt))}
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  id="vulnerability-dialog-host"
                  label="Host"
                  value={selectedRow.ip}
                  multiline
                  fullWidth
                />
              </Grid>

              {selectedRow.instance !== -1 && (
                <Grid item xs={12} sm={3} md={2}>
                  <TextField
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    id="vulnerability-dialog-instance"
                    label="Instance"
                    value={
                      selectedRow.instance >= 0 && selectedRow.instance <= 9
                        ? '0'.concat(String(selectedRow.instance))
                        : selectedRow.instance
                    }
                    multiline
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={3} md={2}>
                <TextField
                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                  id="vulnerability-dialog-port"
                  label="Port"
                  value={selectedRow.port}
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="vulnerability-dialog-service"
                  label="Service"
                  value={selectedRow.serviceTemplateName}
                  multiline
                  fullWidth
                />
              </Grid>

              {selectedRow.path && (
                <Grid item xs={12}>
                  <TextField
                    id="vulnerability-dialog-path"
                    label="Path"
                    value={selectedRow.path}
                    multiline
                    fullWidth
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  id="vulnerability-dialog-description"
                  label="Description"
                  value={combineValues(
                    selectedRow.threatTypeDescription,
                    selectedRow.description
                  )}
                  multiline
                  fullWidth
                />
              </Grid>
              {(selectedRow.threatTypeImpact || selectedRow.impact) && (
                <Grid item xs={12}>
                  <TextField
                    id="vulnerability-dialog-impact"
                    label="Impact"
                    value={combineValues(
                      selectedRow.threatTypeImpact,
                      selectedRow.impact
                    )}
                    multiline
                    fullWidth
                  />
                </Grid>
              )}

              {(selectedRow.solution || selectedRow.threatTypeSolution) && (
                <Grid item xs={12}>
                  <TextField
                    id="vulnerability-dialog-solution"
                    label="Solution"
                    value={combineValues(
                      selectedRow.solution,
                      selectedRow.threatTypeSolution
                    )}
                    multiline
                    fullWidth
                  />
                </Grid>
              )}

              {selectedRow.threatTypeReferences.length > 0 && (
                <Grid item xs={12}>
                  <Stack>
                    <Typography variant="h6" component="div">
                      References:
                    </Typography>
                    {selectedRow.threatTypeReferences.map((reference) => (
                      <Link
                        target="_blank"
                        noWrap
                        href={reference}
                        key={reference}
                      >
                        {reference}
                      </Link>
                    ))}
                    {selectedRow.sapNotes.map((note) => (
                      <Link
                        noWrap
                        target="_blank"
                        href={`https://launchpad.support.sap.com/#/notes/${note}`}
                        key={note}
                      >
                        SAP Note: {note}
                      </Link>
                    ))}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                backgroundColor: green[500],
                display:
                  selectedRow.serviceTemplateName.includes('HANA') ||
                  selectedRow.serviceTemplateName.includes('P4')
                    ? 'none'
                    : ''
              }}
              startIcon={<GetAppIcon />}
              variant="contained"
              onClick={() => downloadRequestResponse(selectedRow.id)}
            >
              Request Log
            </Button>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default VulnerabilityDialog;
