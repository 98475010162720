import fetchIntercept from 'fetch-intercept';

const registerAuthInterceptor = (): (() => void) => {
  return fetchIntercept.register({
    request(url, config) {
      // Modify the url or config here
      const token = window.localStorage.getItem('token');
      const enhancedConfig = { ...config };
      if (url.startsWith('/api/')) {
        enhancedConfig.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        };
      }
      return [url, enhancedConfig];
    },

    requestError(error) {
      // Called when an error occurred during another 'request' interceptor call
      return Promise.reject(error);
    },

    response(response) {
      // Modify the response object
      return response;
    },

    responseError(error) {
      // Handle an fetch error
      return Promise.reject(error);
    }
  });
};

export default registerAuthInterceptor;
