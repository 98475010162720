import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  AlertColor,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import AccordionDetails from '@mui/material/AccordionDetails';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import toast from 'react-hot-toast';
import Vulnerabilities from './Vulnerabilities';
import RenameDialog from '../../components/networkselector/RenameDialog';
import ErrorsDialog from '../../components/ErrorsDialog';
import StatusesWidget from './widgets/StatusesWidget';
import SeveritiesWidget from './widgets/SeveritiesWidget';
import { NetworkMetadata } from '../../api/network/treeApi';
import VANetworkSelector from '../../components/networkselector/VANetworkSelector';
import ProvideNameDialog from '../../components/ProvideNameDialog';
import {
  ReportMetadata,
  Vulnerability
} from '../../api/vulnerabilityAssessment/vulnerabilityApi';
import {
  checkSchedule,
  downloadCSVeport,
  downloadReport,
  exportToJira,
  exportToServiceNow,
  initSchedule,
  loadReport,
  removeSchedule,
  renameReport,
  scan
} from '../../api/vulnerabilityAssessment/vulnerabilityAssessmentService';
import formatDateTime from '../../components/formatDateTime';
import WidgetTemplate, { cardHeaderEnum } from './widgets/WidgetTemplate';
import NetworkComparison from '../../components/networkcomparison/NetworkComparison';
import HotToast from '../../components/notifications/HotToast';
import ReportsDataGrid from './ReportsDataGrid';

interface ISnackbarProps {
  opened: boolean;
  text: string;
  severity: AlertColor;
}

const VulnerabilityAssessment = (): JSX.Element => {
  const hiddenSnackbar: ISnackbarProps = {
    opened: false,
    text: '',
    severity: 'error'
  };
  const [selectedNetworkMetadata, setSelectedNetworkMetadata] = useState<
    NetworkMetadata | undefined
  >(undefined);
  const [selectedReportId, setSelectedReportId] = useState<number | undefined>(
    undefined
  );
  const [selectedReportName, setSelectedReportName] = useState('');
  const [openedReportRenameDialog, setOpenedReportRenameDialog] =
    useState(false);
  const [openedScanNetworkDialog, setOpenedScanNetworkDialog] = useState(false);
  const [openedErrorsDialog, setOpenedErrorsDialog] = useState(false);
  const [vulnerabilities, setVulnerabilities] = useState<Vulnerability[]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [snackbar, setSnackbar] = useState(hiddenSnackbar);

  const [selectedScan, setSelectedScan] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [updateReports, setUpdateReports] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const scanPeriodOptions = [
    { label: '1 day', value: 1 },
    { label: '7 days', value: 7 },
    { label: '14 days', value: 14 },
    { label: '30 days', value: 30 },
    { label: '90 days', value: 90 },
    { label: '180 days', value: 180 },
    { label: '360 days', value: 360 }
  ];
  const closeScheduleDialog = () => {
    setOpenDialog(false);
  };

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const handleRenameReport = (reportId: number, aNewName: string) => {
    renameReport(reportId, aNewName)
      .then((data) => setSelectedReportName(data.name))
      .catch((reason) =>
        notifyError(`Failed to rename with the reason: ${reason.status}`)
      );
  };

  const handleClose = () => {
    setSnackbar(hiddenSnackbar);
  };

  const handleScan = (
    newName: string,
    networkMetadata: NetworkMetadata,
    isFullScan: boolean
  ) => {
    notifySuccess('Network assessment has been started');
    scan(newName, networkMetadata.id, isFullScan)
      .then(() => {
        notifySuccess(
          `${networkMetadata.name} network scheduled for scan. Resulting report is ${newName}`
        );
        setUpdateReports(uuidv4());
      })
      .catch((reason) =>
        notifyError(
          `Failed to scan ${networkMetadata.name} network. Reason: ${reason.status}`
        )
      );
  };

  const handleLoadReport = (reportId: number | undefined) => {
    if (reportId === undefined) {
      setVulnerabilities([]);
      return;
    }
    loadReport(reportId)
      .then((report) => {
        setVulnerabilities(report.vulnerabilities);
        setMessages(report.messages);
      })
      .catch((reason) =>
        notifyError(
          `Failed to load the report with id=${reportId}. Reason: ${reason.status}`
        )
      );
  };
  const [toggleValue, setToggleValue] = useState<string>('Chart');

  const handleChartChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== toggleValue && newAlignment !== null) {
      console.log(newAlignment);
      setToggleValue(newAlignment);
    }
  };

  const handleDownloadReport = (sort: number) => {
    if (selectedReportId === undefined) {
      return;
    }
    downloadReport(selectedReportId, sort).catch((reason) =>
      notifyError(
        `Failed to download the report with id=${selectedReportId}. Reason: ${reason.status}`
      )
    );
  };

  const handleDownloadCSVReport = () => {
    if (selectedReportId === undefined) {
      return;
    }
    downloadCSVeport(selectedReportId).catch((reason) =>
      notifyError(
        `Failed to download the report with id=${selectedReportId}. Reason: ${reason.status}`
      )
    );
  };
  const handleExportToJira = () => {
    if (selectedReportId === undefined) {
      return;
    }

    notifySuccess(`Export to Jira ITSM started`);

    exportToJira(selectedReportId)
      .then(() =>
        notifySuccess(
          `Vulnerabilities have been successfully exported to Jira ITSM`
        )
      )
      .catch((reason) => {
        notifyError(`Failed to export vulnerabilities: ${reason.message}`); // Display error message
      });
  };

  const handleExportToServiceNow = () => {
    if (selectedReportId === undefined) {
      return;
    }
    notifySuccess(`Export to ServiceNow started`);
    exportToServiceNow(selectedReportId)
      .then((r) =>
        notifySuccess(
          `Vulnerabilities have been successfully exported to ServiceNow`
        )
      )
      .catch((reason) =>
        notifyError(`Failed to export vulnerabilities. Reason: ${reason}`)
      );
  };

  // Start Network scheduler part
  enum STATUS_SCHEDULER {
    NOTSCHEDULED,
    ISSCHEDULED
  }

  const [isAlreadySchedule, setIsAlreadySchedule] = useState(
    STATUS_SCHEDULER.NOTSCHEDULED
  );
  const [scheduleText, setScheduleText] = useState('Schedule');

  const handleCheckSchedule = useCallback(() => {
    if (!selectedNetworkMetadata) {
      return;
    }

    checkSchedule(selectedNetworkMetadata?.id).then((returnTextStatus) => {
      setIsAlreadySchedule(
        Number(returnTextStatus) === STATUS_SCHEDULER.ISSCHEDULED
          ? STATUS_SCHEDULER.ISSCHEDULED
          : STATUS_SCHEDULER.NOTSCHEDULED
      );
      setScheduleText(
        Number(returnTextStatus) === STATUS_SCHEDULER.ISSCHEDULED
          ? 'Re-Schedule'
          : 'Schedule'
      );
    });
  }, [selectedNetworkMetadata]);

  useEffect(() => {
    handleCheckSchedule();
  }, [handleCheckSchedule, selectedNetworkMetadata]);

  const handleInitRemoveSchedule = useCallback(
    (scanPeriod?: number) => {
      if (!selectedNetworkMetadata) {
        return;
      }

      const action =
        isAlreadySchedule === STATUS_SCHEDULER.ISSCHEDULED
          ? removeSchedule
          : initSchedule;
      const newScheduleText =
        isAlreadySchedule === STATUS_SCHEDULER.ISSCHEDULED
          ? 'Schedule'
          : 'Re-Schedule';

      action(selectedNetworkMetadata.id, scanPeriod).then(() => {
        handleCheckSchedule();
        setScheduleText(newScheduleText);
      });
    },
    [selectedNetworkMetadata, isAlreadySchedule, handleCheckSchedule]
  );
  // END Network scheduler part

  const openScheduleDialog = () => {
    if (isAlreadySchedule !== STATUS_SCHEDULER.ISSCHEDULED) {
      setOpenDialog(true);
    } else {
      handleInitRemoveSchedule();
    }
  };
  const [networkComparison, setNetworkComparison] = useState<
    number | undefined
  >(undefined);
  const [comparisonBtnText, setComparisonBtnText] = useState<string>('Compare');
  const handleNetworkComparison = () => {
    if (selectedNetworkMetadata === undefined) {
      return;
    }
    if (networkComparison === undefined) {
      setComparisonBtnText('Vulnerability details');
      setNetworkComparison(selectedNetworkMetadata?.id);
    } else {
      setComparisonBtnText('Compare');
      setNetworkComparison(undefined);
    }
  };

  const handleReportSelectorChange = (
    event: any,
    reportMetadata: ReportMetadata | null
  ) => {
    if (reportMetadata !== null) {
      setSelectedReportId(reportMetadata.id);
      setSelectedReportName(reportMetadata.name);
    }
  };

  const handleReportSelectorGridSelect = (
    reportMetadata: ReportMetadata | null
  ) => {
    if (reportMetadata !== null) {
      setSelectedReportId(reportMetadata.id);
      setSelectedReportName(reportMetadata.name);
    }
  };

  useEffect(() => handleLoadReport(selectedReportId), [selectedReportId]);

  const handleOpenReportRenameDialog = () => {
    setOpenedReportRenameDialog(true);
  };

  const scanDialog = (
    <ProvideNameDialog
      opened={openedScanNetworkDialog}
      handleApply={(newName) => {
        if (selectedNetworkMetadata !== undefined) {
          handleScan(newName, selectedNetworkMetadata, false);
        }
        setOpenedScanNetworkDialog(false);
      }}
      handleClose={() => setOpenedScanNetworkDialog(false)}
      autoGenerateName={
        selectedNetworkMetadata?.name?.split('|')[0].concat(' | VA REPORT') ??
        formatDateTime(new Date())
      }
      windowName="Select a name for the Vulnerability Assessment Report"
    />
  );

  const errorsDialog = (
    <ErrorsDialog
      errors={messages}
      opened={openedErrorsDialog}
      handleClose={() => setOpenedErrorsDialog(false)}
    />
  );

  useEffect(() => {
    if (networkComparison !== undefined) setNetworkComparison(undefined);
  }, [selectedNetworkMetadata?.id]);

  return (
    <>
      <title>Vulnerability Assessment | RedRays</title>
      <Grid container sx={{ backgroundColor: '#F8F7F7' }}>
        <Grid
          item
          xs={12}
          lg={5}
          xl={4}
          sx={{ height: 'calc(100vh - 70px)', backgroundColor: '#F8F7F7' }}
        >
          <Box
            sx={{
              height: 'calc(100vh - 70px)',
              backgroundColor: '#F2F2F2',
              ml: 3,
              mr: 1,
              pt: 1
            }}
          >
            <Typography
              sx={{
                width: '90%',
                backgroundColor: '#ffffff',
                fontSize: 'small',
                mb: 1,
                ml: '5%',
                color: '#CACACA',
                textAlign: 'center',
                borderRadius: '4px'
              }}
            >
              Select a network to see the reports.
            </Typography>

            <Accordion
              sx={{
                backgroundColor: '#F2F2F2',
                boxShadow: 'none', // Remove the default box-shadow to get rid of borders
                '&:before': {
                  // This targets the pseudo-element that MUI uses for the default border
                  // Set it to a white line or make it transparent if you want to completely remove it
                  backgroundColor: 'transparent'
                },
                '&.Mui-expanded': {
                  margin: '0 !important' // Remove the margin added when the accordion is expanded
                },
                '& .MuiAccordionSummary-root': {
                  // Add white lines at the top and bottom of the summary part
                  borderTop: '1px solid white',
                  borderBottom: '1px solid white'
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="network-selector-content"
                id="network-selector-header"
              >
                <Typography>
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    Load
                  </Box>{' '}
                  Network
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  height: 'calc(100vh - 236px)',
                  backgroundColor: '#ffffff'
                }}
              >
                <VANetworkSelector
                  onChange={(vaNetworkMetadata) => {
                    handleCheckSchedule();
                    setSelectedNetworkMetadata(vaNetworkMetadata);
                    setSelectedReportId(undefined);
                    setSelectedReportName('');
                  }}
                  selectedId={selectedNetworkMetadata?.id}
                  hasDefaultFilter
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>

        <Grid item xs={12} lg={7} xl={8} sx={{ pl: 2, pr: 2 }}>
          <Box
            sx={{
              height: 'calc(100vh - 70px)',
              backgroundColor: '#F2F2F2',
              mr: 1,
              pt: 1
            }}
          >
            {selectedNetworkMetadata !== undefined && (
              <Grid
                container
                sx={{
                  backgroundColor: '#F2F2F2'
                }}
              >
                <Grid
                  xs={12}
                  sx={{
                    backgroundColor: '#ffffff',
                    p: 1,
                    m: 1,
                    mt: 0,
                    borderRadius: 1,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={1}
                    sx={{ alignItems: 'center' }}
                  >
                    {selectedReportId !== undefined && (
                      <Button
                        variant="outlined"
                        color="warning"
                        size="small"
                        onClick={() => setSelectedReportId(undefined)}
                      >
                        Back to reports
                      </Button>
                    )}
                    {selectedReportId === undefined && (
                      <Button
                        startIcon={<CenterFocusWeakIcon />}
                        variant="contained"
                        color="success"
                        size="small"
                        disabled={selectedNetworkMetadata === undefined}
                        onClick={() => {
                          setSelectedScan('true');
                          setUpdateReports(uuidv4());
                          if (selectedNetworkMetadata !== undefined) {
                            handleScan(
                              selectedNetworkMetadata?.name
                                ?.split('|')[0]
                                ?.concat(' | VA REPORT') ??
                                formatDateTime(new Date()),
                              selectedNetworkMetadata,
                              true
                            );
                          }
                        }}
                      >
                        Run Scan
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      color={isAlreadySchedule ? 'error' : 'success'}
                      size="small"
                      startIcon={<EventIcon />}
                      disabled={selectedNetworkMetadata === undefined}
                      onClick={openScheduleDialog}
                    >
                      {scheduleText}
                    </Button>

                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      startIcon={<CompareArrowsIcon />}
                      disabled={selectedNetworkMetadata === undefined}
                      onClick={handleNetworkComparison}
                    >
                      {comparisonBtnText}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleOpenReportRenameDialog}
                      color="success"
                      size="small"
                      startIcon={<EditIcon />}
                      disabled={selectedReportName === ''}
                    >
                      Rename
                    </Button>

                    {selectedReportId !== undefined && (
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 180,
                          mt: 0,
                          pt: 0
                        }}
                        size="small"
                        disabled={selectedReportName === ''}
                      >
                        {selectedAction === '' && (
                          <InputLabel
                            sx={{ mt: 0, pt: 0 }}
                            id="download-report"
                          >
                            Download Reports
                          </InputLabel>
                        )}
                        <Select
                          labelId="download-report"
                          sx={{ backgroundColor: '#ffffff', mt: 0, pt: 0 }}
                          value={selectedAction}
                          defaultValue="html"
                          size="small"
                          onChange={(event) => {
                            const value = event.target.value as string;
                            setSelectedAction(value);
                          }}
                          disabled={selectedReportName === ''}
                        >
                          <MenuItem
                            value="html_severity"
                            onClick={() => handleDownloadReport(0)}
                          >
                            <Box display="flex" alignItems="center">
                              <FileDownloadIcon />
                              <Box ml={1}>HTML sort by Severity</Box>
                            </Box>
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            value="html_host"
                            onClick={() => handleDownloadReport(1)}
                          >
                            <Box display="flex" alignItems="center">
                              <FileDownloadIcon />
                              <Box ml={1}>HTML sort by Host</Box>
                            </Box>
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            value="csv"
                            onClick={handleDownloadCSVReport}
                          >
                            <Box display="flex" alignItems="center">
                              <FileDownloadIcon />
                              <Box ml={1}>CSV report</Box>
                            </Box>
                          </MenuItem>
                          <Divider />
                          <MenuItem value="jira" onClick={handleExportToJira}>
                            <Box display="flex" alignItems="center">
                              <ShareIcon />
                              <Box ml={1}>Export to Jira</Box>
                            </Box>
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            value="servicenow"
                            onClick={handleExportToServiceNow}
                          >
                            <Box display="flex" alignItems="center">
                              <ShareIcon />
                              <Box ml={1}>Export to ServiceNow</Box>
                            </Box>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </Stack>
                </Grid>
                {selectedReportId &&
                  networkComparison === undefined &&
                  vulnerabilities.length > 0 && (
                    <Grid
                      item
                      spacing={2}
                      xs="auto"
                      direction="column"
                      flex=""
                      justifyContent="flex-start"
                    >
                      <ToggleButtonGroup
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingBottom: 1
                        }}
                        exclusive
                        color="primary"
                        size="medium"
                        defaultValue="Chart"
                        value={toggleValue}
                        onChange={handleChartChange}
                      >
                        {['Chart', 'Text'].map((v) => (
                          <ToggleButton value={v} key={v}>
                            {v}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                      {toggleValue === 'Text' ? (
                        <>
                          <Grid item>
                            <WidgetTemplate
                              cardHeaders={cardHeaderEnum.VULNERABILITIES}
                              vulnerabilities={vulnerabilities}
                            />
                          </Grid>
                          <Grid item>
                            <WidgetTemplate
                              cardHeaders={cardHeaderEnum.STATUS}
                              vulnerabilities={vulnerabilities}
                            />
                          </Grid>
                          <Grid item>
                            <WidgetTemplate
                              cardHeaders={cardHeaderEnum.SEVERITY}
                              vulnerabilities={vulnerabilities}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item>
                            <StatusesWidget
                              vulnerabilities={vulnerabilities}
                              sx={{ height: 225, width: 170, mb: 2, ml: 1 }}
                            />
                          </Grid>{' '}
                          <Grid item>
                            <SeveritiesWidget
                              vulnerabilities={vulnerabilities}
                              sx={{ height: 225, width: 170, mb: 2, ml: 1 }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}

                <Grid item xs sx={{ ml: 1, mr: 1 }}>
                  {networkComparison === undefined &&
                    selectedReportId === undefined && (
                      <ReportsDataGrid
                        onChange={handleReportSelectorGridSelect}
                        networkId={selectedNetworkMetadata.id}
                        uuid={updateReports}
                      />
                    )}
                  {networkComparison === undefined &&
                    selectedReportId !== undefined && (
                      <>
                        <Vulnerabilities
                          rows={vulnerabilities}
                          reloadReport={() =>
                            handleLoadReport(selectedReportId)
                          }
                        />
                      </>
                    )}
                  {networkComparison !== undefined && (
                    <NetworkComparison
                      networkId={selectedNetworkMetadata?.id}
                    />
                  )}

                  {messages.length > 0 && selectedReportId !== undefined && (
                    <Button
                      onClick={() => setOpenedErrorsDialog(true)}
                      variant="contained"
                      color="error"
                      startIcon={<FormatListBulletedIcon />}
                      disabled={messages.length === 0}
                      sx={{ mt: 2, width: '100%' }}
                    >
                      Errors
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}

            {selectedNetworkMetadata === undefined && (
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: 'calc(100vh - 70px)',
                  backgroundColor: '#F2F2F2'
                }} // Full viewport height and background color
              >
                <WifiTetheringOffIcon
                  sx={{ fontSize: 200, color: grey[400] }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: 36,
                    mt: 2,
                    ml: '30%',
                    mr: '30%',
                    color: grey[400],
                    textAlign: 'center'
                  }}
                >
                  Please select a Network from the list
                </Typography>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      {selectedReportId && (
        <RenameDialog
          name={selectedReportName}
          opened={openedReportRenameDialog}
          handleApply={(newName) => {
            handleRenameReport(selectedReportId, newName);
            setOpenedReportRenameDialog(false);
          }}
          handleClose={() => setOpenedReportRenameDialog(false)}
          windowName="Select a new name for the report"
        />
      )}
      {openedScanNetworkDialog && scanDialog}
      {openedErrorsDialog && errorsDialog}
      <Dialog open={openDialog} onClose={closeScheduleDialog}>
        <DialogTitle
          sx={{
            backgroundColor: 'white',
            color: 'black',
            fontSize: '1.2rem', // Increase font size of the title
            mb: 2 // Add margin bottom between title and input label
          }}
        >
          <Typography variant="h2">Periodical Scan</Typography>
          <Divider sx={{ mt: 2 }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ pb: 2 }}>
            Please define a frequency:
          </DialogContentText>
          {scanPeriodOptions.map((option) => (
            <Button
              key={option.value}
              onClick={() => {
                handleInitRemoveSchedule(option.value);
                closeScheduleDialog();
              }}
            >
              {option.label}
            </Button>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeScheduleDialog}>Cancel</Button>
        </DialogActions>
        <HotToast />
      </Dialog>
    </>
  );
};
export default VulnerabilityAssessment;
