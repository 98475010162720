import { checkOk } from '../../../api/common';

/* eslint-disable import/prefer-default-export */
export function updateValueByKey(key: string, value: string) {
  return fetch(`/api/v1/settings/${key}`, {
    method: 'POST',
    body: value
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function getValueByKey(key: string) {
  return fetch(`/api/v1/settings/${key}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      return response.text();
    });
}
export function getAllSettings() {
  return fetch(`/api/v1/settings`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      return response.json();
    });
}
export function sendTestEmail() {
  return fetch(`/api/v1/settings/testemail`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      return response.text();
    });
}
