import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import SapNotesPossibleAreNotInstalled from '../../api/sapnotes/serviceApi';
import formatDateTime from '../formatDateTime';

interface Column {
  id:
    | 'sapHost'
    | 'sapNote'
    | 'versionOfSapNote'
    | 'componentName'
    | 'currentVersion'
    | 'release'
    | 'versionFrom'
    | 'versionTo'
    | 'title'
    | 'createdAt';
  label: string;
  minWidth?: number;
  align?: 'right' | 'center';
  format?: (value: number) => string;
}

interface SapNotesTableProps {
  rows: SapNotesPossibleAreNotInstalled[]; // Assuming SapNotesPossibleAreNotInstalled is the type of your data
}

const columns: Column[] = [
  { id: 'title', label: 'Title' },
  { id: 'sapHost', label: 'Host' },
  { id: 'sapNote', label: 'SAP Note' },
  {
    id: 'componentName',
    label: 'Component'
  }
];

export default function SapNotesTable({ rows }: SapNotesTableProps) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [sortColumn, setSortColumn] = React.useState<
    keyof SapNotesPossibleAreNotInstalled | null
  >(null);
  const [sortDirection, setSortDirection] = React.useState<
    'asc' | 'desc' | null
  >(null);

  const handleSort = (columnId: keyof SapNotesPossibleAreNotInstalled) => {
    console.log(columnId);
    if (columnId === sortColumn) {
      // If clicking on the currently sorted column, reverse the sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If clicking on a new column, set the new column as the sort column in ascending order
      setSortColumn(columnId);
      setSortDirection('asc');
    }
  };

  // Sort the rows based on the current sort column and direction
  const sortedRows = React.useMemo(() => {
    if (!sortColumn || !sortDirection) {
      return rows;
    }

    return rows.slice().sort((a, b) => {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];
      let ret = 0;
      if (sortDirection === 'asc') {
        if (aValue < bValue) {
          ret = -1;
        } else if (aValue > bValue) {
          ret = 1;
        } else {
          ret = 0;
        }
      }
      if (sortDirection === 'desc') {
        if (aValue > bValue) {
          ret = -1;
        } else if (aValue < bValue) {
          ret = 1;
        } else {
          ret = 0;
        }
      }
      return ret;
    });
  }, [rows, sortColumn, sortDirection]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer sx={{ height: 'calc(100vh - 154px)' }}>
        <Table aria-label="sticky table">
          <TableHead>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ top: 57, minWidth: column.minWidth }}
                onClick={() => handleSort(column.id)}
                className={column.id === sortColumn ? 'sorted' : ''}
              >
                <Box display="flex" alignItems="center">
                  <Typography>{column.label}</Typography>
                  {column.id === sortColumn && (
                    <span
                      className={`sort-icon ${
                        sortDirection === 'asc' ? 'asc' : 'desc'
                      }`}
                    />
                  )}
                </Box>
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={String(row.id)}
                    style={{ verticalAlign: 'top' }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'componentName' && (
                            <Typography variant="body2">
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </Typography>
                          )}

                          {column.id === 'title' && (
                            <>
                              <Typography variant="body2">
                                {typeof value === 'string' &&
                                  value.replace(`${row.sapNote} - `, '')}
                              </Typography>
                              <Typography
                                variant="body2"
                                style={{
                                  marginTop: '2px',
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontSize: 'small'
                                }}
                              >
                                Last scan:{' '}
                                {formatDateTime(new Date(row.createdAt))}
                              </Typography>
                            </>
                          )}
                          {column.id === 'sapNote' && (
                            <>
                              <Typography
                                variant="body2"
                                style={{ marginBottom: '2px' }}
                              >
                                {row.sapNote}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  marginBottom: '4px',
                                  marginTop: '2px',
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontSize: 'small'
                                }}
                              >
                                Note Version: {row.versionOfSapNote}
                              </Typography>
                            </>
                          )}
                          {column.id === 'componentName' && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  marginTop: '2px',
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontSize: 'small'
                                }}
                              >
                                Release: {row.release}
                              </Typography>
                              <Typography
                                variant="h6"
                                style={{
                                  marginTop: '2px',
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontSize: 'small'
                                }}
                              >
                                Version: {row.currentVersion.slice(-4)}
                              </Typography>
                            </div>
                          )}
                          {column.id === 'sapHost' && (
                            <Typography variant="body2">
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </Typography>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
