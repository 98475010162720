import ScanProperties from './ScanProperties';
import Check from './Check';
import { checkOk } from '../../api/common';

export function scan(componentVersionsScanProperties: ScanProperties): void {
  fetch(`/api/v1/component-versions/reports`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(componentVersionsScanProperties)
  })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response;
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(
        `SAP scanned successfully. Resulted Profile Parameters report id is ${data}`
      );
    })
    .catch((reason) =>
      console.log(`Failed to scan SAP. Reason: ${reason.status}`)
    );
}

export function renameReport(
  reportId: number,
  aNewName: string,
  handleSuccess: (aNewName: string) => void
): void {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ newName: aNewName })
  };
  fetch(`/api/v1/component-versions/reports/${reportId}`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response;
    })
    .then((response) => response.json())
    .then((data) => {
      handleSuccess(data.name);
      console.log(`Report renamed successfully to ${data.name}`);
    })
    .catch((reason) =>
      console.log(`Failed to rename with the reason: ${reason.status}`)
    );
}

export function loadReport(
  reportId: number,
  handleSuccess: (checks: Check[]) => void
): void {
  fetch(`/api/v1/component-versions/reports/${reportId}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response;
    })
    .then((response) => response.json())
    .then((report) => {
      handleSuccess(report.checks);
      console.log(`Report loaded successfully with id=${reportId}`);
    })
    .catch((reason) =>
      console.log(
        `Failed to load the report with id=${reportId}. Reason: ${reason.status}`
      )
    );
}

export function downloadReport(reportId: number): Promise<void> {
  return fetch(`/api/v1/component-versions/reports/${reportId}/download`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      const filename = response.headers
        ?.get('Content-Disposition')
        ?.split('filename=')[1]
        .replaceAll('"', '');
      if (filename === undefined) {
        return;
      }
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
}

export function downloadCSVReport(reportId: number): Promise<void> {
  return fetch(`/api/v1/component-versions/reports/${reportId}/downloadCSV`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => {
      const filename = response.headers
        ?.get('Content-Disposition')
        ?.split('filename=')[1]
        .replaceAll('"', '');
      if (filename === undefined) {
        return;
      }
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
}

export function exportToJira(reportId: number | undefined) {
  return fetch(`/api/v1/component-versions/reports/${reportId}/exportITSM`, {
    method: 'GET'
  }).then(async (response) => {
    // Enhanced for error handling
    if (!response.ok) {
      const errorText = await response.text(); // Read error message
      throw new Error(`HTTP Error ${response.status}: ${errorText}`);
    }
    const successMessage = await response.text(); // Extract success message
    console.log(successMessage);
  });
}
