import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import InputIcon from '@mui/icons-material/Input';
import { AppBarProps } from '@mui/material/AppBar/AppBar';
import Logo from './Logo';
import useAuth from './auth/useAuth';

const DashboardNavbar = ({ ...rest }: Partial<AppBarProps>): JSX.Element => {
  const { signOut } = useAuth();

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'white',
        pl: 4
      }}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton color="error" size="large" onClick={signOut}>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
