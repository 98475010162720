import * as React from 'react';
import { useState } from 'react';
import Keycloak from 'keycloak-js';

interface AuthContextType {
  authenticated: boolean;
  signIn: (redirectUri: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType>({
  authenticated: false,
  signIn: () =>
    new Promise(() => {
      // do nothing
    }),
  signOut: () =>
    new Promise(() => {
      // do nothing
    })
});

function useAuth(): AuthContextType {
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const [keycloak] = useState(new Keycloak('/keycloak.json'));

  const signIn: (redirectUri: string) => Promise<void> = (
    redirectUri: string
  ) => {
    return keycloak
      .init({ onLoad: 'login-required', redirectUri })
      .then((isAuthenticated) => {
        setAuthenticated(isAuthenticated);
        // todo pass token directly to fetch, using localStorage is less secure
        window.localStorage.setItem('token', keycloak.token ?? '');
      });
  };

  const signOut: () => Promise<void> = () => keycloak.logout();

  return { authenticated, signIn, signOut };
}

export function AuthProvider({
  children
}: {
  children: React.ReactNode;
}): JSX.Element {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer(): AuthContextType {
  return React.useContext(AuthContext);
}
