import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import { AuthProvider } from './components/auth/useAuth';
import registerAuthInterceptor from './components/auth/authFetchInterceptor';

const App = (): JSX.Element => {
  const content = useRoutes(routes);

  useEffect(() => registerAuthInterceptor(), []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AuthProvider>{content}</AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
