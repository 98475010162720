import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { v4 as uuid } from 'uuid';
import { BarChart } from 'echarts/charts';
import { BarSeriesOption } from 'echarts';

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  TitleComponent
]);

interface EChartsChartProps {
  data: {
    xData: string[];
    yData: number[];
  };
  chartText: string;
  chartSubtitle: string;
  color: string;
}

const EChartsChart: React.FC<EChartsChartProps> = ({
  data,
  chartText,
  chartSubtitle,
  color
}) => {
  const uniqueAddress = uuid();

  useEffect(() => {
    const chartDom = document.getElementById(`echarts-chart-${uniqueAddress}`)!;
    const myChart = echarts.init(chartDom);

    const seriesData = data.xData.map((date, index) => ({
      name: date,
      value: data.yData[index] || 0
    }));

    const option: echarts.ComposeOption<BarSeriesOption> = {
      title: {
        text: chartText,
        subtext: chartSubtitle,
        textStyle: {
          color: '#9f9f9f', // Change the color here
          fontSize: 14 // Change the font size here
        },
        left: 'center' // Move the text to the center
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: data.xData,
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Data',
          type: 'bar',
          barWidth: '60%',
          data: seriesData,
          itemStyle: {
            color
          }
        }
      ]
    };

    myChart.setOption(option);

    // Clean up
    return () => {
      myChart.dispose();
    };
  }, []);

  return (
    <Box
      padding={2}
      sx={{ backgroundColor: '#ffffff' }}
      id={`echarts-chart-${uniqueAddress}`}
      style={{ width: '100%', height: '400px' }}
    />
  );
};

export default EChartsChart;
