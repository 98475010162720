// todo how to configure eslint for enum properly?
// eslint-disable-next-line import/prefer-default-export
import { green, grey, red } from '@mui/material/colors';

export enum Status {
  OUTDATED = 'OUTDATED',
  UP_TO_DATE = 'UP_TO_DATE'
}

export const ORDERED_COMPONENT_VERSIONS_STATUSES: Status[] = [
  Status.OUTDATED,
  Status.UP_TO_DATE
];

export const getStatusColor = (status: Status) => {
  switch (status) {
    case Status.OUTDATED:
      return red[500];
    case Status.UP_TO_DATE:
      return green[300];
    default:
      return grey[500];
  }
};
