import React, { useEffect, useState } from 'react';
import { green } from '@mui/material/colors';
import { Grid, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox'; // Add this import
import HotToast from '../../notifications/HotToast';
import { MiscInterface } from './MiscInterface';
import { getMisc, saveMisc } from './MiscService';

const Misc = () => {
  const [miscConfiguration, setMiscConfiguration] = useState<MiscInterface>({
    sslScan: false // Set the initial value of sslScan to false
  });
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  useEffect(() => {
    // Call the function to get the Misc configuration and initialize the state
    getMisc()
      .then((config) => {
        setMiscConfiguration(config);
        console.log(config.sslScan);
      })
      .catch((error) => {
        console.error('Error fetching Misc configuration:', error);
      });
  }, []); // Empty dependency array to run the effect only once on mount

  function handleSave() {
    if (miscConfiguration?.sslScan !== undefined) {
      // Call the function to update the Misc configuration on the server
      saveMisc(miscConfiguration)
        .then(() => {
          notifySuccess('Misc configuration successfully saved');
        })
        .catch((error) => {
          notifyError('Error updating Misc configuration');
        });
    }
  }

  return (
    <Grid container>
      {/* Left side of the UI (Form) */}
      <Grid item xs={12} md={6}>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Configuration Settings:
            </Typography>
          </Grid>

          {/* Checkbox */}
          <Grid item xs={12} container alignItems="center">
            <Checkbox
              checked={Boolean(miscConfiguration.sslScan)}
              onChange={(e) =>
                setMiscConfiguration({
                  ...miscConfiguration,
                  sslScan: e.target.checked
                })
              }
              color="primary"
            />
            <span>Perform SSL Configuration Check</span>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {/* Save Button */}
          <Button
            sx={{ backgroundColor: green[500], mt: 5 }}
            variant="contained"
            size="medium"
            onClick={() => handleSave()}
            fullWidth
          >
            Save configuration
          </Button>
        </Grid>
      </Grid>

      {/* Right side of the UI (Empty space or other content can be added here) */}
      <Grid item xs={12} md={6}>
        {/* Add any additional content or components you want to display on the right side */}
      </Grid>
      <HotToast />
    </Grid>
  );
};

export default Misc;
