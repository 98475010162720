import React from 'react';
import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import IconButton from '@mui/material/IconButton';

interface BtpDashboardCardProps {
  cardHeader: string;
  cardTitle: string;
  cardText: string;
  subaccountStatus?: string;
  subaccountUsedForProduction?: string;
  showUsersUi: any;
}

const SimpleCard: React.FC<BtpDashboardCardProps> = ({
  cardHeader,
  cardTitle,
  cardText,
  subaccountStatus,
  subaccountUsedForProduction,
  showUsersUi
}) => {
  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            gap: '4px',
            paddingRight: '4px',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              mt: 0.5,
              pt: 1
            }}
            color="text.secondary"
          >
            {cardHeader}
          </Typography>
          {subaccountStatus && (
            <Tooltip title={`Status: ${subaccountStatus}`}>
              <CircleIcon
                fontSize="small"
                sx={{ mt: 0, pt: 1 }}
                color={subaccountStatus === 'OK' ? 'success' : 'warning'}
              />
            </Tooltip>
          )}
          {subaccountUsedForProduction && (
            <Tooltip
              title={
                subaccountUsedForProduction === 'NOT_USED_FOR_PRODUCTION'
                  ? 'Not Used for Production'
                  : 'Used for Production'
              }
            >
              <CircleIcon
                fontSize="small"
                sx={{ mt: 0, pt: 1 }}
                color={
                  subaccountUsedForProduction === 'NOT_USED_FOR_PRODUCTION'
                    ? 'error'
                    : 'success'
                }
              />
            </Tooltip>
          )}
          <IconButton
            sx={{ backgroundColor: '#f1f1f1' }}
            onClick={() => showUsersUi(cardText)}
          >
            <PeopleAltIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>

        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {cardTitle}
        </Typography>
        <Typography variant="body2">{'GUID: '.concat(cardText)}</Typography>
      </CardContent>
    </Card>
  );
};

export default SimpleCard;
