import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid
} from '@mui/material';
import toast from 'react-hot-toast';
import {
  getBtpMonitoringSettings,
  updateBtpMonitoringSettings
} from '../../../api/sapBtp/sapBtpService';
import {
  BtpMonitoringSettingsRequest,
  BtpMonitoringSettingsResponse
} from '../../../api/sapBtp/sapBtpApi';
import HotToast from '../../notifications/HotToast';

const SettingsUi: React.FC = () => {
  const [btpMonitoringSettings, setBtpMonitoringSettings] =
    useState<BtpMonitoringSettingsResponse>();
  const [showPath, setShowPath] = useState<boolean | null>(null);
  const [slackWebhookUrl, setSlackWebhookUrl] = useState<string>('');
  const notifySuccess = (message: string) => toast.success(message);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getBtpMonitoringSettings();
        setBtpMonitoringSettings(settings);
        setShowPath(settings.showPath === 'true');
        setSlackWebhookUrl(settings.slackWebhookUrl);
      } catch (error) {
        console.error('Error fetching BTP monitoring settings:', error);
      }
    };

    fetchSettings();
  }, []);

  const handleSave = async () => {
    const settings: BtpMonitoringSettingsRequest = {
      showPath: showPath !== null ? showPath.toString() : '',
      slackWebhookUrl: slackWebhookUrl !== '' ? slackWebhookUrl : ''
    };

    try {
      await updateBtpMonitoringSettings(settings);
      if (settings.showPath !== '') {
        setBtpMonitoringSettings(settings);
        notifySuccess('Settings has been updated');
      }
    } catch (error) {
      console.error('Error updating BTP monitoring settings:', error);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        pt: 1,
        backgroundColor: 'rgba(159,159,159,0.05)'
      }}
    >
      <Typography
        sx={{
          width: '90%',
          backgroundColor: '#ffffff',
          fontSize: 'small',
          mb: 1,
          ml: '1',
          color: '#CACACA',
          textAlign: 'center',
          borderRadius: '4px'
        }}
      >
        Configure SAP BTP Monitoring Module Below
      </Typography>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          ml: 2,
          mr: 2,
          pr: 2
        }}
      >
        <Grid container spacing={1} alignItems="center" sx={{ m: 1, p: 1 }}>
          <Grid item xs={2} sx={{ mt: 1, pt: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="show-path-label">Show Path Column</InputLabel>
              <Select
                labelId="show-path-label"
                id="demo-simple-select"
                value={showPath !== null ? showPath.toString() : ''}
                onChange={(e) => setShowPath(e.target.value === 'true')}
                fullWidth
                label="Show Path Column"
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Slack Webhook URL"
              value={slackWebhookUrl}
              onChange={(e) => setSlackWebhookUrl(e.target.value)}
              fullWidth
              margin="normal"
              placeholder="https://hooks.slack.com/services/..."
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="success"
              onClick={handleSave}
              sx={{ mr: 1 }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
      <HotToast />
    </Box>
  );
};

export default SettingsUi;
