import { CustomCookies } from './CustomCookies';
import { checkOk } from '../../../api/common';

export function saveCustomCookies(customCookie: CustomCookies) {
  return fetch(`/api/v1/settings/custom/cookies/save`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(customCookie)
  })
    .then(checkOk)
    .then((response) => {
      return response.text();
    });
}
export function getCustomCookies(ip: string): Promise<CustomCookies> {
  return fetch(`/api/v1/settings/custom/cookies/get/${ip}`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => {
      return response.json();
    });
}
export function deleteCustomCookies(ip: string) {
  return fetch(`/api/v1/settings/custom/cookies/delete/${ip}`, {
    method: 'DELETE'
  })
    .then(checkOk)
    .then((response) => {
      return response.text();
    });
}
