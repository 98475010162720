import React, { useEffect, useState } from 'react';
import { green, grey, orange } from '@mui/material/colors';
import { Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import { SettingsKeys } from '../SettingsKeys';
import {
  getAllSettings,
  sendTestEmail,
  updateValueByKey
} from './EmailingService';
import { Settings } from '../Settings';
import HotToast from '../../notifications/HotToast';

const Emailing = () => {
  const [reportSendingFromEmail, setReportSendingFromEmail] =
    useState<string>('');
  const [reportSendingToEmail, setReportSendingToEmail] = useState<string>('');
  const [smtpHost, setSmtpHost] = useState<string>('');
  const [smtpPort, setSmtpPort] = useState<string>('');
  const [fromUserPassword, setFromUserPassword] = useState<string>('');
  const [mailTransportProtocol, setMailTransportProtocol] =
    useState<string>('smtp');
  const [mailSmtpAuth, setMailSmtpAuth] = useState<string>('true');
  const [mailSmtpStarttlsEnable, setMailSmtpStarttlsEnable] =
    useState<string>('true');
  const [mailDebug, setMailDebug] = useState<string>('false');
  const [settings, setSettings] = React.useState<Settings[]>();
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  function parseStream(s: Settings[], key: SettingsKeys) {
    if (s !== undefined) {
      return s?.find((x) => x.parameterKey === key)?.parameterValue;
    }

    return '';
  }

  const [updated, setUpdated] = useState<number>(0);
  function updateValues() {
    getAllSettings().then((responseSettings) => {
      setReportSendingFromEmail(
        parseStream(responseSettings, SettingsKeys.REPORT_SENDING_FROM_EMAIL) ??
          ''
      );
      setReportSendingToEmail(
        parseStream(responseSettings, SettingsKeys.REPORT_SENDING_TO_EMAIL) ??
          ''
      );
      setSmtpHost(parseStream(responseSettings, SettingsKeys.SMTP_HOST) ?? '');
      setSmtpPort(parseStream(responseSettings, SettingsKeys.SMTP_PORT) ?? '');
      setFromUserPassword(
        parseStream(responseSettings, SettingsKeys.FROM_USER_PASSWORD) ?? ''
      );
      setMailTransportProtocol('smtp');
      setMailSmtpAuth('true');
      setMailSmtpStarttlsEnable('true');
      setMailDebug('false');
      console.log('updated', responseSettings);
      setSettings(responseSettings);
      setUpdated(1);
    });
  }
  useEffect(() => {
    if (updated === 0) {
      updateValues();
    }
  }, []);

  function handleSaveAll(testEmail: boolean) {
    updateValueByKey(
      SettingsKeys.REPORT_SENDING_FROM_EMAIL,
      reportSendingFromEmail
    );
    updateValueByKey(
      SettingsKeys.REPORT_SENDING_TO_EMAIL,
      reportSendingToEmail
    );
    updateValueByKey(SettingsKeys.SMTP_HOST, smtpHost);
    updateValueByKey(SettingsKeys.SMTP_PORT, smtpPort);
    updateValueByKey(SettingsKeys.FROM_USER_PASSWORD, fromUserPassword);
    updateValueByKey(
      SettingsKeys.MAIL_TRANSPORT_PROTOCOL,
      mailTransportProtocol
    );
    updateValueByKey(SettingsKeys.MAIL_SMTP_AUTH, mailSmtpAuth);
    updateValueByKey(
      SettingsKeys.MAIL_SMTP_STARTTLS_ENABLE,
      mailSmtpStarttlsEnable
    );
    updateValueByKey(SettingsKeys.MAIL_DEBUG, mailDebug);

    notifySuccess('Configuration Saved');
    if (testEmail) {
      sendTestEmail()
        .then((resp) => {
          if (resp === 'OK') {
            notifySuccess('Email sent successfully');
          }
          if (resp !== 'OK') {
            notifyError(
              'Something wrong, please check the configuration '.concat(resp)
            );
          }
        })
        .catch((error) => {
          notifyError(
            'Something wrong, please check the configuration '.concat(
              error.value
            )
          );
        });
    }
  }

  return (
    <Grid container alignItems="left" justifyContent="left">
      <Grid
        container
        spacing={3}
        alignItems="left"
        justifyContent="left"
        sx={{ pb: 2 }}
      >
        <Grid item lg={3} sm={6} xl={2} xs={12}>
          <Typography>Email Address:</Typography>
          <TextField
            sx={{ width: '100%' }}
            value={reportSendingFromEmail}
            onChange={(event) => setReportSendingFromEmail(event.target.value)}
            variant="outlined"
            placeholder="redrays@company.com"
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={2} xs={12}>
          <Typography>Password:</Typography>
          <TextField
            type="password"
            sx={{ width: '100%' }}
            value={fromUserPassword}
            onChange={(event) => setFromUserPassword(event.target.value)}
            variant="outlined"
            placeholder="Password for redrays@company.com"
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        alignItems="left"
        justifyContent="left"
        sx={{ pb: 2 }}
      >
        <Grid item lg={3} sm={6} xl={4} xs={12}>
          <Typography>Mailing Email Address:</Typography>
          <TextField
            variant="outlined"
            placeholder="security@company.com"
            sx={{ width: '100%' }}
            value={reportSendingToEmail}
            onChange={(event) => setReportSendingToEmail(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        alignItems="left"
        justifyContent="left"
        sx={{ pb: 2 }}
      >
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <Typography>SMTP host:</Typography>
          <TextField
            sx={{ width: '100%' }}
            value={smtpHost}
            onChange={(event) => setSmtpHost(event.target.value)}
            variant="outlined"
            placeholder="smtp.company.com"
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={1} xs={12}>
          <Typography>SMTP port:</Typography>
          <TextField
            variant="outlined"
            placeholder="25"
            sx={{ width: '100%' }}
            value={smtpPort}
            onChange={(event) => setSmtpPort(event.target.value)}
          />
        </Grid>{' '}
      </Grid>
      <Grid
        container
        spacing={3}
        alignItems="left"
        justifyContent="left"
        sx={{ pb: 2 }}
      >
        <Grid item lg={3} sm={6} xl={2} xs={12}>
          <Typography>Email transport protocol:</Typography>
          <TextField
            variant="outlined"
            placeholder="smtp"
            sx={{ width: '100%' }}
            value={mailTransportProtocol}
            disabled
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={2} xs={12}>
          <Typography>Email auth. enabled:</Typography>
          <TextField
            variant="outlined"
            placeholder="true"
            sx={{ width: '100%' }}
            value={mailSmtpAuth}
            disabled
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        alignItems="left"
        justifyContent="left"
        sx={{ pb: 2 }}
      >
        <Grid item lg={3} sm={6} xl={2} xs={12}>
          <Typography>SMTP STARTTLS Enable:</Typography>
          <TextField
            variant="outlined"
            placeholder="true"
            sx={{ width: '100%' }}
            value={mailSmtpStarttlsEnable}
            disabled
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={2} xs={12}>
          <Typography>SMTP Mail Debug Enable:</Typography>
          <TextField
            variant="outlined"
            placeholder="false"
            sx={{ width: '100%' }}
            value={mailDebug}
            disabled
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        alignItems="left"
        justifyContent="left"
        sx={{ pb: 3 }}
      >
        <Grid item lg={3} sm={6} xl={2} xs={12}>
          <Button
            sx={{ ml: 0, backgroundColor: green[500] }}
            type="submit"
            variant="contained"
            size="medium"
            onClick={() => {
              handleSaveAll(false);
            }}
          >
            Save
          </Button>

          <Button
            sx={{ ml: 2, backgroundColor: grey[500] }}
            type="submit"
            variant="contained"
            size="medium"
            onClick={() => {
              handleSaveAll(true);
            }}
          >
            Send test email
          </Button>
        </Grid>
      </Grid>
      <HotToast />
    </Grid>
  );
};
export default Emailing;
