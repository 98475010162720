import React, { useEffect } from 'react';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import toast from 'react-hot-toast';
import ReportDataGrid from './ReportsDataGrid';
import ScanDialog from './ScanDialog';
import {
  downloadCSVReport,
  downloadReport,
  exportToJira,
  loadReport,
  renameReport,
  scan
} from './Service';
import { ReportMetadata } from './ReportSelector';
import Checks from './Checks';
import Check from './Check';
import StatusesWidget from './StatusesWidget';
import RenameDialog from '../../components/networkselector/RenameDialog';
import HotToast from '../../components/notifications/HotToast';

const ProfileParameters = (): JSX.Element => {
  const [selectedReportId, setSelectedReportId] = React.useState<
    number | undefined
  >(undefined);
  const [selectedReportName, setSelectedReportName] = React.useState('');
  const [openedReportRenameDialog, setOpenedReportRenameDialog] =
    React.useState(false);
  const [openedReportScanDialog, setOpenedReportScanDialog] =
    React.useState(false);
  const [checks, setChecks] = React.useState<Check[]>([]);

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const handleReportSelectorChange = (
    reportMetadata: ReportMetadata | null
  ) => {
    if (reportMetadata !== null) {
      setSelectedReportId(reportMetadata.id);
      setSelectedReportName(reportMetadata.name);
    }
  };

  useEffect(() => {
    if (selectedReportId === undefined) {
      return;
    }
    loadReport(selectedReportId, setChecks);
  }, [selectedReportId]);

  const handleOpenReportRenameDialog = () => {
    setOpenedReportRenameDialog(true);
  };

  const handleDownloadReport = () => {
    if (selectedReportId === undefined) {
      return;
    }
    downloadReport(selectedReportId).catch((reason) =>
      notifyError(
        `Failed to download the report with id=${selectedReportId}. Reason: ${reason.status}`
      )
    );
  };

  const handleDownloadCSVReport = () => {
    if (selectedReportId === undefined) {
      return;
    }
    downloadCSVReport(selectedReportId).catch((reason) =>
      notifyError(
        `Failed to download the report with id=${selectedReportId}. Reason: ${reason.status}`
      )
    );
  };

  const handleExportToJira = () => {
    if (selectedReportId === undefined) {
      return;
    }
    notifySuccess(`Export to Jira has been started`);
    exportToJira(selectedReportId)
      .then(() =>
        notifySuccess(
          `Profile Parameters have been successfully exported to Jira ITSM`
        )
      )
      .catch((reason) => {
        notifyError(`Failed to export vulnerabilities: ${reason.message}`); // Display error message
      });
  };

  return (
    <>
      <title>Profile Parameters | RedRays</title>
      <Grid
        container
        spacing={2}
        sx={{ pl: 2, pt: 1, height: 'calc(100vh - 210px)' }}
      >
        <Grid item container xs={selectedReportId !== undefined ? 10 : 12}>
          <Grid
            item
            xs={12}
            sm
            sx={{
              backgroundColor: '#ffffff',
              pl: 1,
              mb: 1,
              pr: 1,
              borderRadius: 1,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            }}
          >
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
              sx={{
                mb: 1,
                mt: 1,
                alignItems: 'center'
              }}
            >
              {selectedReportId !== undefined && (
                <>
                  <Typography
                    component="span"
                    variant="caption"
                    sx={{ fontSize: '1rem' }}
                  >
                    Report Name:
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ marginLeft: 1, fontWeight: 'bold' }}
                  >
                    {selectedReportName}
                  </Typography>
                </>
              )}
              {selectedReportId !== undefined && (
                <Button
                  variant="outlined"
                  color="warning"
                  size="small"
                  onClick={() => setSelectedReportId(undefined)}
                >
                  Back to reports
                </Button>
              )}
              {!selectedReportId && (
                <Button
                  sx={{ mt: 0 }}
                  onClick={() => setOpenedReportScanDialog(true)}
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={<CenterFocusWeakIcon />}
                >
                  Scan
                </Button>
              )}
              <Button
                sx={{ mt: 0, ml: 1 }}
                onClick={handleOpenReportRenameDialog}
                variant="outlined"
                color="success"
                size="small"
                disabled={selectedReportName === ''}
                startIcon={<EditIcon />}
              >
                Rename
              </Button>
              <Button
                variant="outlined"
                color="success"
                size="small"
                startIcon={<FileDownloadIcon />}
                disabled={selectedReportName === ''}
                onClick={handleDownloadReport}
              >
                HTML report
              </Button>
              <Button
                variant="outlined"
                color="success"
                size="small"
                startIcon={<FileDownloadIcon />}
                disabled={selectedReportName === ''}
                onClick={handleDownloadCSVReport}
              >
                CSV report
              </Button>
              <Button
                variant="outlined"
                color="success"
                size="small"
                startIcon={<ShareIcon />}
                disabled={selectedReportName === ''}
                onClick={handleExportToJira}
              >
                Export to Jira
              </Button>
            </Stack>
            {!selectedReportId && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <ReportDataGrid
                  onChange={handleReportSelectorChange}
                  selectedName={selectedReportName}
                />
              </Box>
            )}
            {selectedReportId && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <Checks
                  rows={checks}
                  reloadReport={() => {
                    // checks are immutable
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        {selectedReportId !== undefined && (
          <Grid item>
            <StatusesWidget
              checks={checks}
              sx={{ height: 225, width: 170, mt: 0 }}
            />
          </Grid>
        )}
      </Grid>
      {selectedReportId && (
        <RenameDialog
          name={selectedReportName}
          opened={openedReportRenameDialog}
          handleApply={(newName) => {
            renameReport(selectedReportId, newName, setSelectedReportName);
            setOpenedReportRenameDialog(false);
          }}
          handleClose={() => setOpenedReportRenameDialog(false)}
          windowName="Select a new name for the report"
        />
      )}
      <ScanDialog
        opened={openedReportScanDialog}
        handleScan={(scanProperties) => {
          scan(scanProperties);
          notifySuccess('Scan has been started');
          setOpenedReportScanDialog(false);
        }}
        handleClose={() => setOpenedReportScanDialog(false)}
      />
      <HotToast />
    </>
  );
};

export default ProfileParameters;
