import * as React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import Link from '@mui/material/Link';
import { blue } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  DialogContentText,
  Divider,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import ScanProperties from './ScanProperties';

const ScanDialog = ({
  opened,
  handleScan,
  handleClose
}: {
  opened: boolean;
  handleScan: (profileParametersScanProperties: ScanProperties) => void;
  handleClose: () => void;
}): JSX.Element => {
  const [profileParametersScanProperties, setProfileParametersScanProperties] =
    React.useState({} as ScanProperties);
  return (
    <>
      {opened && (
        <Dialog
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleScan(profileParametersScanProperties);
            }
          }}
          open={opened}
          onClose={handleClose}
          aria-labelledby="profile-parameters-scan-dialog-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            id="profile-parameters-scan-dialog-title"
            sx={{
              backgroundColor: 'white',
              color: 'black',
              fontSize: '1.2rem', // Increase font size of the title
              mb: 2 // Add margin bottom between title and input label
            }}
          >
            <Typography variant="h2">Profile Parameters Scan</Typography>
            <Divider sx={{ mt: 2 }} />
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              <Typography variant="h6">
                By writing the following parameters, the RedRays Security
                Platform will connect to SAP ABAP or S4/HANA Stack and find
                misconfigured profile parameters.
              </Typography>
            </DialogContentText>
            <Grid container spacing={3} sx={{ pt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  required
                  id="profile-parameters-scan-dialog-ip"
                  label="SAP IP"
                  value={profileParametersScanProperties.ip}
                  onChange={(e) => {
                    setProfileParametersScanProperties({
                      ...profileParametersScanProperties,
                      ip: e.target.value
                    });
                  }}
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="profile-parameters-scan-dialog-sys"
                  label="SAP Instance ID (00, 01, ... 99)"
                  value={profileParametersScanProperties.sys}
                  onChange={(e) => {
                    setProfileParametersScanProperties({
                      ...profileParametersScanProperties,
                      sys: e.target.value
                    });
                  }}
                  inputProps={{
                    pattern: '^[0-9]{2}$'
                  }}
                  error={
                    Boolean(profileParametersScanProperties.sys) &&
                    !/^[0-9]{2}$/.test(profileParametersScanProperties.sys)
                  }
                  helperText={
                    Boolean(profileParametersScanProperties.sys) &&
                    !/^[0-9]{2}$/.test(profileParametersScanProperties.sys)
                      ? 'Must be a two-digit number.'
                      : ''
                  }
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="profile-parameters-scan-dialog-client"
                  label="SAP Client ID (000, 066, ... 999)"
                  value={profileParametersScanProperties.client}
                  onChange={(e) => {
                    setProfileParametersScanProperties({
                      ...profileParametersScanProperties,
                      client: e.target.value
                    });
                  }}
                  inputProps={{
                    pattern: '^[0-9]{3}$'
                  }}
                  error={
                    Boolean(profileParametersScanProperties.client) &&
                    !/^[0-9]{3}$/.test(profileParametersScanProperties.client)
                  }
                  helperText={
                    Boolean(profileParametersScanProperties.client) &&
                    !/^[0-9]{3}$/.test(profileParametersScanProperties.client)
                      ? 'Must be a three-digit number.'
                      : ''
                  }
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="profile-parameters-scan-dialog-user"
                  label="Username"
                  value={profileParametersScanProperties.user}
                  onChange={(e) => {
                    setProfileParametersScanProperties({
                      ...profileParametersScanProperties,
                      user: e.target.value
                    });
                  }}
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="profile-parameters-scan-dialog-password"
                  label="Password"
                  type="password"
                  value={profileParametersScanProperties.password}
                  onChange={(e) => {
                    setProfileParametersScanProperties({
                      ...profileParametersScanProperties,
                      password: e.target.value
                    });
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="profile-parameters-scan-dialog-lang"
                  label="Language Code (EN, DE, ES, ...)"
                  value={profileParametersScanProperties.lang}
                  onChange={(e) => {
                    setProfileParametersScanProperties({
                      ...profileParametersScanProperties,
                      lang: e.target.value
                    });
                  }}
                  inputProps={{
                    pattern: '^[A-Z]{2}$'
                  }}
                  error={
                    Boolean(profileParametersScanProperties.lang) &&
                    !/^[A-Z]{2}$/.test(profileParametersScanProperties.lang)
                  }
                  helperText={
                    Boolean(profileParametersScanProperties.lang) &&
                    !/^[A-Z]{2}$/.test(profileParametersScanProperties.lang)
                      ? 'Must be a two-letters word.'
                      : ''
                  }
                  multiline
                  fullWidth
                />
              </Grid>
              {/*
              <Grid item xs={12}>
                <TextField
                  id="profile-parameters-scan-dialog-saprouter"
                  label="SAP Router String "
                  multiline
                  fullWidth
                />
              </Grid>
              */}
              <Grid item xs={12}>
                <HelpIcon style={{ color: blue[500] }} />
                <Typography variant="h6">
                  1) Information about supported language codes you can find
                  <Link
                    target="_blank"
                    sx={{ pl: 0.5 }}
                    href="https://help.sap.com/doc/saphelp_nw73/7.3.16/en-US/c1/ae563cd2ad4f0ce10000000a11402f/content.htm?no_cache=true"
                    underline="hover"
                    color="success"
                  >
                    here
                  </Link>
                </Typography>
                <Typography variant="h6">
                  2) All fields marked with * are required
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => handleScan(profileParametersScanProperties)}
              autoFocus
              color="success"
            >
              Scan
            </Button>
            <Button variant="outlined" onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ScanDialog;
