import React, { useEffect, useState } from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import { CChart } from '@coreui/react-chartjs';
import {
  getSeverityColor,
  Severity
} from '../../features/vulnerabilities/Severity';

const CHART_COLOR = [
  'rgb(236,161,74)',
  'rgb(11,23,231)',
  'rgb(11,231,224)',
  'rgb(231,11,194)'
];

interface Props {
  networkId: number | undefined;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked'
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false
  },
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

function printLabel(localData: string[]) {
  let networkCount = Math.round(localData.length / 5);
  if (networkCount > 4) {
    networkCount = 4;
  }
  const labelData = [];
  for (let i = 0; i < networkCount; i++) {
    labelData.push(localData[5 * i]);
  }
  return labelData;
}

function drawChart(localData: string[], pos: number) {
  let networkCount = Math.round(localData.length / 5);
  if (networkCount > 4) {
    networkCount = 4;
  }
  const chartData = [];
  for (let i = 0; i < networkCount; i++) {
    chartData.push(Number(localData[5 * i + pos]));
  }
  return chartData;
}

const NetworkComparison: React.FC<Props> = ({ networkId }: Props) => {
  const [chartData, setChartData] = useState<string[]>(['-1']);
  useEffect(() => {
    fetch(
      `/api/v1/vulnerability-assessment/stats/networkcomparison/${networkId}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response;
      })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setChartData(res);
      })
      .catch((reason) => console.log(reason));
  }, []);

  return (
    <CChart
      type="bar"
      data={{
        labels: printLabel(chartData),
        datasets: [
          {
            label: Severity.CRITICAL,
            backgroundColor: getSeverityColor(Severity.CRITICAL),
            data: drawChart(chartData, 1)
          },
          {
            label: Severity.HIGH,
            backgroundColor: getSeverityColor(Severity.HIGH),
            data: drawChart(chartData, 2)
          },
          {
            label: Severity.MEDIUM,
            backgroundColor: getSeverityColor(Severity.MEDIUM),
            data: drawChart(chartData, 3)
          },
          {
            label: Severity.LOW,
            backgroundColor: getSeverityColor(Severity.LOW),
            data: drawChart(chartData, 4)
          }
        ]
      }}
      style={{
        backgroundColor: 'white',
        border: '1px solid #ccc', // Add this line for a 1px solid border
        borderRadius: '5px', // Add this line for rounded corners
        height: 'calc(100vh - 195px)'
      }} // Add this line
    />
  );
};

export default NetworkComparison;
