import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Paper } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button/Button';

const useStyles = makeStyles(() => ({
  label: {
    flexDirection: 'column'
  }
}));

const AddButton: React.FC<ButtonProps> = ({
  children,
  ...rest
}: ButtonProps) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Paper
      elevation={1}
      sx={{
        width: '100%',
        height: '100%',
        border: `1px dashed ${theme.palette.primary.main}`,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Button
        variant="text"
        sx={{
          textTransform: 'none',
          width: '100%',
          height: '100%'
        }}
        className={classes.label}
        startIcon={
          <Box>
            <AddCircleOutlineOutlinedIcon
              // paddingLeft is specified for symmetry
              // `span` element of the button for some unknown reason has paddingRight
              sx={{
                color: theme.palette.primary.light,
                fontSize: 80,
                paddingLeft: 1
              }}
            />
          </Box>
        }
        {...rest}
      >
        {children}
      </Button>
    </Paper>
  );
};

export default AddButton;
