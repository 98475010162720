import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import { SystemsConnectionMetadata } from './interface/SystemsConnectionInterface';
import {
  downloadCsv,
  getBruteforceLibrary,
  getBruteforcePattern,
  getCurrentId,
  getNetworkConnectionInformation,
  getProgress,
  getStatusOfBruteforce,
  sendSystemsConnectionMetadata,
  startHashesBruteforce,
  stopBruteforce
} from './service/BruteforcePasswordService';
import HotToast from '../../notifications/HotToast';
import BruteforceOptionDialog from './BruteforceOptionDialog';

const BruteforceFramePage = ({ id }: { id?: number }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  let columns: number;

  if (isSmallScreen) {
    columns = 2;
  } else if (theme.breakpoints.down('md')) {
    columns = 4;
  } else {
    columns = 4;
  }

  const [data, setData] = useState<SystemsConnectionMetadata[]>([]);
  const [currentItem, setCurrentItem] = useState<SystemsConnectionMetadata>();
  const [hashesCounts, setHashesCounts] = useState<number[]>([]);
  const [isBruteforceStart, setIsBruteforceStart] = useState<boolean>(false);
  const [bruteforceId, setBruteforceId] = useState<number>();
  const [progress, setProgress] = useState(0);
  const [notifiedForFinishBrute, setNotifiedForFinishBrute] =
    React.useState(false);
  const [libraries, setLibraries] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    getBruteforceLibrary()
      .then((data3) => {
        console.log(data3);
        setLibraries(data3);
      })
      .catch((error) => console.error('GET request failed', error));
  }, []);

  useEffect(() => {
    getBruteforcePattern()
      .then((data4) => {
        console.log(data4);
        setPattern(data4);
      })
      .catch((error) => console.error('GET request failed', error));
  }, []);

  useEffect(() => {
    if (bruteforceId === undefined) {
      getCurrentId().then((resp) => {
        if (Number(resp) !== -1) {
          setBruteforceId(Number(resp));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      getNetworkConnectionInformation(id).then((resp) => setData(resp));

      getStatusOfBruteforce().then((resp) => {
        // console.log('update every 5000', resp, isBruteforceStart, bruteforceId);
        setIsBruteforceStart(resp);
        // console.log('update every 5000', resp, isBruteforceStart, bruteforceId);
      });
    }
  }, [id]);

  useEffect(() => {
    const interval = setInterval(() => {
      getStatusOfBruteforce().then((resp) => {
        // console.log('update every 5000', resp, isBruteforceStart);
        setIsBruteforceStart(resp);
        // console.log('update every 5000', resp, isBruteforceStart);
      });
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getProgress().then((resp2) => {
        setProgress(parseFloat(resp2));
        if (!notifiedForFinishBrute && parseFloat(resp2) === 100) {
          setNotifiedForFinishBrute(true);
          notifySuccess('Bruteforce finished!');
          setProgress(0);
        }
      });
      if (id !== undefined)
        getNetworkConnectionInformation(id).then((resp) => setData(resp));
    }, 5000);

    return () => clearInterval(interval);
  }, [getProgress, notifiedForFinishBrute, setNotifiedForFinishBrute, id]);

  const handleGetHashesButtonClick = (
    item: SystemsConnectionMetadata,
    index: number
  ) => {
    notifySuccess('Start hashes fetching');
    sendSystemsConnectionMetadata(item)
      .then((resp) => {
        console.log(resp);
        setHashesCounts((prevHashesCounts) => {
          const updatedHashesCounts = [...prevHashesCounts];
          updatedHashesCounts[index] = Number(resp);
          return updatedHashesCounts;
        });
        notifySuccess('Hashes fetched successfully!');
      })
      .catch((error) => {
        notifyError('Hashes fetched request failed');
      });
  };

  const handleStartStopBruteforceButtonClick = (
    item: SystemsConnectionMetadata
  ) => {
    console.log(item);
    const request = isBruteforceStart ? stopBruteforce : startHashesBruteforce;
    if (isBruteforceStart) {
      setNotifiedForFinishBrute(false);
    }
    setBruteforceId(item.id);
    setCurrentItem(item);

    if (!openDialog && !isBruteforceStart) {
      setOpenDialog(true);
    } else {
      // console.log(item.bruteforceLibrary);
      // console.log(item.customPassword);
      setOpenDialog(false);
      request(item)
        .then((resp) => {
          setIsBruteforceStart(!isBruteforceStart);

          const message = isBruteforceStart
            ? 'Bruteforce stopped successfully!'
            : 'Bruteforce started successfully!';
          notifySuccess(message);
        })
        .catch((error) => {
          console.error('Request failed', error);
        });
    }
  };

  const handleDownloadButtonClick = async (item: SystemsConnectionMetadata) => {
    try {
      const csvBlob = await downloadCsv(item.id);
      const url = URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${item.ip}_${item.port}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      notifySuccess('CSV file downloaded successfully!');
    } catch (error) {
      console.error('GET request failed', error);
    }
  };

  return (
    <>
      <Box
        sx={{
          ml: 1,
          mt: 2,
          height: 'calc(100vh - 160px)',
          width: '100%',
          typography: 'body1',
          background: '#fdfdfd'
        }}
      >
        <Grid
          xs={9}
          container
          spacing={2}
          style={{
            marginTop: 2,
            marginLeft: 2
          }}
        >
          {data.length === 0 ? (
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography sx={{ mt: '40vh' }}>
                  {id !== undefined
                    ? 'No data found.'
                    : 'Select data from the Networks table.'}
                </Typography>
              </Box>
            </Grid>
          ) : (
            data.map((item, index) => (
              <Grid item xs={12} md={columns} key={`${item.id}`}>
                <Card
                  sx={{
                    backgroundColor: '#fff',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    ':hover': { boxShadow: 6 }
                  }}
                >
                  <Button
                    sx={{
                      border: 0,
                      mb: '4px',
                      width: '100%',
                      height: 3,
                      fontSize: '10px',
                      borderRadius: '4px 4px 0 0',
                      backgroundColor: item.cracked ? '#4caf50' : '#f44336', // Если результат готов - зелёный фон
                      color: 'white',
                      '&:hover': {
                        backgroundColor: item.cracked ? '#388e3c' : '#d32f2f'
                      }
                    }}
                    variant="contained"
                    onClick={() => handleDownloadButtonClick(item)}
                    disabled={!item.cracked} // Активируем кнопку только если файл готов
                  >
                    {item.cracked
                      ? 'Download Results'
                      : 'Waiting for results...'}
                  </Button>

                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <List dense>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: 'bold', color: '#757575' }}
                                >
                                  IP
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  sx={{ color: '#212121' }}
                                >
                                  {item.ip}
                                </Typography>
                              }
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: 'bold', color: '#757575' }}
                                >
                                  Username
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  sx={{ color: '#212121' }}
                                >
                                  {item.username}
                                </Typography>
                              }
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: 'bold', color: '#757575' }}
                                >
                                  SAP Client
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  sx={{ color: '#212121' }}
                                >
                                  {item.sapClient}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>

                      <Grid item xs={6}>
                        <List dense>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: 'bold', color: '#757575' }}
                                >
                                  Port
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  sx={{ color: '#212121' }}
                                >
                                  {item.port}
                                </Typography>
                              }
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: 'bold', color: '#757575' }}
                                >
                                  Password
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  sx={{ color: '#212121' }}
                                >
                                  ********
                                </Typography>
                              }
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="subtitle1"
                                  sx={{ fontWeight: 'bold', color: '#757575' }}
                                >
                                  Hashes
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  sx={{ color: '#212121' }}
                                >
                                  {item.hashesCount === 0
                                    ? hashesCounts[index] ?? 'N/A'
                                    : item.hashesCount}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>

                    <Button
                      sx={{
                        width: '100%'
                      }}
                      color="success"
                      variant="outlined"
                      disabled={isBruteforceStart}
                      onClick={() => handleGetHashesButtonClick(item, index)}
                    >
                      Load Hashes
                    </Button>
                    <Button
                      sx={{
                        mt: '12px',
                        mb: '4px',
                        width: '100%',
                        backgroundColor: isBruteforceStart
                          ? '#f44336'
                          : '#4caf50', // Set your desired background color
                        color: 'white', // Set the text color
                        '&:hover': {
                          backgroundColor: isBruteforceStart
                            ? '#d32f2f'
                            : '#388e3c' // Set the hover background color
                        }
                      }}
                      variant="contained"
                      disabled={
                        item.hashesCount === 0 ||
                        (isBruteforceStart && item.id !== bruteforceId)
                      }
                      onClick={() => handleStartStopBruteforceButtonClick(item)}
                    >
                      {isBruteforceStart
                        ? 'Stop Bruteforce'
                        : 'Start Bruteforce'}
                    </Button>

                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      sx={{
                        display:
                          (isBruteforceStart && item.id !== bruteforceId) ||
                          !isBruteforceStart
                            ? 'none'
                            : 'block'
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
          <HotToast />

          {openDialog && (
            <BruteforceOptionDialog
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              onConfirm={(
                library: string,
                customPassword: string,
                brutePattern: string
              ) => {
                if (currentItem !== undefined) {
                  const newItem = currentItem;
                  newItem.bruteforceLibrary = library;
                  newItem.customPassword = customPassword ?? '';
                  newItem.bruteforcePattern = brutePattern;
                  handleStartStopBruteforceButtonClick(newItem);
                }
              }}
              libraries={libraries}
              patterns={pattern}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

export default BruteforceFramePage;
