import React, { useState } from 'react';
import { FormHelperText, TextField } from '@mui/material';

interface Props {
  value: string;
  onChange: (value: string) => void;
  serviceId: string;
}

const UsernameNode: React.FC<Props> = ({
  value,
  onChange,
  serviceId
}: Props) => {
  const [isValid, setIsValid] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const isPatternRequired = serviceId === 'SICF';
    const validationPattern = isPatternRequired
      ? /^[0-9]{3}:[0-9a-zA-Z_.*-]*$/
      : /^.*$/;

    if (inputValue.length > 3 && !validationPattern.test(inputValue)) {
      setIsValid(false);
    } else {
      setIsValid(true);
      onChange(inputValue);
    }
  };

  return (
    <div>
      <TextField
        autoComplete="off"
        error={!isValid}
        variant="standard"
        label={serviceId === 'SICF' ? 'Client:Username' : 'Username'}
        onChange={handleChange}
        value={value}
        placeholder={serviceId === 'SICF' ? '000:SAP*' : 'Username'}
        size="small"
      />
      {!isValid && (
        <FormHelperText error>
          {serviceId === 'SICF'
            ? 'Invalid format. Format should be Client:Username'
            : 'Invalid format'}
        </FormHelperText>
      )}
    </div>
  );
};

export default UsernameNode;
