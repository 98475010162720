import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import MoreIcon from '@mui/icons-material/More';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Divider, Tooltip, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { Differ, DiffResult, Viewer } from 'json-diff-kit';
import HotToast from '../../notifications/HotToast';
import formatDateTime from '../../formatDateTime';
import 'json-diff-kit/dist/viewer.css';
import {
  ActivatedPoliciesInterface,
  BtpMonitoringSettingsResponse,
  EventsInterface,
  IgnoreEventsInterface
} from '../../../api/sapBtp/sapBtpApi';
import {
  getBtpMonitoringSettings,
  getClassIdText,
  getEventsList,
  normalizeChangeType,
  setBTPAlertCollection,
  setBTPIgnorableCollection,
  getGlobalAccountName,
  getSubaccountName,
  humanizeChangeType
} from '../../../api/sapBtp/sapBtpService';

const BtpLogs: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<EventsInterface[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [prevRows, setPrevRows] = useState<EventsInterface[]>([]);
  const [alertCollection, setAlertCollection] =
    useState<ActivatedPoliciesInterface>();
  const [ignoreCollection, setIgnoreCollection] =
    useState<IgnoreEventsInterface>();
  const notifySuccess = (message: string) => toast.success(message);

  const [openDialog, setOpenDialog] = useState(false);
  const [diff, setDiff] = useState<[DiffResult[], DiffResult[]]>([[], []]);
  const [diffObject, setDiffObject] = useState<string>('');
  const [hideUnchangedLines, setHideUnchangedLines] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [btpMonitoringSettings, setBtpMonitoringSettings] =
    useState<BtpMonitoringSettingsResponse>();

  const [hoveredGlobalAccountId, setHoveredGlobalAccountId] = useState<
    number | null
  >(null);
  const [hoveredSubaccountId, setHoveredSubaccountId] = useState<number | null>(
    null
  );
  const [globalAccountName, setGlobalAccountName] = useState<string | null>(
    null
  );
  const [subaccountName, setSubaccountName] = useState<string | null>(null);

  const prevRowsRef = useRef(prevRows);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getBtpMonitoringSettings();
        setBtpMonitoringSettings(settings);
      } catch (error) {
        console.error('Error fetching BTP monitoring settings:', error);
      }
    };

    fetchSettings();
  }, []);

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = async () => {
    try {
      const response = await getEventsList(page, pageSize, searchQuery);
      setRows(response.content);
      setTotalPages(response.totalPages);

      if (
        JSON.stringify(response.content) !== JSON.stringify(prevRowsRef.current)
      ) {
        notifySuccess('New events detected in SAP BTP');
        setPrevRows(response.content);
        prevRowsRef.current = response.content; // Update the ref as well
      }
    } catch (error) {
      console.error('Error fetching event logs:', error);
    }
  };

  useEffect(() => {
    setRows([]);
    fetchData();

    const interval = setInterval(fetchData, 10000);

    return () => clearInterval(interval);
  }, [page, searchQuery]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleButtonClickIgnore = (row: any) => {
    setIgnoreCollection({
      id: 0,
      classId: row.classId,
      globalAccountGuid: row.globalAccountGuid,
      subaccountGuid: row.subaccountGuid,
      userEmail: row.userEmail,
      changePath: row.changePath,
      changeType: normalizeChangeType(row.changeType),
      createdAt: ''
    });
  };

  useEffect(() => {
    if (ignoreCollection) {
      setBTPIgnorableCollection(ignoreCollection);
      notifySuccess(
        `Event ${getClassIdText(ignoreCollection.classId)} ${
          ignoreCollection.changeType
        } has been added to ignorable list`
      );
    }
  }, [ignoreCollection]);

  const differ = new Differ({
    detectCircular: true, // default `true`
    maxDepth: Infinity, // default `Infinity`
    showModifications: true, // default `true`
    arrayDiffMethod: 'lcs' // default `"normal"`, but `"lcs"` may be more useful
  });

  const compareJson = (row: any) => {
    let oldData = {};
    let newData = {};

    try {
      oldData = JSON.parse(row.oldValue);
    } catch (error) {
      console.error('Error parsing oldValue:', error);
    }

    try {
      newData = JSON.parse(row.newValue);
    } catch (error) {
      console.error('Error parsing newValue:', error);
    }

    const diffResult = differ.diff(oldData, newData) as [
      DiffResult[],
      DiffResult[]
    ];

    if (
      JSON.stringify(oldData).length > 5000 &&
      JSON.stringify(newData).length > 5000
    ) {
      setHideUnchangedLines(true);
    } else {
      setHideUnchangedLines(false);
    }

    setDiffObject(getClassIdText(row.classId));
    setDiff(diffResult);
    setOpenDialog(true);
  };

  const handleButtonClickTrack = (row: any) => {
    setAlertCollection({
      redRaysPoliciesSettings: {
        id: 0,
        classId: '',
        changeType: '',
        changePath: '',
        title: '',
        businessImpact: '',
        severity: '',
        activated: false
      },
      id: 0,
      trackChange: row.changes === 'null' ? '' : row.changes,
      classId: row.classId,
      globalAccountGuid: row.globalAccountGuid,
      subaccountGuid: row.subaccountGuid,
      userEmail: row.userEmail,
      changePath: row.changePath,
      changeType: normalizeChangeType(row.changeType),
      comment: '',
      createdAt: ''
    });
  };

  useEffect(() => {
    if (alertCollection) {
      setBTPAlertCollection(alertCollection);
      notifySuccess(
        `Event ${getClassIdText(alertCollection.classId)} ${
          alertCollection.changeType
        } has been added to monitoring list`
      );
    }
  }, [alertCollection]);

  const handleMouseOverGlobalAccount = async (guid: string, rowId: number) => {
    setHoveredGlobalAccountId(rowId);
    try {
      const response = await getGlobalAccountName(guid);
      setGlobalAccountName(response);
    } catch (error) {
      console.error('Error fetching global account name:', error);
    }
  };

  const handleMouseOutGlobalAccount = () => {
    setHoveredGlobalAccountId(null);
    setGlobalAccountName(null);
  };

  const handleMouseOverSubaccount = async (guid: string, rowId: number) => {
    setHoveredSubaccountId(rowId);
    try {
      const response = await getSubaccountName(guid);
      setSubaccountName(response);
    } catch (error) {
      console.error('Error fetching subaccount name:', error);
    }
  };

  const handleMouseOutSubaccount = () => {
    setHoveredSubaccountId(null);
    setSubaccountName(null);
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        pt: 1,
        backgroundColor: 'rgba(159,159,159,0.05)'
      }}
    >
      <Typography
        sx={{
          width: '90%',
          backgroundColor: '#ffffff',
          fontSize: 'small',
          mb: 1,
          ml: '5%',
          color: '#CACACA',
          textAlign: 'center',
          borderRadius: '4px'
        }}
      >
        Detected events/changes in SAP BTP environment
      </Typography>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          ml: 2,
          mr: 2
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>BTP Object</TableCell>
                <TableCell>Change Type</TableCell>
                {btpMonitoringSettings?.showPath === 'true' && (
                  <TableCell>Change Path</TableCell>
                )}
                <TableCell>Changes</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6}>
                  <TextField
                    sx={{ ml: 0, mt: 1 }}
                    size="small"
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    label="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    There are no events available
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <div>
                      <strong>{`Context: ${getClassIdText(
                        row.classId
                      )}`}</strong>
                    </div>

                    {row.subaccountGuid && (
                      <div>
                        {`Subaccount: ${row.subaccountGuid}`}
                        <Tooltip
                          title={
                            hoveredSubaccountId === row.id
                              ? subaccountName || ''
                              : ''
                          }
                          open={
                            hoveredSubaccountId === row.id &&
                            Boolean(subaccountName)
                          }
                          disableHoverListener
                        >
                          <IconButton
                            onMouseOver={() =>
                              handleMouseOverSubaccount(
                                row.subaccountGuid,
                                row.id
                              )
                            }
                            onMouseOut={handleMouseOutSubaccount}
                          >
                            <MoreIcon style={{ fontSize: 16 }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {row.globalAccountGuid &&
                      row.subaccountGuid.length === 0 && (
                        <div>
                          {`Global Account: ${row.globalAccountGuid}`}
                          <Tooltip
                            title={
                              hoveredGlobalAccountId === row.id
                                ? globalAccountName || ''
                                : ''
                            }
                            open={
                              hoveredGlobalAccountId === row.id &&
                              Boolean(globalAccountName)
                            }
                            disableHoverListener
                          >
                            <IconButton
                              onMouseOver={() =>
                                handleMouseOverGlobalAccount(
                                  row.globalAccountGuid,
                                  row.id
                                )
                              }
                              onMouseOut={handleMouseOutGlobalAccount}
                            >
                              <MoreIcon style={{ fontSize: 16 }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    {row.userEmail && (
                      <div>{`User Email: ${row.userEmail}`}</div>
                    )}
                  </TableCell>
                  <TableCell>{humanizeChangeType(row.changeType)}</TableCell>
                  {btpMonitoringSettings?.showPath === 'true' && (
                    <TableCell>{row.changePath}</TableCell>
                  )}
                  <TableCell>
                    {row.changes.length > 100
                      ? `${row.changes.substring(0, 100)}...`
                      : row.changes}
                  </TableCell>

                  <TableCell>
                    {formatDateTime(new Date(row.createdAt))}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Start track" arrow>
                      <IconButton
                        onClick={() => handleButtonClickTrack(row)}
                        aria-label="start track"
                        color="success"
                      >
                        <TrackChangesIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ignore tracking this alert" arrow>
                      <IconButton
                        onClick={() => handleButtonClickIgnore(row)}
                        aria-label="Never track this alert"
                      >
                        <VisibilityOffIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Compare changes" arrow>
                      <IconButton
                        onClick={() => compareJson(row)}
                        aria-label="compare result"
                        color="success"
                      >
                        <CompareArrowsIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalPages * pageSize}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[10, 20, 25]}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
      <HotToast />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            backgroundColor: 'white',
            color: 'black',
            fontSize: '1.2rem',
            mb: 2
          }}
        >
          <Typography variant="h2">
            SAP BTP State Changes {'{Old : New}'}
          </Typography>
          <Divider sx={{ mt: 2 }} />
          <IconButton
            aria-label="close"
            onClick={() => setOpenDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography sx={{ mb: 2 }}>
            Object of Tracking:<strong> {diffObject}</strong>
          </Typography>
          <Viewer
            diff={diff}
            indent={4}
            lineNumbers
            highlightInlineDiff
            inlineDiffOptions={{
              mode: 'word',
              wordSeparator: ''
            }}
            hideUnchangedLines
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default BtpLogs;
