import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tooltip,
  Typography
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import IconButton from '@mui/material/IconButton';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinearProgress from '@mui/material/LinearProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';
import toast from 'react-hot-toast';
import NetworkSelector from '../../components/networkselector/NetworkSelector';
import TreeNode from './tree/TreeNode';
import ServicesMetadataLoader from '../../components/services/ServicesMetadataLoader';
import RenameDialog from '../../components/networkselector/RenameDialog';
import {
  loadNetwork,
  renameNetwork,
  saveMultiHostScanNetwork,
  saveNetwork,
  scanNetwork,
  updateNetwork,
  uploadIpList
} from '../../api/network/networkService';
import {
  Ip,
  NetworkMetadata,
  NetworkStatus,
  Tree
} from '../../api/network/treeApi';
import ProvideNameDialog from '../../components/ProvideNameDialog';
import ErrorsDialog from '../../components/ErrorsDialog';
import NetMaskScanModal from '../../components/NetMaskScanModal';
import {
  getAvailableIP,
  getPortScanProgress
} from '../../components/settings/license/LicensingService';
import HotToast from '../../components/notifications/HotToast';

const emptyTree: Tree = {
  ips: []
};

const PortScan = (): JSX.Element => {
  const [openMultiHostModal, setOpenMultiHostModal] = useState(false);
  const [tree, setTree] = useState<Tree>(emptyTree);
  const [savedTree, setSavedTree] = useState<Tree>();
  const [selectedNetworkMetadata, setSelectedNetworkMetadata] = useState<
    NetworkMetadata | undefined
  >(undefined);
  const [messages, setMessages] = useState<string[]>([]);
  const [
    forceReloadNetworkSelectorNeeded,
    setForceReloadNetworkSelectorNeeded
  ] = useState(false);
  const [openedNetworkRenameDialog, setOpenedNetworkRenameDialog] =
    useState(false);
  const [openedSaveNetworkDialog, setOpenedSaveNetworkDialog] = useState(false);
  const [openedScanNetworkDialog, setOpenedScanNetworkDialog] = useState(false);
  const [openedErrorsDialog, setOpenedErrorsDialog] = useState(false);
  const [expandedAll, setExpandedAll] = useState(false);
  const [collapsedAll, setCollapsedAll] = useState(false);

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const [availableIp, setAvailableIp] = useState<string>('0/0');
  const [getSapSid, setGetSapSid] = useState(false);

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const checkScanStatus = async () => {
    try {
      const data = parseFloat(await getPortScanProgress());
      if (data !== 0) {
        setLoading(true);
        setProgress(data);
        if (data === 100 || data === 0) {
          setLoading(false);
          setProgress(data);
        }
      }
    } catch (error) {
      console.error('Error fetching scan status:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkScanStatus();
    }, 3000); // Check every 5 seconds

    return () => {
      clearInterval(interval); // Clear the interval when component unmounts
    };
  }, []);

  const handleGetSAPSID = () => {
    setGetSapSid(!getSapSid); // Toggle the checkbox state
    // You can add your desired actions here when the checkbox is clicked
    if (!getSapSid) {
      // Perform actions when checkbox is checked
      console.log('Getting SAP SID...');
    } else {
      // Perform actions when checkbox is unchecked
      console.log('SAP SID retrieval cancelled.');
    }
  };

  function printAvailableIp() {
    getAvailableIP().then((resp) => {
      setAvailableIp(resp);
    });
  }

  useEffect(() => {
    printAvailableIp();
  }, []);

  useEffect(() => {
    if (selectedNetworkMetadata?.id === undefined) {
      return;
    }
    printAvailableIp();

    loadNetwork(
      selectedNetworkMetadata.id,
      (loadedNetwork) => {
        setFirstLoad(false);
        setSavedTree(loadedNetwork.payload);
        setTree(loadedNetwork.payload);
        setMessages(loadedNetwork.messages);
      },
      (reason) =>
        notifyError(
          `Failed to load ${selectedNetworkMetadata.name} network. Reason: ${reason.status}`
        )
    );
  }, [selectedNetworkMetadata?.id, selectedNetworkMetadata?.status, getSapSid]);

  useEffect(() => {
    setExpandedAll(false);
  }, [expandedAll]);

  useEffect(() => {
    setCollapsedAll(false);
  }, [collapsedAll]);

  const handleOpenNetworkRenameDialog = () => {
    setOpenedNetworkRenameDialog(true);
  };
  const handleStartNewScan = () => {
    setFirstLoad(false);
    setTree(emptyTree);
    setSelectedNetworkMetadata(undefined);
    setSavedTree(undefined);
  };

  function checkUniqueInstanceIds(ip: Ip): boolean {
    const uniqueInstanceIds = new Set(
      ip.instances.map((instance) => instance.instanceId)
    );
    return uniqueInstanceIds.size !== ip.instances.length;
  }

  // update network handle
  const handleUpdateNetwork = () => {
    let isDuplicateInstance = false;

    tree.ips.forEach((ip) => {
      if (checkUniqueInstanceIds(ip) && !isDuplicateInstance) {
        isDuplicateInstance = true;
      }
    });

    if (!isDuplicateInstance) {
      updateNetwork(
        tree,
        selectedNetworkMetadata?.id,
        selectedNetworkMetadata?.status,
        selectedNetworkMetadata?.name,
        (createdId) => {
          notifySuccess(
            `Network "${selectedNetworkMetadata?.name}" has been updated`
          );
        },
        (reason) =>
          notifyError(
            `${reason.status}: Possible License limitation is reached`
          )
      );
      if (tree !== undefined) setSavedTree(tree);
    } else {
      notifyError('Instance with this ID already exists. Aborting update.');
    }
  };

  const handleSaveNetwork = (newName: string) => {
    let isDuplicateInstance = false;

    tree.ips.forEach((ip) => {
      if (checkUniqueInstanceIds(ip) && !isDuplicateInstance) {
        isDuplicateInstance = true;
      }
    });

    if (!isDuplicateInstance) {
      saveNetwork(
        tree,
        newName,
        (createdId) =>
          setSelectedNetworkMetadata({
            id: createdId,
            createdAt: 'just now',
            name: newName,
            status: NetworkStatus.ORIGINAL
          }),
        (reason) =>
          notifyError(
            `Failed to save ${newName} network with the reason: ${reason.status}`
          )
      );
      setOpenedSaveNetworkDialog(false);
    } else {
      notifyError('Instance with this ID already exists. Aborting update.');
    }
  };

  const saveDialog = (
    <ProvideNameDialog
      opened={openedSaveNetworkDialog}
      handleApply={handleSaveNetwork}
      handleClose={() => setOpenedSaveNetworkDialog(false)}
      autoGenerateName={tree.ips[0]?.ip ?? 'Enter new network name'}
      windowName="Select a name for the new Landscape"
    />
  );

  const handleScanNetwork = (newName: string) => {
    if (selectedNetworkMetadata?.id === undefined) {
      return;
    }
    scanNetwork(
      selectedNetworkMetadata.id,
      newName,
      () => {
        setForceReloadNetworkSelectorNeeded(true);
        notifySuccess(`${newName} network scheduled for scan`);
      },
      (reason) =>
        notifyError(
          `Failed to schedule ${newName} network for scan with the reason: ${reason.status}`
        )
    );
    setOpenedScanNetworkDialog(false);
  };

  const scanDialog = (
    <ProvideNameDialog
      opened={openedScanNetworkDialog}
      handleApply={handleScanNetwork}
      handleClose={() => setOpenedScanNetworkDialog(false)}
      autoGenerateName={selectedNetworkMetadata?.name ?? 'Network name'}
      windowName="Select Landscape Name"
    />
  );

  const errorsDialog = (
    <ErrorsDialog
      errors={messages}
      opened={openedErrorsDialog}
      handleClose={() => setOpenedErrorsDialog(false)}
    />
  );

  const handleRenameNetwork = (newName: string) => {
    if (selectedNetworkMetadata?.id === undefined) {
      return;
    }
    renameNetwork(
      selectedNetworkMetadata.id,
      newName,
      () => setForceReloadNetworkSelectorNeeded(true),
      (reason) =>
        notifyError(
          `Failed to rename ${selectedNetworkMetadata.name} network to ${newName} with the reason: ${reason.status}`
        )
    );
    setOpenedNetworkRenameDialog(false);
  };

  const renameDialog = selectedNetworkMetadata && (
    <RenameDialog
      name={selectedNetworkMetadata.name}
      opened={openedNetworkRenameDialog}
      handleApply={handleRenameNetwork}
      handleClose={() => setOpenedNetworkRenameDialog(false)}
      windowName="Select a new name for the Landscape"
    />
  );

  const handleMultiHostScan = (name: string, hostMask: string) => {
    setFirstLoad(false);
    saveMultiHostScanNetwork(
      name,
      hostMask,
      (createdId) => {
        setSelectedNetworkMetadata({
          id: createdId,
          createdAt: 'just now',
          name,
          status: NetworkStatus.ORIGINAL
        });
        notifySuccess('Network has been created');
      },
      (reason) =>
        notifyError(
          `Failed to save ${name} network with the reason: ${reason.status}`
        )
    );

    setOpenMultiHostModal(false);
  };

  const netMaskScanDialog = (
    <NetMaskScanModal
      opened={openMultiHostModal}
      handleApply={handleMultiHostScan}
      handleClose={() => setOpenMultiHostModal(false)}
    />
  );

  // State to manage the file input
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // State to manage the values selected in drop-down menus
  const [instanceFrom, setInstanceFrom] = useState<string>('00');
  const [instanceTo, setInstanceTo] = useState<string>('00');

  // Function to handle "Create network" button click

  const [openFileDialog, setOpenFileDialog] = useState(false);

  // Function to open the file dialog
  const handleOpenFileDialog = () => {
    setOpenFileDialog(true);
  };

  // Function to close the file dialog
  const handleCloseFileDialog = () => {
    setOpenFileDialog(false);
  };
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // Function to trigger file input when the custom button is clicked
  const handleCustomButtonClick = () => {
    fileInputRef.current?.click();
  };
  const handleCreateNetwork = () => {
    setFirstLoad(false);
    const formData = new FormData();
    if (selectedFile) {
      formData.append('ipList', selectedFile);
    }
    formData.append('instanceFrom', instanceFrom);
    formData.append('instanceTo', instanceTo);
    uploadIpList(formData)
      .then((data) => {
        notifySuccess(data);
      })
      .catch((error) => {
        notifySuccess(error);
        console.error('Error creating network:', error);
      });
    handleCloseFileDialog();
  };
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  // Function to handle file input change
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setSelectedFileName(event.target.files[0].name); // Set the selected file name
    }
  };
  const labelStyles = {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '0.875rem',
    paddingTop: '2px',
    lineHeight: 1.75
  };

  return (
    <>
      <title>Port and Service Scan | RedRays</title>

      <ServicesMetadataLoader>
        <Grid container sx={{ backgroundColor: '#F8F7F7' }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={4}
            xl={4}
            sx={{ height: 'calc(100vh - 70px)', backgroundColor: '#F8F7F7' }}
          >
            <Box
              sx={{
                height: 'calc(100vh - 70px)',
                backgroundColor: '#F2F2F2',
                ml: 3,
                mr: 1,
                pt: 1
              }}
            >
              <Typography
                sx={{
                  width: '90%',
                  backgroundColor: '#ffffff',
                  fontSize: 'small',
                  mb: 1,
                  ml: '5%',
                  color: '#CACACA',
                  textAlign: 'center',
                  borderRadius: '4px'
                }}
              >
                Create a Network to scan or load one before processing.
              </Typography>
              {/*
            <Typography variant="h6">
              {'Available IP: '.concat(availableIp)}
            </Typography>
            */}
              <Accordion
                sx={{
                  backgroundColor: '#F2F2F2',
                  boxShadow: 'none', // Remove the default box-shadow to get rid of borders
                  '&:before': {
                    // This targets the pseudo-element that MUI uses for the default border
                    // Set it to a white line or make it transparent if you want to completely remove it
                    backgroundColor: 'transparent'
                  },
                  '&.Mui-expanded': {
                    margin: '0 !important' // Remove the margin added when the accordion is expanded
                  },
                  '& .MuiAccordionSummary-root': {
                    // Add white lines at the top and bottom of the summary part
                    borderTop: '1px solid white'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="network-selector-content"
                  id="network-selector-header"
                >
                  <Typography>
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      Create
                    </Box>{' '}
                    Network
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#ffffff' }}>
                  <Box
                    sx={{
                      display: 'flex', // Включить flexbox
                      alignItems: 'center', // Центрировать элементы по вертикали
                      justifyContent: 'space-between' // Распределить пространство между элементами
                    }}
                  >
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                      New Network
                    </Typography>
                    <IconButton
                      onClick={handleStartNewScan}
                      edge="end"
                      aria-label="add"
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex', // Включить flexbox
                      alignItems: 'center', // Центрировать элементы по вертикали
                      justifyContent: 'space-between' // Распределить пространство между элементами
                    }}
                  >
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                      Scan By Netmask
                    </Typography>
                    <IconButton
                      onClick={() => setOpenMultiHostModal(true)}
                      edge="end"
                      aria-label="add"
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Box>
                  <Divider />

                  <Box
                    sx={{
                      display: 'flex', // Включить flexbox
                      alignItems: 'center', // Центрировать элементы по вертикали
                      justifyContent: 'space-between' // Распределить пространство между элементами
                    }}
                  >
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                      Upload IP List
                    </Typography>
                    <IconButton
                      onClick={handleOpenFileDialog}
                      edge="end"
                      aria-label="add"
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={{
                  backgroundColor: '#F2F2F2',
                  boxShadow: 'none', // Remove the default box-shadow to get rid of borders
                  '&:before': {
                    // This targets the pseudo-element that MUI uses for the default border
                    // Set it to a white line or make it transparent if you want to completely remove it
                    backgroundColor: 'transparent'
                  },
                  '&.Mui-expanded': {
                    margin: '0 !important' // Remove the margin added when the accordion is expanded
                  },
                  '& .MuiAccordionSummary-root': {
                    // Add white lines at the top and bottom of the summary part
                    borderTop: '1px solid white',
                    borderBottom: '1px solid white'
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="network-selector-content"
                  id="network-selector-header"
                >
                  <Typography>
                    <Box component="span" sx={{ fontWeight: 'bold' }}>
                      Load
                    </Box>{' '}
                    Network
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    height: 'calc(100vh - 236px)',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <NetworkSelector
                    onChange={setSelectedNetworkMetadata}
                    selectedId={selectedNetworkMetadata?.id}
                    forceReloadNeeded={forceReloadNetworkSelectorNeeded}
                    onAfterReloaded={(networkMetadata) => {
                      setSelectedNetworkMetadata(networkMetadata);
                      setForceReloadNetworkSelectorNeeded(false);
                    }}
                    allowSelectNetworksWithErrors
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            lg={8}
            xl={8}
            sx={{
              height: 'calc(100vh - 70px)',
              backgroundColor: '#F8F7F7'
            }}
          >
            {!firstLoad && (
              <Box
                sx={{
                  height: 'calc(100vh - 70px)',
                  backgroundColor: '#F2F2F2',
                  ml: 2,
                  mr: 2,
                  p: 2,
                  pt: 1
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    backgroundColor: '#ffffff',
                    fontSize: 'small',
                    mb: 1,
                    color: '#CACACA',
                    textAlign: 'center',
                    borderRadius: '4px'
                  }}
                >
                  Define what to scan inside the network below
                </Typography>

                <Stack direction="row" alignItems="center" mt={1}>
                  {progress > 0 && progress < 100 && (
                    <>
                      <Box sx={{ width: '100%' }}>
                        <Typography
                          sx={{ fontSize: '0.75rem' }}
                          textAlign="center"
                        >
                          Progress: {progress}%
                        </Typography>
                        <Tooltip
                          title={`Progress: ${progress}%`}
                          placement="top"
                        >
                          <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                              width: '100%',
                              backgroundColor: 'white',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#ff0000'
                              }
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </>
                  )}
                  {selectedNetworkMetadata?.status === NetworkStatus.SCANNING &&
                    progress === 0 && (
                      <>
                        <Box sx={{ width: '100%' }}>
                          <Typography
                            sx={{ fontSize: '0.75rem' }}
                            textAlign="center"
                          >
                            SID detection is in progress.
                          </Typography>
                          <LinearProgress
                            sx={{
                              width: '100%',
                              backgroundColor: 'white',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: '#5dd000'
                              }
                            }}
                          />
                        </Box>
                      </>
                    )}
                </Stack>
                <TabContext value="1">
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      backgroundColor: '#ffffff'
                    }}
                  >
                    <TabList aria-label="lab API tabs example">
                      <Tab
                        label={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <Typography
                              component="span"
                              variant="caption"
                              sx={{ fontSize: '0.75rem' }}
                            >
                              Network Name:
                            </Typography>
                            <Typography
                              component="span"
                              sx={{ marginLeft: 1, fontWeight: 'bold' }}
                            >
                              {selectedNetworkMetadata?.name || 'New Network'}
                            </Typography>
                          </Box>
                        }
                        value="1"
                      />
                    </TabList>
                  </Box>
                  <TabPanel
                    value="1"
                    sx={{ backgroundColor: '#ffffff', pb: 0, pt: 1 }}
                  >
                    <Stack
                      sx={{
                        width: '100%',
                        height: 'calc(100vh - 245px)',
                        overflow: 'auto'
                      }}
                    >
                      <Stack direction="row">
                        <Tooltip title="Expand whole network to Services">
                          <Button
                            onClick={() => setExpandedAll(true)}
                            sx={{ textTransform: 'none' }}
                          >
                            Expand All
                          </Button>
                        </Tooltip>
                        <Tooltip title="Collapse whole network to Hosts">
                          <Button
                            onClick={() => setCollapsedAll(true)}
                            sx={{ textTransform: 'none' }}
                          >
                            Collapse All
                          </Button>
                        </Tooltip>
                        <Tooltip title="Show SAP SID and System type">
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="getSAPSIDCheckbox"
                                checked={getSapSid}
                                onChange={handleGetSAPSID}
                                sx={{ mr: -1 }}
                              />
                            }
                            label={
                              <Typography style={labelStyles}>
                                SAP Properties
                              </Typography>
                            }
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              mt: -0.5,
                              ml: 1
                            }}
                          />
                        </Tooltip>
                      </Stack>
                      <TreeNode
                        tree={tree ?? emptyTree}
                        onChange={setTree}
                        expandedAll={expandedAll}
                        collapsedAll={collapsedAll}
                        showSid={getSapSid}
                        isScanningStatus={
                          selectedNetworkMetadata?.status ===
                            NetworkStatus.SCANNING &&
                          progress > 0 &&
                          progress < 100
                        }
                      />
                    </Stack>
                  </TabPanel>
                </TabContext>
                <Stack
                  direction="row"
                  justifyContent="flex-end" // Aligns items to the right
                  marginTop={0}
                  spacing={1}
                  divider={<Divider orientation="vertical" flexItem />}
                  sx={{
                    backgroundColor: '#ffffff',
                    pr: 3,
                    pt: 2,
                    pb: 2,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    borderTop: '1px solid #E0E0E0' // Added top border style here
                  }}
                >
                  <Button
                    onClick={() => setOpenedSaveNetworkDialog(true)}
                    variant="outlined"
                    color="success"
                    size="small"
                    startIcon={<SaveIcon />}
                    disabled={
                      tree === savedTree ||
                      tree.ips[0]?.ip.length === 0 ||
                      tree.ips[0] === undefined
                    }
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => handleUpdateNetwork()}
                    variant="outlined"
                    color="success"
                    size="small"
                    startIcon={<CachedIcon />}
                    disabled={
                      tree === savedTree ||
                      selectedNetworkMetadata === undefined
                    }
                  >
                    Update
                  </Button>

                  <Button
                    onClick={handleOpenNetworkRenameDialog}
                    variant="outlined"
                    disabled={tree !== savedTree}
                    sx={{ color: '#000000' }}
                    size="small"
                    startIcon={<EditIcon />}
                  >
                    <Typography variant="h6">Rename</Typography>
                  </Button>
                  <Button
                    onClick={
                      () =>
                        handleScanNetwork(
                          selectedNetworkMetadata
                            ? selectedNetworkMetadata?.name
                            : 'Change name of Network'
                        )
                      /*
                    setOpenedScanNetworkDialog(true)
                    */
                    }
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={
                      tree !== savedTree ||
                      tree.ips === undefined ||
                      (selectedNetworkMetadata?.status ===
                        NetworkStatus.SCANNING &&
                        progress > 0 &&
                        progress < 100)
                    }
                    startIcon={<CenterFocusWeakIcon />}
                  >
                    Scan
                  </Button>
                  {messages.length > 0 && (
                    <Button
                      onClick={() => setOpenedErrorsDialog(true)}
                      variant="contained"
                      color="error"
                      startIcon={<FormatListBulletedIcon />}
                      disabled={messages.length === 0}
                      sx={{ mt: 2 }}
                    >
                      Errors
                    </Button>
                  )}
                </Stack>
              </Box>
            )}
            {firstLoad && (
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  height: 'calc(100vh - 70px)',
                  backgroundColor: '#F2F2F2'
                }} // Full viewport height and background color
              >
                <WifiTetheringOffIcon
                  sx={{ fontSize: 200, color: grey[400] }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: 36,
                    mt: 2,
                    ml: '30%',
                    mr: '30%',
                    color: grey[400],
                    textAlign: 'center'
                  }}
                >
                  Create a new or select an existing Network from the list for
                  further actions.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        {openedNetworkRenameDialog && renameDialog}
        {openedSaveNetworkDialog && saveDialog}
        {openedScanNetworkDialog && scanDialog}
        {openedErrorsDialog && errorsDialog}
        {openMultiHostModal && netMaskScanDialog}
        <Dialog open={openFileDialog} onClose={handleCloseFileDialog}>
          <DialogTitle
            sx={{
              backgroundColor: 'white',
              color: 'black',
              fontSize: '1.2rem', // Increase font size of the title
              mb: 2 // Add margin bottom between title and input label
            }}
          >
            <Typography variant="h2">
              Upload IP list and Select Instance Range
            </Typography>
            <Divider sx={{ mt: 2 }} />
          </DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <input
              type="file"
              onChange={handleFileInputChange}
              ref={fileInputRef}
              style={{ display: 'none' }}
            />
            <Button
              onClick={handleCustomButtonClick}
              variant="contained"
              color={selectedFileName ? 'success' : 'primary'}
            >
              {selectedFileName ? (
                // Show the selected file name if it exists
                <>Selected File: {selectedFileName}</>
              ) : (
                'Choose IP List File'
              )}
            </Button>
            <Box
              sx={{
                display: 'flex',
                mt: 2,
                alignItems: 'center'
              }}
            >
              <Typography sx={{ flex: '1' }}>Instance From:</Typography>
              <Select
                value={instanceFrom}
                onChange={(e) => setInstanceFrom(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '350px' // Set the max height here to control visible options
                    }
                  }
                }}
              >
                {Array.from({ length: 100 }, (_, i) => i).map((value) => (
                  <MenuItem
                    sx={{ height: '40px' }}
                    key={value}
                    value={value.toString().padStart(2, '0')}
                  >
                    {value.toString().padStart(2, '0')}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={{ display: 'flex', mt: 2, alignItems: 'center' }}>
              <Typography sx={{ flex: '1' }}>Instance to:</Typography>
              <Select
                value={instanceTo}
                onChange={(e) => setInstanceTo(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '350px' // Set the max height here to control visible options
                    }
                  }
                }}
              >
                {Array.from({ length: 100 }, (_, i) => i).map((value) => (
                  <MenuItem
                    key={value}
                    value={value.toString().padStart(2, '0')}
                  >
                    {value.toString().padStart(2, '0')}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleCreateNetwork}
              color="success"
              disabled={!selectedFile}
            >
              Create network
            </Button>
            <Button onClick={handleCloseFileDialog}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <HotToast />
      </ServicesMetadataLoader>
    </>
  );
};

export default PortScan;
