import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { GlobalAccountListResponse } from '../../../api/sapBtp/sapBtpApi';
import { getGlobalAccountList } from '../../../api/sapBtp/sapBtpService';

const SapGlobalAccountList: React.FC = () => {
  const [globalAccounts, setGlobalAccounts] = useState<
    GlobalAccountListResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getGlobalAccountList()
      .then((data) => {
        setGlobalAccounts(data);
        setIsLoading(false); // Data loaded, set loading to false
      })
      .catch((error) => {
        setIsLoading(false); // Error occurred, set loading to false
        console.error('Error fetching global account list:', error);
      });
  }, []);

  return (
    <></>
    /* <div>
      <Typography variant="h4" align="center" gutterBottom>
        Global Account List
        {isLoading && <LinearProgress color="error" />}
      </Typography>
      <Grid container spacing={2}>
        {globalAccounts.map((account) => (
          <Grid item key={account.guid}>
            <Card variant="outlined" sx={{ m: 2, ':hover': { boxShadow: 6 } }}>
              <CardContent>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <AccountCircleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Display Name"
                    secondary={account.displayName || 'N/A'}
                  />
                </ListItem>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  GUID: {account.guid}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Description: {account.description || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Cost Object ID: {account.costObjectId || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  License Type: {account.licenseType || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Created Date:{' '}
                  {new Date(account.createdDate).toLocaleString() || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Last Modified Date:{' '}
                  {new Date(account.lastModifiedDate).toLocaleString() || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Subdomain: {account.subdomain || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Commercial Model: {account.commercialModel || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Consumption Based: {account.consumptionBased || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Contract Status: {account.contractStatus || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Entity State: {account.entityState || 'N/A'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Termination Notification Status:{' '}
                  {account.terminationNotificationStatus || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div> */
  );
};

export default SapGlobalAccountList;
