export interface BtpConnectionProperties {
  id: number;
  customIdp: string;
  region: string;
  email: string;
  password: string;
  createdAt: string;
  isMonitoring: boolean;
}

export interface GlobalAccountListResponse {
  costObjectId: string;
  licenseType: string;
  createdDate: number;
  lastModifiedDate: number;
  guid: string;
  displayName: string;
  description: string;
  subdomain: string;
  commercialModel: string;
  consumptionBased: boolean;
  contractStatus: string;
  entityState: string;
  terminationNotificationStatus: string;
}

export interface BtpCliResponse {
  id: number;
  classId: string;
  json: string;
  globalAccountGuid: string;
  subaccountGuid: string;
  userEmail: string;
  createdAt: string;
}

export enum CHANGE_TYPE {
  ITEM_ADDED = 'ITEM ADDED',
  VALUES_CHANGED = 'VALUE CHANGED',
  ITEM_REMOVED = 'OBJECT REMOVED'
}

export enum BTP_MONITORING_SETTINGS {
  SHOW_PATH,
  SLACK_WEBHOOK_URL
}

export interface BtpMonitoringSettingsResponse {
  showPath: string;
  slackWebhookUrl: string;
}

export interface BtpMonitoringSettingsRequest {
  showPath: string;
  slackWebhookUrl: string;
}
export const CLASS_ID_MAP: Record<string, string> = {
  AccountsEntitlementResponse: 'Entitlements of Global Account',
  AccountsEntitlementSubaccountResponse: 'Entitlements of Subaccount',
  AccountsSubscriptionSubaccountResponse: 'Subscriptions of Subaccount',
  AvailableEnvironmentSubaccountResponse:
    'Available Environments for Subaccount',
  AvailableRegionResponse: 'Available Regions',
  EnvironmentInstanceRequestSubaccountResponse:
    'Environment Instance Requests for Subaccount',
  GlobalAccountListResponse: 'List of Global Accounts',
  ResourceProviderResponse: 'Resource Providers',
  SecurityAppResponse: 'Security Applications from Global Account',
  SecurityAppSubaccountResponse: 'Security Applications from Subaccount',
  SecurityRoleSubaccountResponse: 'Security Roles from Subaccount',
  SecurityRoleResponse: 'Security Roles from Global Account',
  SecurityRoleCollectionResponse:
    'Security Role Collections from Global Account',
  SecurityRoleCollectionSubaccountResponse:
    'Security Role Collections from Subaccount',
  SecuritySettingsSubaccountResponse: 'Security Settings of Subaccount',
  SecuritySettingsResponse: 'Security Settings of Global Account',
  SecurityTokenKeySubaccountResponse: 'Token Keys from Subaccount',
  SecurityTokenKeyResponse: 'Token Keys from Global Account',
  SecurityTrustSubaccountResponse: 'Trust Configuration from Subaccount',
  SecurityTrustResponse: 'Trust Configuration from Global Account',
  ServicesOfferingSubaccountResponse: 'Service Offerings for Subaccount',
  SubaccountResponse: 'List of Subaccounts of Global Account',
  UsersGetResponse: 'User from Global account',
  UsersGetSubaccountResponse: 'User from Subdomain account',
  UsersListResponse: 'Users list from Global Account',
  UsersListSubaccountResponse: 'Users list from Subdomain Account'
};

export enum REDRAYS_VALUES {
  REDRAYS_ANY_VALUE = 'REDRAYS_ANY_VALUE',
  REDRAYS_NUMBERS = 'REDRAYS_NUMBERS',
  REDRAYS_ANY_OBJECT = 'REDRAYS_ANY_OBJECT'
}

export interface IgnoreEventsInterface {
  id: number;
  classId: string;
  changeType: string;
  changePath: string;
  globalAccountGuid: string;
  subaccountGuid: string;
  userEmail: string;
  createdAt: string;
}

export interface EventsInterface {
  id: number;
  classId: string;
  changeType: string;
  changePath: string;
  changes: string;
  newValue: string;
  oldValue: string;
  globalAccountGuid: string;
  subaccountGuid: string;
  userEmail: string;
  createdAt: Date;
}

export interface RedRaysPoliciesInterface {
  id: number;
  classId: string;
  changeType: string;
  changePath: string;
  title: string;
  businessImpact: string;
  severity: string;
  activated: boolean;
}

export interface ActivatedPoliciesInterface {
  id: number;
  classId: string;
  changeType: string;
  changePath: string;
  trackChange: string;
  globalAccountGuid: string;
  subaccountGuid: string;
  userEmail: string;
  comment: string;
  createdAt: string;
  redRaysPoliciesSettings: RedRaysPoliciesInterface;
}

export interface AlertsHistoryInterface {
  id: number;
  classId: string;
  changeType: string;
  changePath: string;
  trackChange: string;
  globalAccountGuid: string;
  subaccountGuid: string;
  userEmail: string;
  comment: string;
  createdAt: string; // You might need to adjust the type based on the format sent from the backend
  redRaysPoliciesSettings: RedRaysPoliciesInterface;
}

export interface subaccountUsers {
  subaccountGuid: string;
  subaccountStatus: string;
  subaccountUsedForProduction: string;
}

export interface UsersMap {
  guid: string;
  email: string;
}

export interface BtpDashboardMetadataInterface {
  eventCount: { [classId: string]: number };
  globalAccountGuidNameMap: { [guid: string]: string };
  subAccountGuidNameMap: { [guid: string]: string };
  usersMap: UsersMap[];
  eventCountPerDay: { [eventPerDays: string]: number };
  alertsHistoryPerDay: { [historyPerDays: string]: number };
  subaccountUsersStatus: subaccountUsers[];
}
