import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Tooltip, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import toast from 'react-hot-toast';
import { RedRaysPoliciesInterface } from '../../../api/sapBtp/sapBtpApi';
import {
  activateRedRaysRule,
  getClassIdText,
  getRedRaysPolicySettingsList,
  humanizeChangeType,
  removeRedRaysRule
} from '../../../api/sapBtp/sapBtpService';

const RecommendedPoliciesUi: React.FC = () => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<RedRaysPoliciesInterface[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const notifySuccess = (message: string) => toast.success(message);

  const fetchData = async () => {
    try {
      const response = await getRedRaysPolicySettingsList(page, pageSize);

      const updatedRows = response.content.map((alert) => ({
        ...alert,
        changePath: alert.changePath.replace('REDRAYS_', '')
      }));

      setRows(updatedRows);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error('Error fetching event logs:', error);
    }
  };

  useEffect(() => {
    setRows([]);
    fetchData();
  }, [page]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleButtonClick = async (row: RedRaysPoliciesInterface) => {
    try {
      const updatedRows = rows.map((r) =>
        r.id === row.id ? { ...r, activated: !row.activated } : r
      );
      setRows(updatedRows);

      if (row.activated) {
        await removeRedRaysRule(row.id);
        notifySuccess(`Rule "${row.title}" removed successfully`);
      } else {
        await activateRedRaysRule(row.id);
        notifySuccess(`Rule "${row.title}" activated successfully`);
      }
    } catch (error) {
      console.error('Error removing alert:', error);
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        pt: 1,
        backgroundColor: 'rgba(159,159,159,0.05)'
      }}
    >
      <Typography
        sx={{
          width: '90%',
          backgroundColor: '#ffffff',
          fontSize: 'small',
          mb: 1,
          ml: '5%',
          color: '#CACACA',
          textAlign: 'center',
          borderRadius: '4px'
        }}
      >
        The following policies developed by RedRays to track critical changes in
        SAP BTP
      </Typography>

      <Box
        sx={{
          backgroundColor: '#ffffff',
          ml: 2,
          mr: 2
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Business Impact</TableCell>
                <TableCell>BTP Object</TableCell>
                <TableCell>Change Type</TableCell>
                <TableCell>Severity</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.businessImpact}</TableCell>
                  <TableCell>{getClassIdText(row.classId)}</TableCell>
                  <TableCell>{humanizeChangeType(row.changeType)}</TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold', // This line makes the text bold
                      backgroundColor:
                        row.severity === 'HIGH' ? red[100] : 'inherit'
                    }}
                  >
                    {row.severity ?? 'INFO'}
                  </TableCell>

                  <TableCell>
                    <Tooltip
                      title={row.activated ? 'Remove Rule' : 'Activate Rule'}
                    >
                      <IconButton
                        onClick={() => handleButtonClick(row)}
                        color={row.activated ? 'error' : 'success'}
                      >
                        {row.activated ? (
                          <NotificationsOffIcon />
                        ) : (
                          <NotificationAddIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ backgroundColor: '#ffffff' }}
          component="div"
          count={totalPages * pageSize}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[10, 20, 25]}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Box>
  );
};

export default RecommendedPoliciesUi;
