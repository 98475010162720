import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material'; // Импорт иконок для статуса VPN
import { checkOk } from '../../../api/common';
import './openvpn.css';
import { getOpenvpnConfiguration, OpenvpnConfig } from './OpenvpnServices';

const Openvpn = () => {
  const [vpnPID, setVpnPID] = useState(0);
  const [openvpnConfig, setOpenvpnConfig] = useState<OpenvpnConfig>({
    configFileContent: '',
    username: '',
    password: ''
  });
  const [originalConfig, setOriginalConfig] = useState<OpenvpnConfig>({
    configFileContent: '',
    username: '',
    password: ''
  });
  const [isConfigChanged, setIsConfigChanged] = useState(false);

  const getVpnConfiguration = useCallback(async () => {
    try {
      const config = await getOpenvpnConfiguration();
      setOpenvpnConfig(config);
      setOriginalConfig(config);
    } catch (error) {
      console.error('Error fetching VPN configuration:', error);
    }
  }, []);

  useEffect(() => {
    getVpnConfiguration();
  }, []);

  const startVpn = () => {
    return fetch('/api/v1/settings/openvpn/start', { method: 'GET' }).then(
      checkOk
    );
  };

  const stopVpn = () => {
    return fetch('/api/v1/settings/openvpn/stop', { method: 'GET' }).then(
      checkOk
    );
  };

  const statusVpn = useCallback(() => {
    return fetch('/api/v1/settings/openvpn/status', { method: 'GET' })
      .then(checkOk)
      .then((response) => response.json());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      statusVpn().then((data) => {
        const pid = data;
        if (pid > 0) {
          setVpnPID(pid);
        } else {
          setVpnPID(0);
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [statusVpn]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOpenvpnConfig((prevState) => ({
      ...prevState,
      [name]: value
    }));
    setIsConfigChanged(true);
  };

  const saveOpenvpnConfiguration = (config: OpenvpnConfig) => {
    return fetch('/api/v1/settings/openvpn/configuration/save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(config)
    }).then(checkOk);
  };

  const handleSave = () => {
    saveOpenvpnConfiguration(openvpnConfig);
    setIsConfigChanged(false);
  };

  return (
    <Grid container spacing={3} sx={{ width: '100%' }}>
      <Grid
        item
        lg={12}
        spacing={3}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Typography variant="h4">OpenVPN Configuration</Typography>
        <TextareaAutosize
          minRows={15}
          maxRows={15}
          placeholder="Config File Content"
          value={openvpnConfig.configFileContent}
          onChange={(e) =>
            setOpenvpnConfig((prevState) => ({
              ...prevState,
              configFileContent: e.target.value
            }))
          }
          onResize={() => {
            console.log(1);
          }} // Placeholder function
          onResizeCapture={() => {
            console.log(2);
          }} // Placeholder function
          style={{ width: '100%', fontSize: 16 }}
        />
        <TextField
          fullWidth
          label="Username"
          variant="outlined"
          name="username"
          sx={{ mt: 3 }}
          value={openvpnConfig.username}
          onChange={handleInputChange}
        />
        <TextField
          sx={{ mt: 3 }}
          fullWidth
          label="Password"
          variant="outlined"
          name="password"
          type="password"
          value={openvpnConfig.password}
          onChange={handleInputChange}
        />
        <Button
          variant="contained"
          onClick={startVpn}
          color="success"
          disabled={vpnPID !== 0}
          sx={{ mr: 2, mt: 2 }}
        >
          Start VPN
        </Button>
        <Button
          variant="contained"
          onClick={stopVpn}
          color="error"
          disabled={vpnPID === 0}
          sx={{ mr: 2, mt: 2 }}
        >
          Stop VPN
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          disabled={!isConfigChanged}
          sx={{ mt: 2 }}
        >
          Save
        </Button>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          {vpnPID !== 0 ? (
            <CheckCircleOutline
              sx={{ color: 'green', fontSize: 20, marginRight: 1 }}
            />
          ) : (
            <HighlightOff sx={{ color: 'red', fontSize: 20, marginRight: 1 }} />
          )}
          <Typography>
            VPN Status: {vpnPID !== 0 ? 'Active' : 'Inactive'}
          </Typography>
        </Box>
        {vpnPID !== 0 && (
          <Typography sx={{ mt: 1 }}>VPN Process ID: {vpnPID}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Openvpn;
