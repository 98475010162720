import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import formatDateTime from './formatDateTime';

const emptyState = { host: '', mask: '' };
const NetMaskScanModal = ({
  opened,
  handleApply,
  handleClose
}: {
  opened: boolean;
  handleApply: any;
  handleClose: any;
}): JSX.Element => {
  const [formState, setFormState] = React.useState(emptyState);

  const netMask = () => {
    const numberArray = [];
    for (let i = 24; i <= 32; i++) {
      numberArray.push(i.toString());
    }
    return numberArray;
  };

  const handleInputChange = (
    e: React.ChangeEvent<any>,
    value?: any,
    name?: string
  ) => {
    const targetName = name || e.currentTarget.name;
    const targetValue = value || e.currentTarget.value;

    setFormState({
      ...formState,
      [targetName]: targetValue
    });
  };

  const generateName = (): string =>
    formState.host &&
    formState.mask &&
    `${formState.host}/${formState.mask} | ${formatDateTime(new Date())}`;

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '30%',
          maxHeight: 435,
          overflowY: 'hidden'
        }
      }}
      maxWidth="xs"
      fullScreen
      open={opened}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          backgroundColor: 'white',
          color: 'black',
          fontSize: '1.2rem', // Increase font size of the title
          mb: 2 // Add margin bottom between title and input label
        }}
      >
        <Typography variant="h2">Add Host with Network Mask</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: 'absolute', // Position it absolutely relative to dialog
            right: 24, // Right offset
            top: 16 // Top offset
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>
      <DialogContent>
        <Typography>
          Enter network IP and choose network mask (example: 192.168.0.1 and
          choose 25 network mask)
        </Typography>
        <TextField
          style={{ marginBottom: '20px' }}
          id="input-host"
          required
          name="host"
          label="IP: 1.1.1.1"
          onChange={(e) => handleInputChange(e)}
          fullWidth
          sx={{ mt: 1 }}
        />
        <Autocomplete
          style={{ marginBottom: '20px' }}
          id="combo-box-demo"
          getOptionLabel={(option: any) => option || ''}
          options={netMask()}
          onChange={(e, value) => handleInputChange(e, value, 'mask')}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              name="mask"
              label="Network Mask: /24"
            />
          )}
        />
        {formState.host && formState.mask ? (
          <Typography>
            <div>
              <Typography>
                The network will be created with the following name*:
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }}>
                {generateName()}
              </Typography>
              <Typography sx={{ mt: 1 }}>
                *After network saving, you can change the network name
              </Typography>
            </div>
          </Typography>
        ) : (
          ''
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={() =>
            handleApply(generateName(), `${formState.host}/${formState.mask}`)
          }
        >
          Create Network
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
export default NetMaskScanModal;
