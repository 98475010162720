import * as React from 'react';
import { Tooltip, Typography } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValueGetterParams
} from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import { useInterval } from 'usehooks-ts';
import toast from 'react-hot-toast';
import { NetworkMetadata, NetworkStatus } from '../../api/network/treeApi';
import { getSapNotesNetworksMetadata } from '../../api/network/networkService';
import theme from '../../theme';
import SapNotesPossibleAreNotInstalled from '../../api/sapnotes/serviceApi';
import { getSapNotes } from '../../api/sapnotes/serviceService';
import HotToast from '../notifications/HotToast';

interface Props {
  onChange: (networkMetadata: NetworkMetadata) => void;
  selectedId: number | undefined;
  forceReloadNeeded?: boolean;
  onAfterReloaded?: (networkMetadata: NetworkMetadata | undefined) => void;
  hasDefaultFilter?: boolean;
  allowSelectNetworksWithErrors?: boolean;
}

function FilterToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton
        onResize={() => console.log('Resized')}
        onResizeCapture={() => console.log('Resized captured')}
      />{' '}
    </GridToolbarContainer>
  );
}

const initialGridState = {
  initialState: {
    filter: {}
  }
};

const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  }
});

const SapNotesNetworkSelector: React.FC<Props> = ({
  onChange,
  selectedId,
  forceReloadNeeded,
  onAfterReloaded,
  hasDefaultFilter = false,
  allowSelectNetworksWithErrors = true
}: Props) => {
  const classes = useStyles();

  const [networksMetadata, setNetworksMetadata] = React.useState<
    NetworkMetadata[]
  >([]);

  const reload = () => {
    getSapNotesNetworksMetadata().then((aNetworksMetadata) => {
      setNetworksMetadata(
        aNetworksMetadata.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      );
      const selectedNetworkMetadata = aNetworksMetadata.find(
        (x) => x.id === selectedId
      );
      onAfterReloaded?.(selectedNetworkMetadata);
    });
  };

  useInterval(reload, 5000);

  React.useEffect(() => {
    const doesSelectedIdHaveMetadataLoaded =
      networksMetadata.find((x) => x.id === selectedId) !== undefined;
    if (forceReloadNeeded || !doesSelectedIdHaveMetadataLoaded) {
      reload();
    }
  }, [forceReloadNeeded, selectedId]);

  const convertToCSV = (data: SapNotesPossibleAreNotInstalled[]) => {
    const header = `${Object.keys(data[0]).join(',')}\n`;
    const rows = data.map((row) => `${Object.values(row).join(',')}\n`);
    return header + rows.join('');
  };

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  function handleDownloadClick(id: number) {
    getSapNotes(id)
      .then((data) => {
        if (data.length === 0) {
          notifyError('Exported File is Empty');
          console.log('DATA NULL');
          return;
        }
        const csvData = convertToCSV(data);

        const csvBlob = new Blob([csvData], { type: 'text/csv' });

        // Create a download link
        const link = document.createElement('a');
        const url = URL.createObjectURL(csvBlob);

        link.href = url;
        link.setAttribute(
          'download',
          `${networksMetadata[0].id}_${networksMetadata[0].name}.csv`
        );
        document.body.appendChild(link);

        // Trigger a click on the link to initiate the download
        link.click();

        // Clean up by removing the link and revoking the URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        notifySuccess('The file export was successful.');
      })
      .catch((error) => console.error('Error fetching data:', error));
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Network',
      flex: 10,
      valueGetter: (params: GridValueGetterParams) => params.row,
      renderCell: (params: GridCellParams) => (
        <div>
          <Typography variant="body1" color="textPrimary">
            {params.value.name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Created on:{' '}
            {new Date(params.value.createdAt as string).toDateString()}
          </Typography>
        </div>
      )
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      valueGetter: (params: GridValueGetterParams) => params.row,
      renderCell: (params: GridCellParams) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%' // Ensure the container takes up the full cell height
          }}
        >
          <Tooltip title="Download Report in CSV">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SaveAltIcon
                style={{
                  cursor: 'pointer',
                  fontSize: '0.9rem',
                  color: 'green'
                }}
                onClick={() => handleDownloadClick(params.value.id)}
              />
              <Typography
                variant="body2"
                style={{
                  fontSize: '0.9rem',
                  color: 'green',
                  cursor: 'pointer'
                }}
                sx={{ ml: 1 }}
                onClick={() => handleDownloadClick(params.value.id)}
              >
                Report
              </Typography>
            </div>
          </Tooltip>
        </div>
      )
    }
  ];

  return (
    <>
      <DataGrid
        className={classes.root}
        {...(hasDefaultFilter ? initialGridState : {})}
        sx={{
          border: 0, // Remove the border
          '& .MuiDataGrid-main': {
            borderColor: 'transparent' // Remove cell borders
          },
          // If you want to remove the border for the header as well
          '& .MuiDataGrid-columnHeaders': {
            borderColor: 'transparent' // Remove header borders
          },
          flexGrow: 1,
          '& .MuiDataGrid-virtualScrollerContent': {
            backgroundColor: theme.palette.background.paper
          },
          '&.MuiDataGrid-root': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
            {
              display: 'none'
            },
          boxShadow: 0.5,
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer'
          }
        }}
        rows={networksMetadata}
        columns={columns}
        onRowClick={(params) => {
          const networkMetadata = networksMetadata.filter(
            (it) => it.id === params.id
          )[0];
          if (
            allowSelectNetworksWithErrors ||
            [NetworkStatus.ORIGINAL, NetworkStatus.SCANNED].includes(
              networkMetadata.status
            )
          ) {
            onChange(networkMetadata);
          }
        }}
        selectionModel={selectedId}
        hideFooterSelectedRowCount
      />
      <HotToast />
    </>
  );
};

export default SapNotesNetworkSelector;
