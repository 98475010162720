import { checkOk } from '../common';
import { ServiceMetadata } from './serviceApi';

// eslint-disable-next-line import/prefer-default-export
export function getServicesMetadata(): Promise<ServiceMetadata[]> {
  return fetch('/api/v1/services/metadata', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(checkOk)
    .then((response) => response.json());
}
