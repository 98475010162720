import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getOverallAvatar } from './OverallSwitch';

const SystemsOverallRisk = (props) => {
  const [overallRisk, setOverallRisk] = useState();
  useEffect(() => {
    fetch(`/api/v1/vulnerability-assessment/stats/overallrisk`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        return response;
      })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setOverallRisk(res);
      })
      .catch((reason) => console.log(reason));
  }, []);

  return (
    <Card sx={{ height: '100%' }} {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              SAP Landscape Overall risk
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {String(overallRisk).concat('%')}
            </Typography>
          </Grid>
          <Grid item>{getOverallAvatar(overallRisk)}</Grid>
        </Grid>
        <Box sx={{ pt: 3 }}>
          <LinearProgress value={overallRisk} variant="determinate" />
        </Box>
      </CardContent>
    </Card>
  );
};
export default SystemsOverallRisk;
