import React from 'react';
import { FormControlLabel, Switch } from '@mui/material';

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
}

function SslSelector({ value, onChange }: Props): JSX.Element {
  return (
    <FormControlLabel
      control={
        <Switch
          color="success"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      label="SSL"
    />
  );
}

export default SslSelector;
