import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Divider, Grid, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import toast from 'react-hot-toast';
import { editJavaSystemsNetwork } from './services/JavaSystemsServices';
import { JavaSystemsConnectionMetadata } from './interface/DatabaseConnectionInterface';
import HotToast from '../../notifications/HotToast';

interface JavaSystemsDialogProps {
  action: string;
  open: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
  onTest: (data: any) => void;
  initialSystemData?: JavaSystemsConnectionMetadata | null;
}

const JavaSystemsDialog: React.FC<JavaSystemsDialogProps> = ({
  action,
  open,
  onClose,
  onSave,
  onTest,
  initialSystemData
}) => {
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  const [formData, setFormData] = useState({
    id: 0,
    host: '',
    port: 0,
    username: '',
    password: '',
    dbName: '',
    type: 'ASE',
    encryptedPassword: false,
    hanaEncrypted: false,
    validateCertificate: false
  });

  useEffect(() => {
    if (initialSystemData && action === 'update') {
      setFormData(initialSystemData);
    } else {
      setFormData({
        id: 0,
        host: '',
        port: 0,
        username: '',
        password: '',
        dbName: '',
        type: 'ASE',
        encryptedPassword: false,
        hanaEncrypted: false,
        validateCertificate: false
      });
    }
  }, [initialSystemData]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    const isCheckbox = type === 'checkbox';

    setFormData((prevData) => ({
      ...prevData,
      [name]: isCheckbox ? (event.target as HTMLInputElement).checked : value
    }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    if (name === 'type') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        encryptedPassword: false,
        hanaEncrypted: false,
        validateCertificate: false
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSave = () => {
    notifySuccess('Network has been saved');
    onSave(formData);

    onClose();
  };

  const handleTest = () => {
    onTest(formData);
  };

  const handleEditSave = () => {
    console.log('handle edit and save');

    const networkData: JavaSystemsConnectionMetadata = {
      id: formData.id,
      host: formData.host,
      port: formData.port,
      username: formData.username,
      password: formData.password,
      dbName: formData.dbName,
      type: formData.type,
      encryptedPassword: formData.encryptedPassword,
      hanaEncrypted: formData.hanaEncrypted,
      validateCertificate: formData.validateCertificate,
      hashesCount: 0,
      createdAt: new Date().toISOString(),
      comment: ''
    };

    editJavaSystemsNetwork(formData.id, networkData)
      .then((response) => {
        notifySuccess('Network has been updated');
        console.log('Network information updated successfully:', response);
      })
      .catch((error) => {
        notifyError('Failed to update network information');
        console.error('Failed to update network information:', error);
      });

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: 'white',
          color: 'black',
          fontSize: '1.2rem', // Increase font size of the title
          mb: 2 // Add margin bottom between title and input label
        }}
      >
        <Typography variant="h1">
          {initialSystemData ? `Edit System ${formData.host}` : 'Add System'}
        </Typography>
        <Divider sx={{ mt: 2 }} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Host"
              name="host"
              value={formData.host}
              onChange={handleInputChange}
              fullWidth
              autoComplete="off"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Port"
              name="port"
              value={formData.port}
              onChange={handleInputChange}
              fullWidth
              autoComplete="off"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              fullWidth
              autoComplete="off"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              fullWidth
              autoComplete="off"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Database Name"
              name="dbName"
              value={formData.dbName}
              onChange={handleInputChange}
              fullWidth
              autoComplete="off"
              margin="normal"
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }}>
            <InputLabel>
              <></>
            </InputLabel>
            <Select
              name="type"
              value={formData.type}
              onChange={handleSelectChange}
              fullWidth
            >
              <MenuItem value="ASE">ASE</MenuItem>
              <MenuItem value="HANA">HANA</MenuItem>
              <MenuItem value="ORACLE" disabled>
                ORACLE
              </MenuItem>
            </Select>
          </Grid>

          {formData.type === 'ASE' && (
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="encryptedPassword"
                    checked={formData.encryptedPassword}
                    onChange={handleInputChange}
                  />
                }
                label="Encrypted Password"
              />
            </Grid>
          )}

          <Grid item xs={6}>
            {formData.type === 'HANA' && (
              <FormControlLabel
                control={
                  <Checkbox
                    name="hanaEncrypted"
                    checked={formData.hanaEncrypted}
                    onChange={handleInputChange}
                  />
                }
                label="HANA Encrypted"
              />
            )}
          </Grid>

          {formData.type === 'HANA' && (
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="validateCertificate"
                    checked={formData.validateCertificate}
                    onChange={handleInputChange}
                  />
                }
                label="Validate Certificate"
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color={initialSystemData ? 'warning' : 'success'}
            onClick={initialSystemData ? handleEditSave : handleSave}
          >
            {initialSystemData ? 'Update' : 'Save'}
          </Button>

          <Button
            variant="contained"
            color="info"
            onClick={handleTest}
            sx={{ ml: 3 }}
          >
            Test Connection
          </Button>
        </Grid>
      </DialogContent>
      <HotToast />
    </Dialog>
  );
};

export default JavaSystemsDialog;
