import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import ServiceSelector from './ServiceSelector';
import { ServiceMetadata } from '../../../../../../api/service/serviceApi';
import { Service } from '../../../../../../api/network/treeApi';
import UsernameNode from './property/UsernameNode';
import SslSelector from './property/SslSelector';
import PortSelector from './property/PortSelector';
import PasswordNode from './property/PasswordNode';
import AnonymousSelector from './property/AnonymousSelector';
import { getServicesMetadata } from '../../../../../../components/services/ServicesMetadataLoader';

interface Props {
  service: Service;
  onChange: (service: Service) => void;
  onDelete: () => void;
}

const ServiceNode: React.FC<Props> = ({
  service,
  onChange,
  onDelete
}: Props) => {
  const [serviceMetadata, setServiceMetadata] = React.useState<
    ServiceMetadata | undefined
  >(
    getServicesMetadata().filter(
      (metadata) => metadata.id === service.templateId
    )[0]
  );

  React.useEffect(() => {
    setServiceMetadata(
      getServicesMetadata().filter(
        (metadata) => metadata.id === service.templateId
      )[0]
    );
  }, [service.templateId]);

  const anonymous =
    (serviceMetadata?.anonymousOnly ?? true) || (service.anonymous ?? true);

  return (
    <Paper
      elevation={1}
      sx={{ p: 3, width: '100%', height: '100%', ':hover': { boxShadow: 6 } }}
    >
      <Grid container spacing={2}>
        <Grid item xs={11} sm={12}>
          <ServiceSelector
            onChange={(aServiceMetadata: ServiceMetadata) => {
              onChange({ ...service, templateId: aServiceMetadata.id });
            }}
            selectedId={service.templateId}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <PortSelector
            value={`${service.port ?? ''}`}
            onChange={(port: number) => onChange({ ...service, port })}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <></>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <SslSelector
            value={service.secure}
            onChange={(secure: boolean) => onChange({ ...service, secure })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <AnonymousSelector
            anonymousOnly={serviceMetadata?.anonymousOnly ?? true}
            anonymous={anonymous}
            onChange={(anAnonymous) =>
              onChange({ ...service, anonymous: anAnonymous })
            }
          />
        </Grid>

        {!anonymous && (
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sm={6} xl={6}>
              <UsernameNode
                value={service.username}
                onChange={(username: string) =>
                  onChange({ ...service, username })
                }
                serviceId={service.templateId}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <PasswordNode
                value={service.password}
                onChange={(password: string) =>
                  onChange({ ...service, password })
                }
              />
            </Grid>
            {service.templateId === 'SICF' && (
              <Typography sx={{ mt: 2 }} variant="h6">
                To define &apos;sap-client&apos;, please write it before
                username (example: 000:SAP*).
              </Typography>
            )}
          </Grid>
        )}
        <Grid item xl={12}>
          <></>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            sx={{ width: '100%' }}
            onClick={onDelete}
          >
            Delete Service
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default ServiceNode;
