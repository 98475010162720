import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField, Typography } from '@mui/material';
import Check from './Check';
import ColoredEnumSelector from '../../components/coloredenumselector/ColoredEnumSelector';
import { getStatusColor, Status } from './Status';
import {
  RowDialog,
  RowDialogProps
} from '../../components/reportgrid/ReportGrid';
import formatDateTime from '../../components/formatDateTime';

const CheckDialog: RowDialog<Check> = ({
  selectedRow,
  opened,
  handleClose,
  reloadReport
}: RowDialogProps<Check>) => {
  return (
    <>
      {selectedRow && opened && (
        <Dialog
          open={opened}
          onClose={handleClose}
          aria-labelledby="check-dialog-title"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="check-dialog-title"
            sx={{
              backgroundColor: getStatusColor(selectedRow.status),
              color: 'white',
              fontSize: '1.2rem', // Increase font size of the title
              mb: 2 // Add margin bottom between title and input label
            }}
          >
            <Typography variant="h2">{selectedRow.title}</Typography>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={3} sx={{ pt: 1 }}>
              <Grid item xs={12}>
                <ColoredEnumSelector<Status, Check>
                  id="check-dialog-status"
                  property="status"
                  EnumValues={Status}
                  getColor={getStatusColor}
                  entity={selectedRow}
                  reloadReport={reloadReport}
                  readonly
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="check-dialog-name"
                  label="Parameter name"
                  value={selectedRow.name}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="check-dialog-value"
                  label="Value from SAP"
                  value={selectedRow.value}
                  multiline
                  fullWidth
                />{' '}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="check-dialog-title"
                  label="Title"
                  value={selectedRow.title}
                  multiline
                  fullWidth
                />{' '}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="check-dialog-description"
                  label="Description"
                  value={selectedRow.description}
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="check-dialog-solution"
                  label="Solution"
                  value={selectedRow.solution}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="check-dialog-solution"
                  label="Founded at"
                  value={formatDateTime(new Date(selectedRow.createdAt))}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CheckDialog;
