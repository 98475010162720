import { checkOk } from '../../../../api/common';
import { JavaSystemsConnectionMetadata } from '../interface/DatabaseConnectionInterface';
import { BruteforcePatternInterface } from '../interface/BruteforcePatternInterface';
import { JavaSystemHashesInterface } from '../interface/JavaSystemHashesInterface';

export function getJavaSystemsNetworkMetadata() {
  return fetch('/api/v1/java/bruteforce/systems/list', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function getStatusOfBruteforce() {
  return fetch('/api/v1/java/bruteforce/systems/status', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function stopBruteforce() {
  return fetch('/api/v1/java/bruteforce/systems/hashes/stop', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function deleteJavaSystemsNetwork(networkId: number) {
  return fetch(`/api/v1/java/bruteforce/systems/delete/${networkId}`, {
    method: 'DELETE'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function getJavaSystemsHashes(
  networkId: number
): Promise<JavaSystemHashesInterface[]> {
  return fetch(`/api/v1/java/bruteforce/systems/hashes/get/${networkId}`, {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.json());
}

export function startBruteforceJavaSystemsHashes(
  networkId: number,
  bruteforcePattern: BruteforcePatternInterface
) {
  return fetch(`/api/v1/java/bruteforce/systems/hashes/start/${networkId}`, {
    method: 'POST',
    body: JSON.stringify(bruteforcePattern),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function downloadCrackedJavaSystemsPasswords(networkId: number) {
  return fetch(
    `/api/v1/java/bruteforce/systems/download/cracked/passwords/${networkId}`,
    {
      method: 'GET'
    }
  )
    .then(checkOk)
    .then((response) => response.json());
}

export function checkCrackedJavaSystemsPasswords(networkId: number) {
  return fetch(
    `/api/v1/java/bruteforce/systems/download/cracked/passwords/check/${networkId}`,
    {
      method: 'GET'
    }
  )
    .then(checkOk)
    .then((response) => response.json());
}

export async function getProgress() {
  return fetch('/api/v1/java/bruteforce/systems/progress', {
    method: 'GET'
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function saveJavaSystemsNetwork(item: JavaSystemsConnectionMetadata) {
  return fetch('/api/v1/java/bruteforce/systems/save', {
    method: 'POST',
    body: JSON.stringify(item),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function editJavaSystemsNetwork(
  networkId: number,
  item: JavaSystemsConnectionMetadata
) {
  return fetch(`/api/v1/java/bruteforce/systems/edit/${networkId}`, {
    method: 'PUT',
    body: JSON.stringify(item),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(checkOk)
    .then((response) => response.text());
}

export function testConnectionOfJavaSystemsNetwork(
  item: JavaSystemsConnectionMetadata
) {
  return fetch('/api/v1/java/bruteforce/systems/connection/test', {
    method: 'POST',
    body: JSON.stringify(item),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((response) => response.text());
}

export default getJavaSystemsNetworkMetadata;
