import React, { useEffect, useState } from 'react';
import { green, red } from '@mui/material/colors';
import { Box, Grid, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Cancel, CheckCircle } from '@mui/icons-material';
import Button from '@mui/material/Button';
import toast from 'react-hot-toast';
import { activateLicense, getLicenseInfo } from './LicensingService';
import { LicenseInterface } from './LicenseInterface';
import formatDateTime from '../../formatDateTime';
import HotToast from '../../notifications/HotToast';

const Licensing = () => {
  const [licenseInfo, setLicenseInfo] = useState<LicenseInterface>();
  const [licenseText, setLicenseText] = useState<string>();

  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);

  function updateLicenseInfo() {
    getLicenseInfo().then((resp) => {
      if (resp !== undefined) {
        setLicenseInfo(resp);
        setLicenseText(resp.licenseKey);
      }
    });
  }

  useEffect(() => {
    updateLicenseInfo();
  }, []);

  function handleActivate() {
    if (licenseText !== undefined) {
      activateLicense(licenseText).then((resp) => {
        if (resp === 'activated') {
          notifySuccess('The Platform license is activated');
          updateLicenseInfo();
        }
        if (resp === 'fail') {
          notifyError('The Platform license is not activated');
        }
      });
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography sx={{ pb: 2 }} variant="h3">
          License Information
        </Typography>

        <Typography variant="h6">
          Please enter the activation code below:
        </Typography>
        <TextField
          type="password"
          fullWidth
          value={licenseText}
          onChange={(event) => setLicenseText(event.target.value)}
          variant="outlined"
          multiline
          rows={8}
          placeholder="Activation code"
        />
      </Grid>

      <Grid item lg={12} sm={6} xl={12} xs={12}>
        <Button
          sx={{ backgroundColor: green[500] }}
          type="submit"
          variant="contained"
          size="medium"
          onClick={() => {
            handleActivate();
          }}
        >
          Activate
        </Button>
      </Grid>
      <Grid item xs={12}>
        {licenseInfo && (
          <Box sx={{ mt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Expiration time:</strong>{' '}
                  {licenseInfo?.expired
                    ? formatDateTime(new Date(licenseInfo?.expired))
                    : 'License not activated'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Organization:</strong>{' '}
                  {licenseInfo?.orgName || 'License not activated'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>License Type:</strong>{' '}
                  {licenseInfo?.type || 'License not activated'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Port and Service Scan:</strong>{' '}
                  {licenseInfo?.ps > 0 ? (
                    <CheckCircle style={{ color: green[500] }} sx={{ pt: 1 }} />
                  ) : (
                    <Cancel style={{ color: red[500] }} sx={{ pt: 1 }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Vulnerability Assessment:</strong>{' '}
                  {licenseInfo?.va > 0 ? (
                    <CheckCircle style={{ color: green[500] }} sx={{ pt: 1 }} />
                  ) : (
                    <Cancel style={{ color: red[500] }} sx={{ pt: 1 }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Vulnerability Management:</strong>{' '}
                  {licenseInfo?.vm > 0 ? (
                    <CheckCircle style={{ color: green[500] }} sx={{ pt: 1 }} />
                  ) : (
                    <Cancel style={{ color: red[500] }} sx={{ pt: 1 }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Threat Modeling:</strong>{' '}
                  {licenseInfo?.tm > 0 ? (
                    <CheckCircle style={{ color: green[500] }} sx={{ pt: 1 }} />
                  ) : (
                    <Cancel style={{ color: red[500] }} sx={{ pt: 1 }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Profile Parameters:</strong>{' '}
                  {licenseInfo?.pp > 0 ? (
                    <CheckCircle style={{ color: green[500] }} sx={{ pt: 1 }} />
                  ) : (
                    <Cancel style={{ color: red[500] }} sx={{ pt: 1 }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Outdated Components:</strong>{' '}
                  {licenseInfo?.oc > 0 ? (
                    <CheckCircle style={{ color: green[500] }} sx={{ pt: 1 }} />
                  ) : (
                    <Cancel style={{ color: red[500] }} sx={{ pt: 1 }} />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Passwords Bruteforce:</strong>{' '}
                  {licenseInfo?.bf > 0 ? (
                    <CheckCircle style={{ color: green[500] }} sx={{ pt: 1 }} />
                  ) : (
                    <Cancel style={{ color: red[500] }} sx={{ pt: 1 }} />
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4} sx={{ mt: 1 }}>
                <Typography>
                  <strong>IP number limit:</strong>{' '}
                  {licenseInfo?.ipCount || 'License not activated'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
      <HotToast />
    </Grid>
  );
};
export default Licensing;
