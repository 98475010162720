import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; // Icon for Start Bruteforce
import StopIcon from '@mui/icons-material/Stop'; // Icon for Stop Bruteforce
import GetAppIcon from '@mui/icons-material/GetApp'; // Icon for Download
import AddIcon from '@mui/icons-material/Add'; // Icon for ADD
import UpdateIcon from '@mui/icons-material/Update'; // Icon for UPDATE
import TableHead from '@mui/material/TableHead';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import JavaSystemsDialog from './JavaSystemsDialog';
import {
  checkCrackedJavaSystemsPasswords,
  deleteJavaSystemsNetwork,
  downloadCrackedJavaSystemsPasswords,
  getJavaSystemsHashes,
  getJavaSystemsNetworkMetadata,
  getProgress,
  getStatusOfBruteforce,
  saveJavaSystemsNetwork,
  startBruteforceJavaSystemsHashes,
  stopBruteforce,
  testConnectionOfJavaSystemsNetwork
} from './services/JavaSystemsServices';
import { JavaSystemsConnectionMetadata } from './interface/DatabaseConnectionInterface';
import { JavaSystemHashesInterface } from './interface/JavaSystemHashesInterface';
import BruteforceOptionDialog from '../abap/BruteforceOptionDialog';
import {
  getBruteforceLibrary,
  getBruteforcePattern
} from '../abap/service/BruteforcePasswordService';
import { BruteforcePatternInterface } from './interface/BruteforcePatternInterface';
import JavaSystemsCrackedPasswords from './interface/JavaSystemsCrackedPasswords';
import HotToast from '../../notifications/HotToast';

const JavaSystemsUI: React.FC = () => {
  const [progress, setProgress] = useState(0);
  const notifySuccess = (message: string) => toast.success(message);
  const notifyError = (message: string) => toast.error(message);
  const [javaSystems, setJavaSystems] = useState<
    JavaSystemsConnectionMetadata[]
  >([]);
  const [selectedSystem, setSelectedSystem] =
    useState<JavaSystemsConnectionMetadata | null>(null);
  const [deletionCount, setDeletionCount] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [bruteforcePattern, setBruteforcePattern] =
    useState<BruteforcePatternInterface>({
      library: '',
      customPassword: '',
      bruteforcePattern: ''
    });
  const [hashes, setHashes] = useState<JavaSystemHashesInterface[]>([]);
  const [isGetHashButtonEnabled, setIsGetHashButtonEnabled] = useState(false);
  const [isAvailablePasswords, setIsAvailablePasswords] = useState(false);
  const [action, setAction] = useState(String);
  const [openBruteforceDialog, setOpenBruteforceDialog] = useState(false);
  const [bruteforceHasBeenStarted, setBruteforceHasBeenStarted] =
    useState<boolean>(false);
  const [libraries, setLibraries] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [isEmptyHashes, setIsEmptyHashes] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      getProgress().then((resp2) => {
        setProgress(parseFloat(resp2));
        if (!bruteforceHasBeenStarted && parseFloat(resp2) === 100) {
          notifySuccess('Bruteforce finished!');
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [getProgress]);

  useEffect(() => {
    getBruteforceLibrary()
      .then((data) => {
        console.log(data);
        setLibraries(data);
      })
      .catch((error) => console.error('GET request failed', error));
  }, []);

  const handleCheckCrackedPasswords = (networkId: number | undefined) => {
    if (networkId !== undefined) {
      checkCrackedJavaSystemsPasswords(networkId)
        .then((r) => {
          if (r) setIsAvailablePasswords(true);
          else setIsAvailablePasswords(false);
        })
        .catch((error) =>
          console.error('Failed to check Java System Passwords:', error)
        );
    }
  };

  useEffect(() => {
    getBruteforcePattern()
      .then((data) => {
        console.log(data);
        setPattern(data);
      })
      .catch((error) => console.error('GET request failed', error));
  }, []);

  const handleGetHash = () => {
    if (selectedSystem) {
      getJavaSystemsHashes(selectedSystem.id)
        .then((data) => {
          if (data.length === 0) {
            setIsEmptyHashes(true);
            notifyError('No hashes loaded from the SAP system');
          } else {
            setIsEmptyHashes(false);
            notifySuccess(`Hash for the ${selectedSystem.host} loaded`);
            setHashes(data);
          }
        })
        .catch((error) => {
          notifyError('Failed to fetch hashes');
          console.error('Failed to fetch hashes:', error);
        });
    }
  };

  const convertToCSV = (data: JavaSystemsCrackedPasswords[]) => {
    const header = `${Object.keys(data[0]).join(',')}\n`;
    const rows = data.map((row) => {
      const rowValues = Object.values(row).map((value) => {
        const stringValue = String(value); // Convert the value to a string
        if (stringValue.includes(',')) {
          return `"${stringValue}"`;
        }
        return stringValue;
      });
      return `${rowValues.join(',')}\n`;
    });
    return header + rows.join('');
  };

  const downloadPasswords = () => {
    if (selectedSystem) {
      downloadCrackedJavaSystemsPasswords(selectedSystem.id)
        .then((data: JavaSystemsCrackedPasswords[]) => {
          const csvData = convertToCSV(data);

          const csvBlob = new Blob([csvData], { type: 'text/csv' });

          // Create a download link
          const link = document.createElement('a');
          const url = URL.createObjectURL(csvBlob);

          link.href = url;
          link.setAttribute(
            'download',
            `${selectedSystem.host}_${selectedSystem.port}.csv`
          );
          document.body.appendChild(link);

          // Trigger a click on the link to initiate the download
          link.click();

          // Clean up by removing the link and revoking the URL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error('Failed to fetch cracked passwords:', error);
        });
      notifySuccess(
        `Passwords file for the ${selectedSystem.host} system created`
      );
    }
  };

  const downloadHashes = (localHashes: JavaSystemHashesInterface[]) => {
    if (localHashes && localHashes.length > 0) {
      const hashText = localHashes.map((hashRow) => hashRow.hash).join('\n');
      const blob = new Blob([hashText], { type: 'text/plain;charset=utf-8' });

      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.setAttribute('download', `hashes_${selectedSystem?.host}.txt`);
      document.body.appendChild(link);

      // Trigger a click on the link to initiate the download
      link.click();

      // Clean up by removing the link and revoking the URL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
    if (selectedSystem?.host !== undefined)
      notifySuccess(
        `Hashes file for the ${selectedSystem.host} system created`
      );
  };

  const fetchData = () => {
    getJavaSystemsNetworkMetadata()
      .then((data) => setJavaSystems(data))
      .catch((error) =>
        console.error('Failed to fetch Java Systems data:', error)
      );
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 3000);
    return () => clearInterval(intervalId);
  }, [deletionCount]);

  const handleRowClick = (system: JavaSystemsConnectionMetadata) => {
    if (selectedSystem?.id !== system.id) {
      setHashes([]);
      setIsAvailablePasswords(false);
      setSelectedSystem(system);
      setIsEmptyHashes(false);
    }
  };

  useEffect(() => {
    handleGetHash();
    handleCheckCrackedPasswords(selectedSystem?.id);
  }, [selectedSystem?.id]);

  const handleCheckBruteforceStatus = () => {
    getStatusOfBruteforce().then((r) => {
      const isBruteforceStarted = r === 'true';
      setBruteforceHasBeenStarted(isBruteforceStarted);
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleCheckBruteforceStatus();
      handleCheckCrackedPasswords(selectedSystem?.id);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      await handleCheckCrackedPasswords(selectedSystem?.id);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [selectedSystem, handleCheckCrackedPasswords]);

  const handleDelete = (networkId: number) => {
    deleteJavaSystemsNetwork(networkId)
      .then(() => {
        notifySuccess('The network has been deleted');
        setHashes([]);
        setSelectedSystem(null);
        setDeletionCount((count) => count + 1);
      })
      .catch((error) => console.error('Failed to delete Java System:', error));
  };

  const handleEdit = (id: number) => {
    const systemToEdit = javaSystems.find((system) => system.id === id);
    setSelectedSystem(systemToEdit || null);
    setIsDialogOpen(true);
  };

  const systemDetails = selectedSystem
    ? JSON.stringify(selectedSystem, null, 2)
    : '';

  const handleSaveDialog = (data: JavaSystemsConnectionMetadata) => {
    saveJavaSystemsNetwork(data)
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.error('Failed to save network information:', error);
      });

    setIsDialogOpen(false);
  };

  const handleTestConnection = (data: JavaSystemsConnectionMetadata) => {
    testConnectionOfJavaSystemsNetwork(data)
      .then((response) => {
        if (response.indexOf('failed') !== 0) {
          notifySuccess(response);
          console.log('Test Connection Result:', response);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          notifyError('Failed to test connection:'.concat(error.response.data));
        } else {
          console.log('Failed to test connection:', error);
        }
      });
  };

  useEffect(() => {
    if (selectedSystem) {
      setIsGetHashButtonEnabled(true);
    } else {
      setIsGetHashButtonEnabled(false);
    }
  }, [selectedSystem]);

  useEffect(() => {
    handleCheckCrackedPasswords(selectedSystem?.id);
  }, [bruteforceHasBeenStarted]);

  return (
    <Grid
      container
      direction="column"
      style={{ padding: '16px', backgroundColor: '#F8F7F7' }}
    >
      <Grid
        item
        container
        spacing={2}
        sx={{ ml: 0 }}
        style={{
          marginBottom: '16px',
          backgroundColor: '#ffffff',
          paddingBottom: '16px'
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => {
              setSelectedSystem(null);
              setAction('add');
              setIsDialogOpen(true);
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AddIcon sx={{ marginRight: 1 }} />
            Add System
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color={isGetHashButtonEnabled ? 'success' : 'primary'}
            onClick={handleGetHash}
            size="small"
            disabled={!selectedSystem || !isGetHashButtonEnabled}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <UpdateIcon sx={{ marginRight: 1 }} />
            Update
          </Button>
        </Grid>
        <Grid item>
          {!bruteforceHasBeenStarted ? (
            <Button
              variant="outlined"
              color={isGetHashButtonEnabled ? 'warning' : 'primary'}
              size="small"
              onClick={() => setOpenBruteforceDialog(true)}
              disabled={
                !(hashes.length > 0) ||
                !isGetHashButtonEnabled ||
                bruteforceHasBeenStarted
              }
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <PlayArrowIcon sx={{ marginRight: 1 }} />
              Start Bruteforce
            </Button>
          ) : (
            <Button
              variant="outlined"
              size="small"
              color={isGetHashButtonEnabled ? 'warning' : 'primary'}
              onClick={() => stopBruteforce()}
              disabled={
                !(hashes.length > 0) ||
                !isGetHashButtonEnabled ||
                !bruteforceHasBeenStarted
              }
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <StopIcon sx={{ marginRight: 1 }} />
              Stop Bruteforce
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            size="small"
            color={isGetHashButtonEnabled ? 'success' : 'primary'}
            onClick={downloadPasswords}
            disabled={!isAvailablePasswords}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <GetAppIcon sx={{ marginRight: 1 }} />
            Download passwords
          </Button>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Tooltip title={`Progress: ${progress}%`} arrow>
            <LinearProgress
              sx={{
                mt: '-8px',
                mb: '8px',
                display: !bruteforceHasBeenStarted ? 'none' : 'block'
              }}
              variant="determinate"
              value={progress}
            />
          </Tooltip>
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={3} style={{ overflow: 'auto' }}>
          <TableContainer
            component={Paper}
            style={{
              height: 'calc(100vh - 220px)',
              ...(javaSystems.length === 0
                ? {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }
                : {})
            }}
          >
            <Table stickyHeader>
              <TableBody>
                {javaSystems.length !== 0 ? (
                  javaSystems.map((system) => (
                    <TableRow
                      key={system.id}
                      onClick={() => handleRowClick(system)}
                      style={{
                        cursor: 'pointer',
                        backgroundColor:
                          selectedSystem && selectedSystem.id === system.id
                            ? 'lightgray'
                            : 'inherit'
                      }}
                    >
                      <TableCell>{system.host}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={(e) => {
                            setAction('update');
                            e.stopPropagation();
                            handleEdit(system.id);
                          }}
                          disabled={!selectedSystem}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(system.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Typography align="center" sx={{ pl: 4, pr: 4 }}>
                    Network unavailable. Please click &apos;Add System&apos; to
                    add a new system.
                  </Typography>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {selectedSystem && hashes.length > 0 && (
          <Grid item xs={9}>
            <div
              style={{
                overflow: 'auto',
                height: 'calc(100vh - 220px)',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              }}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          flex: 1,
                          backgroundColor: '#fffefe',
                          color: '#333',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          display: 'flex',
                          justifyContent: 'space-between', // Align the button to the right
                          alignItems: 'center' // Vertically center the button
                        }}
                      >
                        Hashes from {selectedSystem?.host}
                        <Button
                          onClick={() => downloadHashes(hashes)}
                          style={{
                            background: 'none',
                            border: 'none'
                          }}
                        >
                          <GetAppIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {hashes.map((hashRow) => (
                      <TableRow key={hashRow.hash}>
                        <TableCell style={{ flex: 1 }}>
                          {hashRow.hash}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        )}

        {!selectedSystem && hashes.length === 0 && (
          <Grid item xs={9}>
            <Box
              display="flex"
              sx={{ background: '#fdfdfd', height: 'calc(100vh - 220px)' }}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {/* Replace with your image */}
              <Typography>Select data from the Networks table.</Typography>
            </Box>
          </Grid>
        )}

        {selectedSystem && hashes.length === 0 && (
          <Grid item xs={9}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="50vh" // Adjust the height as needed
            >
              <Typography>
                {isEmptyHashes
                  ? 'No hashes available from the SAP Database. Check the connection details.'
                  : 'The loading process for hashes is ongoing...'}
              </Typography>
            </Box>
          </Grid>
        )}

        <JavaSystemsDialog
          action={action}
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSave={handleSaveDialog}
          onTest={handleTestConnection}
          initialSystemData={selectedSystem}
        />

        <HotToast />

        {openBruteforceDialog && (
          <BruteforceOptionDialog
            open={openBruteforceDialog}
            onClose={() => setOpenBruteforceDialog(false)}
            onConfirm={(
              library: string,
              customPassword: string,
              brutePattern: string
            ) => {
              bruteforcePattern.bruteforcePattern = brutePattern;
              bruteforcePattern.customPassword = customPassword;
              bruteforcePattern.library = library;
              console.log(bruteforcePattern);
              if (selectedSystem?.id !== undefined) {
                notifySuccess('Bruteforce has been started');
                startBruteforceJavaSystemsHashes(
                  selectedSystem?.id,
                  bruteforcePattern
                ).then((r) => console.log(' Started '));
              }
            }}
            libraries={libraries}
            patterns={pattern}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default JavaSystemsUI;
