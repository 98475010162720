import { green, grey, orange, red } from '@mui/material/colors';

export enum Severity {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CRITICAL = 'CRITICAL'
}

export const ORDERED_VULNERABILITY_SEVERITIES: Severity[] = [
  Severity.LOW,
  Severity.MEDIUM,
  Severity.HIGH,
  Severity.CRITICAL
];

export const getSeverityColor = (severity: Severity) => {
  switch (severity) {
    case Severity.LOW:
      return green[400];
    case Severity.MEDIUM:
      return orange[400];
    case Severity.HIGH:
      return red[400];
    case Severity.CRITICAL:
      return red[900];
    default:
      return grey[500];
  }
};
