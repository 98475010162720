export enum NetworkStatus {
  ORIGINAL = 'ORIGINAL',
  SCANNING = 'SCANNING',
  SCANNED = 'SCANNED',
  ERROR = 'ERROR'
}

export interface Service {
  templateId: string;
  port?: number;
  secure: boolean;
  anonymous: boolean;
  username: string;
  password: string;
}

export interface Instance {
  instanceId: number;
  services: Service[];
}

export interface Ip {
  ip: string;
  instances: Instance[];
}

export interface Tree {
  ips: Ip[];
}

export interface NetworkMetadata {
  id: number;
  createdAt: string;
  name: string;
  status: NetworkStatus;
}

export interface Network extends NetworkMetadata {
  id: number;
  createdAt: string;
  name: string;
  parentId: number;
  status: NetworkStatus;
  payload: Tree;
  messages: string[];
}
